import { BaseApiService } from "./base-api-service";
import { User } from "@sparkademy/app-common/models/user";

const ONE_YEAR_IN_MILLISECONDS_SECONDS = 365 * 24 * 3600 * 1000;

export class BaseMockApiService implements BaseApiService {
  login(email: string, next?: string, src?: string): Promise<void> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }
  authenticate(token: string): Promise<User> {
    return new Promise(resolve => {
      setTimeout(() => {
        const user: User = {
          company: "PMI",
          role: "instructor",
          username: "Alan",
          jwt: token,
          exp: new Date().getTime() + ONE_YEAR_IN_MILLISECONDS_SECONDS,
          data: {
            id: "test-10",
            firstName: "Alan10",
            lastName: "Test",
            email: "foo-10@bar.com",
            cohort_id: "mock",
            role: "instructor",
            company: "acme",
            exp: 123,
          },
        };
        resolve(user);
        return;
      }, 100);
    });
  }
}
