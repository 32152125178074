import * as React from "react";

export const CloudflareEmbedPlayer: React.FC<{ videoId: string }> = ({ videoId }) => {
  return (
    <div style={{ position: "relative", margin: "32px 0 32px 0" }}>
      <iframe
        src={`https://customer-qjxq1o02ggg7tff9.cloudflarestream.com/${videoId}/iframe?poster=https%3A%2F%2Fcustomer-qjxq1o02ggg7tff9.cloudflarestream.com%2F${videoId}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600`}
        style={{ border: "none", width: "384px", height: "216px" }}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};
