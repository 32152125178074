export function copyToClipboard(contents: string) {
  let dummy = document.createElement("input"),
    text = contents;

  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}
