import { Tool } from "../../models/tool";

import { tools as m1Tools } from "./m1-tools";
import { tools as m2Tools } from "./m2-tools";
import { tools as m3Tools } from "./m3-tools";
import { tools as m4Tools } from "./m4-tools";
import { tools as abmTools } from "./abm-tools";
import { tools as aifTools } from "./aif-tools";
import { tools as adsTools } from "./ads-tools";
import { tools as auxTools } from "./aux-tools";

export const tools: Tool[] = [
  ...m1Tools,
  ...m2Tools,
  ...m3Tools,
  ...m4Tools,
  ...abmTools,
  ...aifTools,
  ...adsTools,
  ...auxTools,
];

type ToolBySlugMap = { [key: string]: Tool };

export const toolsBySlug: ToolBySlugMap = tools.reduce((acc: ToolBySlugMap, tool) => {
  acc[tool.slug] = tool;
  return acc;
}, {} as ToolBySlugMap);
