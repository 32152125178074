/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { Container } from "@sparkademy/app-common/elements/Container";
import { ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";

export const Footer: React.FC = () => {
  return (
    <footer
      sx={{
        bg: "new.primary.black",
        color: "new.primary.white",
        minHeight: 50,
        py: 4,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
          alignItems: ["center", "flex-start"],
          justifyContent: "center",
        }}
      >
        <ButtonGhostAnchor
          href="https://www.sparkademy.com/"
          target="_blank"
          rel="noopened noreferrer"
          sx={{
            px: 7,
            color: "inherit",
            fontSize: "14px",
          }}
        >
          {new Date().getFullYear()} Sparkademy AG
        </ButtonGhostAnchor>
      </Container>
    </footer>
  );
};
