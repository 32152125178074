import ky, { ResponsePromise, Options as KyOptions } from "ky";

const DEFAULT_OPTIONS: KyOptions = {
  retry: {
    limit: 5,
    methods: ["get", "put", "head", "delete", "options", "trace"],
    statusCodes: [408, 413, 429, 500, 502, 503, 504],
  },
};

const REQUEST_MAX_ATTEMPTS = 10;

export async function requestWithRetry(
  requestPromise: () => ResponsePromise,
  maxAttempts: number = REQUEST_MAX_ATTEMPTS,
  attempt: number = 0
): Promise<Response> {
  try {
    const res = await requestPromise();
    return res;
  } catch (err) {
    console.error(err);

    // retry with a back off in case of network error (err instanceof TypeError)
    // https://github.com/sindresorhus/ky/issues/107#issuecomment-476048453
    if (err instanceof TypeError && attempt < maxAttempts) {
      return new Promise(resolve => {
        setTimeout(
          () => resolve(requestWithRetry(requestPromise, maxAttempts, attempt + 1)),
          500 * (attempt + 1)
        );
      });
    } else {
      throw err;
    }
  }
}

export async function postWithRetry(
  path: string,
  payload?: object,
  maxAttempts?: number,
  options?: KyOptions
): Promise<Response> {
  return requestWithRetry(
    () => ky.post(path, { ...DEFAULT_OPTIONS, ...options, json: payload }),
    maxAttempts
  );
}

export async function postFormWithRetry(
  path: string,
  form: FormData,
  maxAttempts?: number,
  options?: KyOptions
): Promise<Response> {
  return requestWithRetry(
    () => ky.post(path, { ...DEFAULT_OPTIONS, ...options, body: form }),
    maxAttempts
  );
}

export async function getWithRetry(
  path: string,
  maxAttempts?: number,
  options?: KyOptions
): Promise<Response> {
  return requestWithRetry(() => ky.get(path, { ...DEFAULT_OPTIONS, ...options }), maxAttempts);
}
