import * as React from "react";
import { Message } from "theme-ui";
import { useAtom } from "jotai";
import { errorToastMessageAtom } from "@sparkademy/app-common/stores/ui";

export const MessageToast: React.FC<{ visible: boolean; message: string }> = ({
  visible,
  message,
}) => {
  return (
    <Message
      id="message-toast"
      sx={{
        bg: "brand.green",
        borderColor: "brand.green",
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        position: "fixed",
        margin: "auto",
        top: 5,
        width: ["90vw", "88ch"],
        height: visible ? "auto" : 0,
        transition: "0.2s",
        overflow: "hidden",
        p: visible ? 3 : 0,
        zIndex: 9,
        maxWidth: "90vw",
      }}
    >
      {message}
    </Message>
  );
};

export const ErrorToast: React.FC = () => {
  // shows error toast if error message is set in errorToastMessageAtom
  const [errorMessage] = useAtom(errorToastMessageAtom);

  return (
    <Message
      id="error-toast"
      sx={{
        bg: "new.failingRed",
        borderColor: "new.failingRed",
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        position: "fixed",
        alignSelf: "center",
        top: 5,
        width: ["90%", "88ch"],
        height: !!errorMessage ? "auto" : 0,
        transition: "0.2s",
        overflow: "hidden",
        p: !!errorMessage ? 3 : 0,
        zIndex: 9,
      }}
    >
      {errorMessage}
    </Message>
  );
};
