// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

// m4 assets
import m4ConstructiveFeedbackFrontImage from "../method-kit/m4/front/constructive-feedback.svg";
import m4ConstructiveFeedbackBackImage from "../method-kit/m4/back/constructive-feedback.svg";
import m4DecisionMakingFrontImage from "../method-kit/m4/front/decision-making.svg";
import m4DecisionMakingBackImage from "../method-kit/m4/back/decision-making.svg";
import m4DiverseTeamFrontImage from "../method-kit/m4/front/diverse-team.svg";
import m4DiverseTeamBackImage from "../method-kit/m4/back/diverse-team.svg";
import m4EfficientTeamworkFrontImage from "../method-kit/m4/front/efficient-teamwork.svg";
import m4EfficientTeamworkBackImage from "../method-kit/m4/back/efficient-teamwork.svg";
import m4EnablingConditionsFrontImage from "../method-kit/m4/front/enabling-conditions.svg";
import m4EnablingConditionsBackImage from "../method-kit/m4/back/enabling-conditions.svg";
import m4FiveStagesFrontImage from "../method-kit/m4/front/five-stages.svg";
import m4FiveStagesBackImage from "../method-kit/m4/back/five-stages.svg";
import m4HealthCheckUpFrontImage from "../method-kit/m4/front/health-check-up.svg";
import m4HealthCheckUpBackImage from "../method-kit/m4/back/health-check-up.svg";
import m4RolesAndRewardsFrontImage from "../method-kit/m4/front/roles-and-rewards.svg";
import m4RolesAndRewardsBackImage from "../method-kit/m4/back/roles-and-rewards.svg";
import m4ManagingConflictFrontImage from "../method-kit/m4/front/managing-conflict.svg";
import m4ManagingConflictBackImage from "../method-kit/m4/back/managing-conflict.svg";
import m4SharedLeadershipFrontImage from "../method-kit/m4/front/shared-leadership.svg";
import m4SharedLeadershipBackImage from "../method-kit/m4/back/shared-leadership.svg";
import m4SmartGoalsFrontImage from "../method-kit/m4/front/smart-goals.svg";
import m4SmartGoalsBackImage from "../method-kit/m4/back/smart-goals.svg";
import m4TeamPerformanceFrontImage from "../method-kit/m4/front/team-performance.svg";
import m4TeamPerformanceBackImage from "../method-kit/m4/back/team-performance.svg";
import m4TrustInTeamsFrontImage from "../method-kit/m4/front/trust-in-teams.svg";
import m4TrustInTeamsBackImage from "../method-kit/m4/back/trust-in-teams.svg";
// @ts-ignore
import m4TeamHealthCheckTemplate from "../method-kit/m4/team-health-check.pdf";
// @ts-ignore
import m4TeamDiversityTemplate from "../method-kit/m4/team-diversity.pdf";

export const tools: Tool[] = [
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Efficient Teamwork",
    slug: "m4-efficient-teamwork",
    resources: [],
    content: mdx`
##### What is a “Team”?

A team is a set of two or more people who interact adaptively, interdependently, and dynamically towards a common and valued goal (Salas et al. 1992). In a team, members work together because their outcomes are interdependent – what A achieves (or not) impacts whether B can achieve her goals (or not), and vice versa. A team also has a limited life span. This makes teams different from business groups, which typically exist for a long period of time.

##### Teams vs. Individuals

Teamwork is essential, as most innovations are developed by teams, not by individuals. It is crucial to understand how teams think, behave, feel, and make decisions. In groups, members need to negotiate, discuss, disagree, and reconcile their goals and activities. Motivations, thoughts, and behavior are social and interactive processes between individuals and not solely within the individual.
Because thoughts, actions, and decisions happen in-between, not within individuals, teams do not work the same way as individuals do.
For example, teams are less overconfident than individuals, more risk-averse, and better able to manage uncertainty, more competitive, and greedy than individuals. (Salas, 2008)

##### What’s the main challenge with teams?

Teams can outperform individuals, but only if members work well together. A team that manages to combine the knowledge of its members is fantastic. But teams can also be subject to conflicts that tear them apart. Despite having access to a broad range of knowledge and expertise, it does not mean that team members are able to combine it into something useful.

##### What determines team performance?

Team performance is determined by the parts that create the team (“inputs”) and how those parts play together to create something bigger than its parts (“teamwork”). When you hear the expression 1+1=3, that’s what it means. If the team manages both these things (inputs & teamwork) well, they will have a good output.

##### Inputs

Generally speaking, inputs are (1) team members and (2) resources. These are some questions to ask yourself to see if you have the right inputs:

1. Are your team members smart, compassionate, empathetic,
       ambitious, hard-working, experienced,…?

2. Does this team have access to enough money, time, organizational
       support,…?

People and resources are relatively static aspects of a team. Team members have whatever traits or skills they have, and these attributes cannot be changed in the short term. The same is valid for resources: they are set and often in short supply.

##### Teamwork

Teamwork is emergent. It develops over time as the team members work together. Great teamwork—playing well together—has different aspects to it. Team members need to manage:

1. Their relationships with each other; build trust; develop unity in the
        team; manage conflicts.

2. The task at hand: make plans, set goals, divide roles, establish
        routines.

##### The positive and negative impacts of teamwork

For those working in an innovation team, these emergent teamwork qualities have both a negative and a positive impact.

##### Positive impact

Teamwork is something that team members can influence themselves. Trust, planning, and work processes are dependent on what members decide and how members behave.

##### Negative impact

Teamwork has a strong path dependency. This means that once norms, routines, and relationships have been set in a team, they are difficult to change.

Building trust is easier than repairing trust. Setting a good routine is easier than breaking a bad one. Because of this path dependency, the most important decisions you make in a team are the ones you make right on. First impressions last.

##### How do you build a strong team?

Building a strong team is complex, and there is no silver bullet for it.  It depends on the team members, how they work together, and how long they have worked together.

There are, however, a lot of tips and tricks to learn along the way. It helps to remember that there are different routes to performance:

1. Good team members

2. Adequate resources

3. Well-managed relationships

4. Good management of the task at hand

And above all, it is much easier to enhance team performance if you, as a team member, reflect on what it implies to work in a team.
`,
    benefits: [],
    thumbImage: m4EfficientTeamworkFrontImage,
    backImage: m4EfficientTeamworkBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Team Performance",
    slug: "m4-dimensions-of-team-performance",
    resources: [],
    content: mdx`
Teams performance can be judged along different dimensions:

##### Delivery of good results vs. reasonable use of resources

For example, an innovation team’s performance, that is formed to develop a new, ground-breaking product for their organization, could be judged by:

1. Whether the end product is indeed ground-breaking,

2. What resources are spent to get those results.

Often, there is a trade-off between effectiveness (delivering good results) and efficiency (reasonable use of resources).

##### Short term results vs. learning

Team performance could be judged by more subtle outcomes, like:

1. What did members of our innovation team learn, even if they failed at delivering a ground-breaking product?

There is a lot to be learned, even from failures. Therefore, there is another potential trade-off between measurable short-term results and learning (potentially higher long-term results).

##### Getting along vs. delivering results

We could also look at the happiness and well-being of team members. Do they have a good time together? Do they enjoy working together? Do they get along? Just as learning and results are not necessarily related, members’ well-being is not necessarily related to their abilities to achieve good results.

We know from research that when team members are asked the question, “Do you have a good team?” their answers will tell you how they get along.

On the other hand, if the same question is being asked to the recipient of team outcomes - a senior manager or a customer – their answers will typically reflect whether the team is delivering good results or not.

Sometimes, by focusing too much on getting along, team members can undermine their ability to achieve good outcomes.

_An example from research:_

_Two friends formed a new venture team called Sand. Trust, respect, and comradeship characterized their interactions. The problem, however, was that they never questioned one another. As was discovered over time, the two did not have the right motivation nor the skills to do the task at hand. Due to a lack of discussion and questioning each other, performance suffered. From a “getting-along” perspective, the results were fantastic. Team members’ friendship remained intact. From a financial perspective, however, results were disastrous. The venture was closed down at a major loss._

_“We were so afraid of damaging our relationship, we never spoke our honest mind.”, one of the two said after this experience._

##### The team vs. the individual

A team’s performance can be understood at different levels: what is good for the team is not necessarily good for individual team members. This is known as the trade-off between (1) getting along and (2) getting ahead.

##### Getting along

Individual careers are dependent on individual performance reviews. To make a career, people must cooperate, seem friendly and positive. They must be reviewed as good team players and act in a way that enables the team to get along.

##### Getting ahead

To climb the corporate ladder, people must also take individual initiative. Seek responsibility. Show ambition. Be recognized for their achievements. They want to be reviewed as high performers, which enables them to get ahead in their careers.

A meta-analysis that synthesized data from over 40 studies of career performance found that both, getting along and getting ahead, matter for individual careers. Team members who also have career aspirations face a dilemma.

1. Should I prioritize finishing my part, or should I help a colleague achieve his?

2. Should I credit others or take credit myself?

Such questions are an aspect of individual careers, and they affect team performance.

##### Summary

1. Team performance can mean many different things.

2. Performance in one dimension can undermine performance in another dimension.

3. The key to effective teamwork is recognizing potential trade-offs and find a way to alleviate them.

4. Even though getting along can stand in the way of getting ahead, it does not necessarily do so. Even though an obsession with creating unity can undermine the production of good results – that is not necessarily the case.
    `,
    benefits: [],
    thumbImage: m4TeamPerformanceFrontImage,
    backImage: m4TeamPerformanceBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Enabling Conditions",
    slug: "m4-enabling-conditions",
    resources: [],
    content: mdx`
By now, we have about 100 years of systematic research to rely on when understanding what makes a high performing team: what matters most for team performance is not the personalities, skills, or attitudes of individual members. Just because your team is composed of high-performing stars, it does not necessarily make a high-performing team.

The other significant insight from these 100 years of research is that teams are vulnerable to internal tensions and internal dilemmas. There are potential performance trade-offs between effective results and efficient use of resources, learning, and well-being. Getting along as a team can sometimes stand in the way of individual team members getting ahead in their careers.

If unmanaged, these tensions can undermine a team completely, causing it to break apart. Research has identified four core enabling conditions that help your team to thrive:

1. A compelling direction

2. A strong structure

3. A supportive context and

4. A shared mindset

##### A compelling direction

A compelling and clear direction is what energizes and motivates a team. Unless the team knows where to go, it cannot move forward. Setting a compelling direction also includes setting clear goals.

At its very essence, innovation implies a search for something that is not known. At the same time, it is also unknown what resources are required or how long time it will take to reach the goal. In this state of ambiguity, team members often have very different understandings of goals.

_An example from research_

_A team was operating within a global manufacturer of heavy vehicles. All members of the team agreed that the most important goal was the development of electrical engines. Yet, within the group, different members had completely different understandings about how to get there. Some members of the team advocated in-house development, building core competence in this area within the organizations. Other members of the team advocated an open-innovation approach involving external partners. Over time, these different understandings of goals triggered an intense political struggle with devastating consequences for the organization._

In the context of innovation, a clear direction is set through continuous and frank discussions about where to go and how to get there. If the only thing the team knows for sure is that the direction will change, they should at least agree on that.

##### A strong structure

Innovation teams are in a state of flux. They engage in a search for something: not knowing what it is nor exactly how to get there. Being in this state of flux, innovation teams need to find structure wherever they can, for example, by:

1. Establishing routines about how to share information—but not about what information needs to be shared

2. Having clear roles—but allowing some flexibility in who takes on what role and when.

Structure creates predictability. Predictability, in turn, builds trust. And trust enables collaboration, information exchange, and creativity.

##### A supportive context

A strong supporting context is the third important enabling condition for teams. Having the right support means:

1. Having enough resources to get the job done

2. Having a performance structure that alleviates tensions within the team (getting along vs. getting ahead)

3. Ensuring that the result of teamwork lands well within the organization

Setting a support structure is rarely in control of team members themselves. For this, most innovation teams rely on senior management.

##### A shared mindset

Remember that in a team, members are dependent on one another. One member cannot get her job done unless another member does hers. Therefore, a shared mindset is essential.

This does not mean that everyone thinks in the same way. On the contrary, innovation work benefits from differences. A shared mindset means members understand each other, how they work, what goals are important to them, and how to best share information within the team.
    `,
    benefits: [],
    thumbImage: m4EnablingConditionsFrontImage,
    backImage: m4EnablingConditionsBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Team Health Check",
    slug: "m4-health-checkup",
    resources: ["templates"],
    content: mdx`
Team problems account for about half of all innovation failures. To understand how your team is performing and increase its success rate, evaluate it on the different performance criteria. Then take a moment to reflect on how well your team meets up to the four enabling conditions.

Very often, problems in performance can be traced to one or more of these conditions. Understanding these links will help your team to perform.

**Download the template to distribute and discuss performance criteria and enabling conditions. To make the feedback workable, encourage team members to add comments and reasoning to their answers. You might also consider conducting the survey online, using any tool of your choice.**

##### Tips:

1. Do a regular health check-up on your team, using these questions to guide your analysis.

2. Think carefully about the links between performance and enabling conditions to understand how best to remedy potential problems.

If you identify more severe issues, deal with them, and engage in an honest discussion with your teammates or superiors.
    `,
    benefits: [],
    thumbImage: m4HealthCheckUpFrontImage,
    backImage: m4HealthCheckUpBackImage,
    template: m4TeamHealthCheckTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Working In A Diverse Team",
    slug: "m4-diverse-team",
    resources: ["templates"],
    content: mdx`
In modern team-based organizations composed of specialists, teams inevitably become more diverse. But what does diversity imply?

##### Different types of diversity and their characteristics

We make a difference between two kinds of diversity:

1. Demographic diversity—based on visible characteristics such as gender, age, or ethnicity. This increases the likelihood of sub-group thinking because we tend to stereotype based on demographic characteristics. In turn, such stereotyping tends to lower members’ identification with the team, hamper collaboration, and increase conflict.

2. Informational diversity—based on team members’ understandings, skills, and knowledge. Informational diversity invites better problem solving because members can potentially leverage their differences.

##### The effects of diversity in innovation

One might conclude that demographic diversity is bad, or at best irrelevant, for performance but that informational diversity can enable teams to leverage their differences and be more creative. But it is not that easy. Research shows that teams that are diverse on both dimensions outperform homogenous teams—but solely if team members share information, listen to each other, and cooperate.

##### How to make your diverse team outperform homogenous teams: Do diverse teams outperform homogenous teams?

1. Yes – if team members share information, listen to each other and cooperate.

2. No – if diversity means that people find it challenging to understand each other, become divided into sub-groups, and stop communicating.

So how can you create a sound understanding with one another and foster communication and cooperation? Spend some time at the beginning of your project to do the following with your team:

1. Break the ice: Get to know each other’s prejudices and judgments.

2. Move to actions: What behaviors do you expect from each other?

3. Look at interactions: How do you communicate and collaborate with everyone?

##### To put this into practice, you can use our discussion template "Turn Diversity Into A Strength"

_An example from research_

_In a famous study, researchers were interested in the effect of team diversity on collective intelligence, so they designed an experiment._

_The experiment:_

_First, the researchers tested individual intelligence through a standard IQ test. Then, they randomly assigned individuals to groups and asked them to solve a complex problem. Teams were given scores based on their ability to solve that complex problem._

_The hypothesis:_

_The researchers were expecting that a team’s ability to solve a complex problem would simply reflect the average of all the individual IQs in a group. The smarter the members, the smarter the group._

_The results:_

_They found, however, that individual IQ played a relatively small part. Instead, gender was a better predictor of collective intelligence. The standard argument is that one should have gender diversity - a mix of men and women - in the team. However, the researchers found that the more women were on the team, the better the team performed. It seemed that neither individual IQ nor diversity could explain collective intelligence in this experiment._

_The explanation:_

_Women tend to be better than men at sharing information, discussing, and listening. And these aspects make a high performing team._
    `,
    benefits: [],
    thumbImage: m4DiverseTeamFrontImage,
    backImage: m4DiverseTeamBackImage,
    template: m4TeamDiversityTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "5 Stages Of Teamwork",
    slug: "m4-five-stages-of-teamwork",
    resources: [],
    content: mdx`
Just like individual relationships, teams move from an initial state of honeymoon to something deeper, well-functioning – unless they split up on the way. When team members learn to work together, they go through 5 phases. Those stages of teamwork are called forming, storming, norming, performing, and adjourning.

##### Forming

When teams are first formed, members are often polite and pleasant, excited about the new task and new team. They are in a state of honeymoon.

In the forming stage, people familiarize themselves with each other and learn about what part different people play. The focus in this stage is typically more on the relationships than the task at hand.

Because of this, the team is often not very productive in the forming stage.

##### Storming

After some time, reality hits. The first excitement is over, and the weight of managing the task starts to weigh in. People are unable to uphold their overly polite and pleasant way of being. The task may seem less exciting now that we realize the difficulties and hard work required from all of us. In many teams, this leads to conflict.

It is easy to try to brush over the storming phase to avoid conflicts. Conflicts, however, can be important and productive for teams. Through them, team members challenge each other’s way of thinking and behaving. In conflicts, new perspectives and ideas emerge. Therefore, conflict is important for innovation.

The storming phase can be uncomfortable, and you might question the team and/or the task. But storming is a normal and essential phase. If you stick through the storm, chances are you will come out stronger on the other side. Especially if you are the team leader, your role in this phase is to remind everyone that this, too, shall pass.

##### Norming

When the dust settles after the storming phase, people tend to have learned important things about themselves, their team members, and the task at hand.

They learn to appreciate each other’s strengths and find ways of working together that help them overcome their differences. They enter the norming phase.

In the norming phase, team members build a common identity. They start to describe themselves as “us” and reach some sort of consensus about who does what, how, and when.

The norming phase is essential for teams to be able to perform. If a team gets stuck in the honeymoon phase, chances are members will only be polite but never really get to know each other or the task.

##### Performing

After norming, most groups can start performing. In this phase, members are confident, motivated, and familiar with each other and the task. They can work together towards a common goal.

Because people in the performing stage know each other and have a more precise understanding about who does what, the performing stage is typically when most of the work gets done.

##### Adjourning

The difference between a business group and a team is that the latter has a limited lifespan. Therefore, the very final, adjourning stage of a team’s development is when it breaks up.

For teams that successfully accomplished things and built a strong unit, this stage is sometimes characterized by a sense of loss. For less successful teams, it can be a period of grief and regret of all of the things that failed.

As a leader, you have the responsibility of helping team members cope with these emotions.

##### Practical implications

Some teams get faster to the performance stage than others. Others get stuck in storming or keep coming back to it.

Some teams even manage to get to norming and performing without any storming at all.

However, for most teams, recognizing these phases as natural elements of teamwork can help make the journey somewhat less of an emotional roller coaster.
    `,
    benefits: [],
    thumbImage: m4FiveStagesFrontImage,
    backImage: m4FiveStagesBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Managing Conflict",
    slug: "m4-managing-conflict",
    resources: [],
    content: mdx`
Conflicts are natural, even necessary, in teams. Through conflicts, team members challenge each other’s way of thinking and behaving. In conflicts, new perspectives and ideas emerge. This makes conflict important for innovation.

Conflicts, however, can also turn unproductive. Instead of helping team members to new perspectives, they lock people in the trenches of war. They can make people feel frustrated, angry and anxious, lead to more irrational decisions, and hamper exchange of information.

There are three core principles behind having a good fight and avoiding getting stuck in conflict escalation.

1. Focus on tasks, not people
2. Work towards the common point of interest
3. Establish a sense of fairness in the process

##### Focus on tasks, not people

Successful management of conflict focuses on the task instead of people. The goal is to fight over ideas, not personalities; to criticize results, not the people behind the results.

In innovation work that might be easier said than done. Innovation work requires people to get involved, to invest their emotions and to give themselves in.

Facts vs. Assumptions

To keep a cool head, try to separate facts from guesses. You might disagree on many things, but perhaps you can at least agree on what is known (the facts) and what is unknown (the assumptions). If you can agree on that, you can focus the discussion on facts and agree to disagree on the unknown for the time being. Moving on in your project, you will figure out ways to validate important assumptions as soon as possible.

Few vs. Multiple Options

Another thing to do to avoid getting personal is to keep multiple options in the discussion open. If you boil down conflicts to two main options, people easily get stuck in one or the other and find it difficult to agree. By opening up multiple options, you create room for negotiation and creativity. Instead of cornering people to one side, you allow people in your team to keep multiple doors open.

##### Work towards a common point of interest

The second principle about constructive conflict solving is to work towards a common point of interest.

##### A common goal

For this to happen, try to find a common goal for the team and the organization. Most people will accept to not get their way if they know that it is for the common good. As a team, you need to break down that “common good” into specific goals around which you can gather.

##### Use humor

Another way to find unity in the midst of conflict is to add a bit of humor to the process. Maybe not everything needs to be taken dead seriously, and you can afford a little laughter on the way. Try to see what is humorous about the situation you are in and you will find that a good laugh can untie the hardest knots. Of course, avoid mocking or shaming others to create laughter.

##### Democratic leadership

Democratic leadership does not mean no leadership, but it means that all members of the team get heard and are recognized for their contributions. Democratic leadership is one way of establishing a sense of fairness. The other way is to seek consensus but not to insist on it. In teams with a democratic leadership, conflicts are more productive.

To implement democratic leadership, make sure to gather all relevant points of view and seek consensus if you can. If that proves impossible, however, someone needs to make the decision, accepting the lack of consensus in the group.

Groups that insist on finding consensus often get stuck in haggling. This leads to half-baked compromises and missed deadlines, undermining collaboration over time. As long as they are heard, however, most people can accept that they do not always get their way. They will feel that the decision is just – even if they might think that it is the wrong one.

Focusing on tasks, creating common interests and establishing fairness are three core principles behind constructive solving of conflict. What they ultimately do is to allow a team to have a fight – but then get over it. To disagree, but not dissolve.
    `,
    benefits: [],
    thumbImage: m4ManagingConflictFrontImage,
    backImage: m4ManagingConflictBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Constructive Feedback",
    slug: "m4-providing-constructive-feedback",
    resources: [],
    content: mdx`
Feedback is crucial for learning, but giving and receiving feedback is not easy.

##### The 7:1 Feedback Principle

According to research, high-performing teams share positive feedback nearly six times more often than average teams. Low-performing teams, on the other hand, share nearly twice as much negative feedback as average teams. Negative feedback hurts. In a team, it can trigger negative energy, which hampers collaboration. But if counterbalanced with positive feedback, it can be constructive and help teammates play better together.

##### What to give feedback on

It is more difficult to give and receive negative feedback. Before giving it, you need to realize that some aspects of behaviour are more difficult to change than others.

When giving feedback and looking for a solution to solve a conflict, keep this spectrum in mind and aim for easy aspects. Work practices are much easier to change than a personality. Imagine the different personalities in your team cause conflict because someone is always working close to a deadline and needs time pressure, and someone else would like to have everything planned with a time buffer. Solving this conflict should not aim to change how those people feel and prefer working because, most likely, they just can’t change by telling them.

Instead, look at the spectrum and think about an easier solution. For example, you could implement a work practice of setting two deadlines instead of one, so you have the time pressure to get done, plus a designated time to control everything and make large changes.
Or talk about why everyone is acting the way they do and try to change their attitude towards each other. So if person A works until the deadline, try not to view this as sloppy and unresponsible work. And if person B is asking for the current status of the project every day, don’t see this as annoying and controlling, but communicate and give them confidence that everything will be ready at the deadline.

##### Giving feedback on skills

Skills are relatively easy to change. Is your teammate a poor presenter? Give him some tips and tricks about how to make presentations more interactive, shorter, and informative.

##### Giving feedback on personality

Personality, at the other extreme, is inherently difficult to change. Does your teammate get anxious quickly, working himself up over deadlines and deliverables? There is no use telling your teammate to stop worrying – it is very likely that he can’t. When criticized for their personality attributes, most people get defensive, which makes them unreceptive to feedback. Try to frame personality problems in changeable terms. Instead of telling your colleague to stop worrying, ask how you can create work practices that help him cope with stress. Or ask if you can provide any type of training that increases his confidence in getting the work done.

##### Giving feedback on work practices

An aspect that is relatively easy to change is work practices, and that’s an area where people tend to give the most feedback. Before you provide feedback to someone working in a different way than yours, however, pause for a moment to ask yourself:

1. Is this a real problem? Or is it just that person doing his work in a different work style?
2. Perhaps there are even good things about his way of working that you missed?
3. Perhaps waiting until the deadline gives room for him to help other people in the team contribute?
4. Perhaps he simply benefits from the added pressure of the deadline?

Feedback should be adequate and reflect real problems, not just personal preferences.

##### Giving constructive feedback

Both positive and negative feedback need to be (1) specific and (2) actionable.

##### Specific feedback

Unspecific feedback examples:

1. “Good job!” > Good job doing what exactly?
2. “Be a better team player next time!” > How? What did I do wrong?

Specific feedback example

“You did such a great job yesterday. I was really impressed about how you enabled us all to see new aspects of this product that we are working on. Please let us know what helped you do such a great job!”

##### Actionable feedback

A good way to make your feedback specific and workable is to develop an action plan according to the feedback. Define workable next step and attach goals to them. Include rewards if the goals are reached and consequences if they aren’t. Specify a time for follow-up. And make sure that the person who gives the feedback and the one who receives it share the same understanding about moving forward.

When giving feedback, the most important point is helping the recipient receive feedback with an open mind. Stay calm and constructive. Frame problems in terms of skills and work management rather than in terms of personality or personal habits. And keep in mind the 7:1 principle. High performing teams do not avoid negative feedback – they just make sure to balance it out with a surplus of positivity.
    `,
    benefits: [],
    thumbImage: m4ConstructiveFeedbackFrontImage,
    backImage: m4ConstructiveFeedbackBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Trust In Teams",
    slug: "m4-trust-in-teams",
    resources: [],
    content: mdx`
Trust is one of the most valuable assets that a team can have, and high performing teams tend to have one thing in common: members trust each other.

##### What does it mean to trust?

To trust is to accept vulnerability. When we trust, we place our fate in the hands of another person. To trust is to have faith in someone. If there are full predictability and control – then we do not need to trust. Trust is only relevant when there is uncertainty. This makes trust especially important for innovation teams. As you know, when it comes to innovation, nothing is certain.

But trust can mean different things to different people. When asked what trust meant to them, people often answer with words such as Care, Dependence, Liking, Competence, Reliability, Commitment, and Benevolence. In a business context, it is important to recognize that trust with the heart and trust with the head are being built in different ways.

##### Different types of trust

Trust with the head

Some of the words above are less affectionate but more factual, like Competence, Reliability, and Dependence. We build “trust with the head” through professional credentials and past performance. You might trust someone’s knowledge because they have a Ph.D. You trust your colleague because she has been proven worthy of your trust in the past. This type of trust leads to confidence in your teammates as reliable and competent.

Trust with the heart

Some of these words are deeply affectionate. They are the type of words we associate with our most intimate relationships. This is what we call “trust with the heart.” Trust with the heart, however, cannot be built based on any degree. It requires a personal connection. And whereas trust with the head creates confidence in your teammates as reliable and competent, trust with the heart creates feelings of care and concern.

##### How to build trust depends on the context

It is next to impossible in some business contexts to form a business relationship unless the heart is also involved. China, Jordan, and Brazil are examples of relationship-based countries in which “trust” is often equated with “the heart.” In other countries, trust is closely related to the head. Task-based countries, such as Germany or the U.S., are often characterized by a head-type of trust.

Working in a team, you need to recognize these differences. If you are a trust-with-the-brain type of person, keep in mind that for some of your teammates, relationships matter more than they do to you. They simply might not trust you unless you have also had dinner together and they know the name of your spouse. If you are a trust-with-the-heart-type of person, don’t mistake your colleagues’ unwillingness to socialize for lack of trust.

##### Dependency vs Observability

Depending on the situation, the heart and/or the head should and can be involved. You need to be realistic about what type of trust is possible in the first place. If actions are observable, then you can trust with the head. On the other hand, if it is impossible to monitor your teammates or check their credentials – then trust with the head impossible. You will need to trust with the heart.

Before you build trust in a team, first assess what is possible and the level of reliability you have with the other party. Once that is determined, you can start to set priorities and decide how to monitor.

One-sided dependency, observability

In some relationships, dependence is once sided. If you hired a painter to repaint your house, you would be quite dependent on him since you intend to live in this house for many years to come. To him, you are just another customer. Simultaneously, his actions are very observable, and you can follow his work and recognize problems. This type of relationship can be built based on trust with the head.

One-sided dependency, no observability

In other relationships where dependence is one-sided, actions are less observable. For example, with babysitters, parents trust them with the most precious thing they have, and they have no way of verifying or controlling what happens when they’re gone. In this relationship, parents need to trust with the heart. Therefore, they tend to hire family members to babysit. Those have no formal training in babysitting, but the parents trust them deeply, with their hearts.

Two-sided dependency, no observability

In other relationships, dependence is two-sided. In a marriage, for example, actions are not observable. Credentials do not matter. We trust with the heart.

Two-sided dependency, observability

A tennis partner is a good example of such a relationship. When playing tennis in pairs, you are entirely dependent on your partner, and mistakes are painfully obvious. Innovation teams resemble tennis partner relationships. One team member cannot do her work unless other team members do theirs. At the same time, most actions are observable. We work together, have regular meetings and share information about our progress and our setbacks.

##### Trust in innovation teams

Innovation teams focus on learning and development where dependency is mutual and most but not all actions are observable. Therefore, monitoring and trust with the head are applicable. However, too much monitoring undermines the needed flexibility of innovation teams. This means that both trust with the heart and trust with the head are required and need to be balanced out.

Building trust is the most important thing you can do in a team. Make sure to give it some due consideration and thought.

##### How to build trust in innovation teams

Innovation teams need both a bit of the heart and the head to function. So how can you build trust with the head without undermining trust with the heart?

First rule of thumb: Start small

The first rule of thumb is to build trust through small, repeated actions over time. By building trust step by step, you have time to both demonstrate benevolence and care as well as reliability and competence. In this way, the heart and the head can move side-by-side.

Second rule of thumb: Align interests

The second rule of thumb is to recognize the possibility that team members have conflicting interests. Working in a team, you need to figure out the different interests of your teammates and create incentives that allow everyone to achieve his or hers without undermining the team as a whole. When team members have conflicting interests, trust is at risk. Members become suspicious that others are not pulling their weight. Or that they try to gain an unfair amount of credit for collective work.

Third rule of thumb: Establish escape clauses

The third rule of thumb is to talk about difficult things while you are still on good terms. In an innovation team, it can be a good idea to talk about what to do in a situation of trust breakdown. For example, what will you do if one team member does not deliver what is promised? Is it acceptable that members work on side projects while in the team? Is it acceptable to use material that was developed by the team for personal purposes?

The list is long of potential trust transgressions. Make sure to have an open conversation while still on good terms.

Once trust has been broken, it rapidly erodes. The sooner you can deal with trust problems, the better. If you can pre-empty trust issues before they occur – even better.
    `,
    benefits: [],
    thumbImage: m4TrustInTeamsFrontImage,
    backImage: m4TrustInTeamsBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "How To Set SMART Goals",
    slug: "m4-smart-goals",
    resources: [],
    content: mdx`
Goals serve three important purposes:

1. Goals are motivating. We work harder if we have goals.
2. By setting goals strategically, we avoid that members’ conflicting  interests will undermine trust.
3. Through the combination of goals and feedback, team members learn and become motivated in the process.

##### How to set strategic goals

Based on some 400 studies in both the lab and the field, Edwin Locke has helped us understand the three most important principles of effective goal setting:

1. Set high goals, because high goals are more motivating than moderate goals.
2. Set specific goals, because specific goals are more motivating than vague goals.
3. Track progress & give feedback, because it helps people see they are getting closer to reaching their goals.

These principles have been turned into the S.M.A.R.T. acronym, used in many organizations. S.M.A.R.T. goals are:

1. Specific – because specific goals are more motivating
2. Measurable – because tracking progress is easier with numbers
3. Achievable and Ambitious – because these are more motivating
4. Relevant – goals need tob e relevant to matter
5. Time bound – people need to see the end of the tunnel.

##### Example goals in the context of innovation

How would an innovation team that is about to launch a new product set S.M.A.R.T. goals?

1. To be Specific and Measurable, the team could break down the product launch into measurable activities; for example setting goals in terms of number of customers to contact or number of clicks to generate on social media.
2. Make the product launch Ambitious but Achievable by breaking it down. Perhaps the ambitious goal is to assume a market share by 40%, but the achievable version is to assume a market share of 10% in the coming 4 months.
3. To be Relevant, set goals that is within the control of your team. If market share is dependent on a host of team-external factors – may that be top management; or a well-functioning supply chain - then it is not a relevant goal to motivate your team.
4. Finally, it needs to be Time-bound. How many customers do you aim to reach within the first day? Week? Month? When do you expect to have a dominant position in the market?

By setting SMART goals – or in scientific terms, following Locke’s principles for goal setting – you create a foundation for trust with the brain. You align interests. You boost commitment. And you get a channel and direction for feedback in the team. Such predictability builds trust.
    `,
    benefits: [],
    thumbImage: m4SmartGoalsFrontImage,
    backImage: m4SmartGoalsBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Roles and Rewards",
    slug: "m4-roles-and-rewards",
    resources: [],
    content: mdx`
Organizing teamwork includes setting roles and responsibilities, defining leadership, and making decisions.

Responsibilities and role setting can have two extremes:

1. Each role is clearly defined and has its belonging responsibilities, or
2. Roles are blurry,and responsibilities are therefore overlapping

Depending on the task at hand, you can decide which role setting is most appropriate. When tasks are known and plannable, for instance in car manufacturing, the work can be divided into clear separate jobs along the assembly line.

In innovation, it is unclear what tasks need to be done and how the final product will look. This situation is called true uncertainty.

##### Motivation and rewards

To keep motivation high, benefits and rewards must be aligned with the leadership structure.

1. With distinct roles, the individual added value is visible, and rewards should be given to individuals
2. In (innovation) teams with blurry and overlapping roles, individual contributions to the overall result are difficult to trace.

Therefore, rewards should be collective for the whole team. Freeriding problems in teams with collective rewards can destroy motivation in a team.

The solution is to build and renew trust among team members continuously.

##### Information sharing

One would assume that sharing information in teams with overlapping roles is easy, as everyone is involved in everything. Paradoxically, however, information can be very tricky to share in those teams, as most knowledge is tacit –it is in the head of people, but not written down.

Tacit information is difficult to share for two reasons:

1. We are unaware that we have it, as we take it for granted
2. We assume it is common know-how and don’t talk about it

Teams can overcome this obstacle by making the information visible, using different methods presented in other modules. Never assume that just because everyone is involved in everything, information is automatically shared with the team!
    `,
    benefits: [],
    thumbImage: m4RolesAndRewardsFrontImage,
    backImage: m4RolesAndRewardsBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Shared Leadership",
    slug: "m4-shared-leadership",
    resources: [],
    content: mdx`
When leading an innovation team, you establish a sense of shared responsibility. But what is shared leadership? And what are the benefits?

##### Shared leadership

Shared leadership is a group quality, where everyone has the responsibility to do the job and carry out a set of functions.

The advantages of shared leadership are:

1. An increased commitment
2. More knowledge sharing
3. Openness towards other team members
4. Higher trust and respect

One crucial difference to hierarchical leadership is that shared leadership must be built and cannot be commanded. Management research identified three key factors that are needed to implement shared leadership:

1. Creating an open climate (create a common purpose, build trust and social support, allow for honest communication)
2. Encouraging leadership by other team members
3. Aligning on the common goals and purpose

However, teams with shared leadership should have anassigned or pre-defined leader. This leader's job is not to manage people and their tasks, as this is something done by the whole team. Instead, the leader is responsible for:

1. Information sharing, by encouraging and distributing visualization of tacit information
2. Enable better decision making by overcoming common team fallacies
    `,
    benefits: [],
    thumbImage: m4SharedLeadershipFrontImage,
    backImage: m4SharedLeadershipBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m4",
    name: "Decision Making In Teams",
    slug: "m4-decision-making",
    resources: [],
    content: mdx`
How to make decisions? Although it is one of the most important tasks of any executive, it is also one of the hardest. Making a wrong decision can harm an organization or career easily. Within the following, we will touch upon several pitfalls that affect the decision-making process and the mindset. These ‘decision defaults’ can be traced back to the psychological effects and are likely to undermine business decisions. When mitigating team failure, the leader holds a critical position. On the one hand, he or she is a facilitator of information, on the other hand, the coordinator of decisions.

In the following, you will learn about several hidden pitfalls and learn how to overcome them.

##### Amplification Effect

It is in our human nature to make decisions based on biased thoughts and mental shortcuts. In a group format, these effects are intensified. We tend to underestimate the needed time for a project; we become overconfident about making future predictions and assuming our tastes and ideas are representative of others. This effect occurs in a team even more amplified than as an individual.

Through such amplification effects, an idea moves rapidly from one person to another in the team. Eventually, this produces a belief within the group that the idea is, in fact, a truth, leading to decision making pitfalls. Trust in a team should never stand in the way of a critical mindset.

How to overcome this pitfall?

Even if trust within a team is a source for good group performance, you should still stay critical of each other. Stimulate criticality by:

1. Creating an open team culture where people are not afraid to be critical and reflect.
2. Encourage group discussions before finalizing a decision.

##### The Polarization Effect

When a group has to make a decision, they are likely to depart from an extreme converged point of view. Suppose a majority of the people is leaning towards a more risk-averse behavior. In that case, the cumulative result will be that the team is even more risk-averse than the individual team members. As a result, a team can miss out on otherwise promising solutions or options.

How to overcome this pitfall?

Similar to the Amplification effect, you can overcome this decision default by:

1. Staying critical to your team members’ judgments and arguments.
2. Do not be afraid to speak up and encourage discussion.

##### Common information bias

Teams tend to focus their conversations on information they have in common instead of sharing their expert knowledge. Commonly held information is considered more credible. Additionally, we avoid taking action that could damage our ego and avoid decisions that can potentially result in criticism and regret. Consequently, we stick with verdicts that are related to what is already known or accepted before.  This leads to one-sided, amplified decisions.

So, how can you overcome this pitfall?

Albeit choosing the status quo is the right decision, do not solely choose it because it is the most familiar decision to make. Instead, use these techniques to increase your awareness:

1. Remind yourself of your objectives.
2. Go beyond the status quo as your only alternative; identify other options as well.
3. Ask yourself, would you have chosen the status quo if it was not the status quo?
4. Do not emphasize the cost and effort of changing from the status quo.
5. The desirability of the status quo will change over time, therefore evaluate alternatives looking at both the present and the future

##### The Anchoring Effect

When making decisions, we tend to weigh the first information received over any secondary knowledge. Subsequently, initial data, expressions, or estimates anchor our thoughts and behavior.

In a business environment, we tend to inform future business decisions on past trends and data. This is a problem, as it highlights past events and neglects to look at other factors. In particular, when rapid changes in the marketplace occur, this approach can lead to poor forecasting.

So how can you avoid this pitfall?

Although the influence of anchors is widespread, you can reduce their impact as follows:

1. View a problem from different perspectives
2. View a problem first individually, then ask the opinion of others
3. Keep an open mindset and include information and opinions of a variety of people
4. Take care in anchoring the stakeholders you solicit for information
5. Take in particular care when negotiating, try to overcome to be anchored by an initial proposal

##### Sunk-Costs

A third decision fallacy is to justify decisions made in the past, even if they are no longer applicable to the new problem or situation. Past decisions can become like a ‘sunk cost’ where the resources spend on the first decision unconsciously influence future decisions, even if they are irrelevant to a new situation. This becomes a difficulty because we fail to admit to our mistakes or bad choices made. Subsequently, we rather stick with poor quality decisions than acknowledging the miscalculation and avoid the risk of public criticism.
Unfortunately, some corporate cultures stimulate the invisible force of Sunk-Costs.

How can you overcome this pitfall?

Overall, try to consciously set aside any sunk costs by adhering to the following tips:

1. Start decisions with a fresh beginners mindset
2. Involve and listen to those people who did not participate in previous decisions.
3. Find out why deviating from an earlier bad decision made will make you feel upset. Moreover, remember that you are allowed to make mistakes.
4. Take a look at which sunk-cost biases of direct stakeholders influence you.
5. Cultivate a culture where failure is accepted. Besides, focus on the quality of decision making and rather than solely on the outcome.

##### Confirming-Evidence

People tend to seek information that confirms their point of view in order to defend it. As a result, we less frequently consider information that is contradicting or conflicting with our preferred ruling.

The cause of this decision behavior can be traced back to two psychological strengths.

1. The impulse to subconsciously decide before we have all the information to make a sound decision.
2. We tend to be more engaged with argumentation we favor than with contrasting lines of reasoning.

How can you overcome this pitfall?

You can make sure that you are making the right choice by testing your subconscious with the following:

1. Check if you have examined all available evidence equally and stay critical when reading confirming information.
2. Involve a devil’s advocate or formulate counter-arguments yourself.
3. Honestly assess your efforts to find information to support your decision.
4. When asking advice of others, avoid asking leading questions.

##### Framing

The way you formulate a question or problem automatically defines how you will answer it. There are two ways to formulate a problem or question:

Gains versus losses

When a problem is formulated in terms of gains, people lean towards choosing the risk-averse option. However, when it is formulated in terms of avoiding losses, they favor taking a risk.

Formulating with a different reference point

You can solicit various reactions of people by framing your question differently, even if you ask the same. If you concretely change the reference point, for instance, a financial number, you change the mental connection and thereby its context.

In general, people adopt the formulation as it is presented instead of reformulating a problem or question to their understanding.

How can you overcome this pitfall?

Framing a problem or question right is always critical. To do so, consider the following:

1. Attempt to reframe the problem or question and look for red flags.
2. Stay neutral when formulating a problem or include different reference points.
3. Assess the formulation of your problem throughout the decision-making process several times.
4. When others formulate, challenge them by rephrasing the problem.

##### Estimating and Forecasting Errors

In our daily lives, we are constantly making estimates. As managers, you make judgments and forecasts; however, there is rarely clear feedback about the accuracy of these predictions. The danger of this force consequently lies within the fact that with uncertain situations, we are influenced by a set of invisible forces that distort our capability to assess different options rationally:

The invisible force of overconfidence

People tend to either underestimate the high end and overestimate the low end of a crucial variable, which results in the risk of missing opportunities and wasted money.

The invisible force of prudence

To be on the safe side’ people tend to adjust their estimates to favor the opposing party. However, being too careful in your estimation can backfire in your decision making, in particular in the long run.

The invisible force of recallability

Our estimations are often based on our memory of past events. In turn, we remember events with a high impact better than others. Consequently, dramatic or traumatic events influence your thinking more than regular, less memorable events.

How can you overcome this pitfall?

Approach estimations with rigor. Furthermore, these precautions can be taken:

1. First, when faced with overconfidence, take into consideration the extremes of the range of values. Also, think about occasions where the estimates could be different.
2. Second, when influenced by carefulness, consistently set honest estimates and explain to your collaborators that they have not been modified. Moreover, test their reasonability.
3. Last, to decrease the effect of faults in the recollection of your memory, carefully check your estimates and get verifiable data instead.
    `,
    benefits: [],
    thumbImage: m4DecisionMakingFrontImage,
    backImage: m4DecisionMakingBackImage,
  },
];
