import * as React from "react";
import { BaseApiService } from "../services/base-api-service";
import { BaseMockApiService } from "../services/base-mock-api-service";

const defaultService = new BaseMockApiService();

export const BaseApiServiceContext = React.createContext<BaseApiService>(defaultService);

export const useBaseApiServiceContext = () => React.useContext(BaseApiServiceContext);

export const BaseApiServiceContextProvider: React.FC<{
  apiService?: BaseApiService;
}> = props => {
  return (
    <BaseApiServiceContext.Provider value={props.apiService || defaultService}>
      {props.children}
    </BaseApiServiceContext.Provider>
  );
};
