import * as React from "react";
import { ModuleInfo } from "@sparkademy/app-common/models/module";
import { LevelInfo } from "@sparkademy/app-common/models/level";
import { getWithRetry } from "../utils/httpRetry";
import { API_URL } from "../constants";

async function getLevels(): Promise<LevelInfo[]> {
  const resp = await getWithRetry(`${API_URL}/content/levels`);
  return (await resp.json()) as Promise<LevelInfo[]>;
}

async function getModules(): Promise<ModuleInfo[]> {
  const resp = await getWithRetry(`${API_URL}/content/modules`);
  return (await resp.json()) as Promise<ModuleInfo[]>;
}

type ContentContextShape = {
  modules: ModuleInfo[];
  levels: LevelInfo[];
  isLoading: boolean;
};

export const ContentContext = React.createContext<ContentContextShape>({
  modules: [],
  levels: [],
  isLoading: true,
});

export const useContentContext = () => React.useContext(ContentContext);

export const ContentContextProvider: React.FC = ({ children }) => {
  const [modules, setModules] = React.useState<ModuleInfo[]>([]);
  const [levels, setLevels] = React.useState<LevelInfo[]>([]);

  const isLoading = modules.length === 0 || levels.length === 0;

  React.useEffect(() => {
    if (levels.length === 0) {
      getLevels()
        .then(res => {
          setLevels(
            res.map(
              level => new LevelInfo(level.id, level.name, level.description, level.commitment)
            )
          );
        })
        .catch(console.error);
    }
  }, [levels.length]);

  React.useEffect(() => {
    if (modules.length === 0) {
      getModules()
        .then(res => {
          setModules(
            res.map(
              mod =>
                new ModuleInfo(
                  mod.index,
                  mod.id,
                  mod.levelId,
                  mod.name,
                  mod.description,
                  mod.commitment,
                  mod.level
                )
            )
          );
        })
        .catch(console.error);
    }
  }, [modules.length]);

  return (
    <ContentContext.Provider value={{ modules, levels, isLoading }}>
      {children}
    </ContentContext.Provider>
  );
};
