/** @jsx jsx */
import { jsx, SxProps, SxStyleProp } from "theme-ui";
import * as React from "react";
import { getMethodKitColorForLevel } from "@sparkademy/app-common/utils/theme";

export const ACTIVE_BORDER_SIZE = 7;
export const SHADOW_SIZE = 10;
export const ACTIVE_HEIGHT_INCREASE = 10;
export const INATIVE_BORDER_SIZE = 1;

type Props = {
  disabled?: boolean;
  completed?: boolean;
  failed?: boolean;
  level: string;
} & SxProps;

const openStyles = (levelId: string): SxStyleProp => ({
  bg: "white",
  color: getMethodKitColorForLevel(levelId),
  ".text-alternative": {
    color: "grays.lighter",
  },
  boxShadow: theme => `
  0 0 0 ${ACTIVE_BORDER_SIZE}px ${getMethodKitColorForLevel(levelId)},
  ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px 0 0 ${
    theme.colors.black
  }
  `,
  ":hover": {
    cursor: "pointer",
    bg: getMethodKitColorForLevel(levelId),
    color: "white",
    ".text-alternative": {
      color: "brand.blueLight",
    },
    transform: "translateY(-10px)",
    boxShadow: theme => `
          0 0 0 ${ACTIVE_BORDER_SIZE}px ${getMethodKitColorForLevel(levelId)},
          ${SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10}px ${
      SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10
    }px 0 0 ${theme.colors.black}
        `,
  },
  ":focus-within": {
    cursor: "pointer",
    bg: getMethodKitColorForLevel(levelId),
    color: "white",
    ".text-alternative": {
      color: "brand.blueLight",
    },
    transform: "translateY(-10px)",
    boxShadow: theme => `
          0 0 0 ${ACTIVE_BORDER_SIZE}px ${getMethodKitColorForLevel(levelId)},
          ${SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10}px ${
      SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10
    }px 0 0 ${theme.colors.black}
        `,
  },
  ":before, :after": {
    content: '""',
    height: SHADOW_SIZE + ACTIVE_HEIGHT_INCREASE * 2,
    width: "100%",
    display: "block",
  },
});

const closedStyles: SxStyleProp = {
  bg: "white",
  color: "grays.lighter",
  boxShadow: theme => `
  0 0 0 ${INATIVE_BORDER_SIZE}px ${theme.colors.grays.medium},
  ${SHADOW_SIZE + INATIVE_BORDER_SIZE}px ${SHADOW_SIZE + INATIVE_BORDER_SIZE}px 0 0 ${
    theme.colors.black
  }
  `,
};

const failedStyles: SxStyleProp = {
  bg: "white",
  color: "grays.lighter",
  boxShadow: theme => `
  0 0 0 ${ACTIVE_BORDER_SIZE}px ${theme.colors.brand.red},
  ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px 0 0 ${
    theme.colors.black
  }
  `,
};

const failedClickableStyles: SxStyleProp = {
  bg: "white",
  color: "grays.lighter",
  boxShadow: theme => `
  0 0 0 ${ACTIVE_BORDER_SIZE}px ${theme.colors.brand.red},
  ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px 0 0 ${
    theme.colors.black
  }
  `,

  ":hover": {
    cursor: "pointer",
    bg: "brand.red",
    color: "white",
    ".text-alternative": {
      color: "brand.redLight",
    },
    transform: "translateY(-10px)",
    boxShadow: theme => `
          0 0 0 ${ACTIVE_BORDER_SIZE}px ${theme.colors.brand.red},
          ${SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10}px ${
      SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10
    }px 0 0 ${theme.colors.black}
        `,
  },
  ":focus-within": {
    cursor: "pointer",
    bg: "brand.red",
    color: "white",
    ".text-alternative": {
      color: "brand.redLight",
    },
    transform: "translateY(-10px)",
    boxShadow: theme => `
          0 0 0 ${ACTIVE_BORDER_SIZE}px ${theme.colors.brand.red},
          ${SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10}px ${
      SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10
    }px 0 0 ${theme.colors.black}
        `,
  },
};

const completedStyles = (levelId: string): SxStyleProp => ({
  bg: getMethodKitColorForLevel(levelId),
  color: "white",
  ".text-alternative": {
    color: "brand.blueLight",
  },
  boxShadow: theme => `
  0 0 0 ${ACTIVE_BORDER_SIZE}px ${getMethodKitColorForLevel(levelId)},
  ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px ${SHADOW_SIZE + ACTIVE_BORDER_SIZE}px 0 0 ${
    theme.colors.black
  }
  `,
  ":hover": {
    cursor: "pointer",
    transform: "translateY(-10px)",
    boxShadow: theme => `
          0 0 0 ${ACTIVE_BORDER_SIZE}px ${getMethodKitColorForLevel(levelId)},
          ${SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10}px ${
      SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10
    }px 0 0 ${theme.colors.black}
        `,
  },
  ":focus-within": {
    cursor: "pointer",
    transform: "translateY(-10px)",
    boxShadow: theme => `
          0 0 0 ${ACTIVE_BORDER_SIZE}px ${getMethodKitColorForLevel(levelId)},
          ${SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10}px ${
      SHADOW_SIZE + ACTIVE_BORDER_SIZE + 10
    }px 0 0 ${theme.colors.black}
        `,
  },
});

export const CardClickable: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  sx = {},
  level,
  disabled,
  failed,
  completed,
  ...rest
}) => (
  <article
    sx={{
      display: "flex",
      flexDirection: "column",
      position: "relative",
      transition: "transform 300ms ease-out, box-shadow 300ms ease-out, background 300ms ease-out",
      width: "100%",
      height: "100%",

      ...(!failed && !disabled && !completed ? openStyles(level) : {}),
      ...(failed && !disabled ? failedClickableStyles : {}),
      ...(failed && disabled ? failedStyles : {}),
      ...(disabled && !failed ? closedStyles : {}),
      ...(completed ? completedStyles(level) : {}),
      ...sx,
    }}
    {...rest}
  />
);
