/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";

const NARRATIVE_STYLES: {
  [styleName: string]: SxStyleProp;
} = {
  "arrow-h3": {
    "h3::before": {
      content: '">"',
      color: "new.primary.green",
      mr: 3,
    },
  },
  "arrow-list-after": {
    ".arrow-list-after + ul li::before": {
      content: '">"',
      color: "new.primary.green",
      mr: 3,
      fontWeight: "bold",
    },
  },
};

export const Narrative: React.FC<
  {
    sx?: SxStyleProp;
    styleNames?: string[];
  } & React.HTMLProps<HTMLDivElement>
> = ({ sx = {}, styleNames = [], ...rest }) => (
  <Box
    sx={{
      fontSize: 1,
      "h1, h2, h3, h4, h5, h6, p": {
        margin: 0,
      },
      "h2:not(:first-of-type), h3:not(:first-of-type), h4:not(:first-of-type), h5:not(:first-of-type), h6:not(:first-of-type)":
        {
          mt: "2em",
        },
      "p:not(:first-of-type)": {
        mt: "1em",
      },
      "h2 + h3, h3 + h4, h4 + h5": {
        marginTop: "2em !important",
      },
      p: {
        fontSize: [0, 1],
        maxWidth: "65ch",
        lineHeight: 1.4,
      },
      "p + p": {
        mt: 4,
      },
      "p:first-of-type": {
        marginTop: 0,
      },
      h2: {
        fontSize: 3,
        // color: 'brand.blue'
      },
      h3: {
        fontSize: 2,
      },
      ul: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
      },
      ol: {
        margin: 0,
        ml: 5,
        padding: 0,
      },
      "ul li:before": {
        // display: 'inline-block',
        content: '"— "',
        mr: 2,
        // backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMi4wMSAzMS4zMyI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOm5vbmU7c3Ryb2tlOiM0YjI3ZTA7c3Ryb2tlLW1pdGVybGltaXQ6MTA7c3Ryb2tlLXdpZHRoOjJweDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPkFycm93X2JsdWU8L3RpdGxlPjxnIGlkPSJMYXllcl8xIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTMuMTIgMTAuMDQgMTguODkgMTUuODQgMTMuMTIgMjEuMjkiLz48L2c+PC9zdmc+")`,
        // width: '1.5em',
        // height: '1.5em',
        verticalAlign: "top",
        // mr: 4,
        // marginLeft: '-10px'
      },
      li: {
        mt: "1em",
        fontSize: 1,
        lineHeight: 1.4,
        maxWidth: "65ch",
      },
      "li > a": {
        display: "inline",
      },
      a: {
        color: "brand.blue",
        textDecoration: "none",
      },
      "a:hover": {
        textDecoration: "underline",
      },
      "a:hover, a:focus-visible": {
        color: "brand.blueLight",
      },
      "p em": {
        fontStyle: "italic",
      },
      img: {
        maxWidth: "100%",
        mt: 10,
      },
      blockquote: {
        p: 6,
        m: 6,
        bg: "new.secondary.lightGrey",
      },
      video: {
        maxWidth: "100%",
      },
      "audio, video": {
        my: 6,
      },
      hr: {
        my: 14,
      },
      ...styleNames.reduce(
        (acc, cur) => ({
          ...acc,
          ...(NARRATIVE_STYLES[cur] || {}),
        }),
        {}
      ),
      ".visually-hidden": {
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: "1px",
        overflow: "hidden",
        position: "absolute",
        whiteSpace: "nowrap",
        width: "1px",
      },
      ...sx,
    }}
    {...rest}
  />
);
