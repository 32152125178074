/** @jsx jsx */
import { jsx, SxProps, Box } from "theme-ui";
import * as React from "react";
import { ReactComponent as ArrowFoward } from "@sparkademy/app-common/materials/icons/arrow_foward.svg";

type Props = {
  sx?: SxProps;
};

export const List: React.FC<Props & React.HTMLProps<HTMLUListElement>> = ({
  sx = {},
  children,
  ...rest
}) => (
  <ul
    sx={{
      listStyle: "none",
      p: 0,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </ul>
);

export const ListItem: React.FC<Props> = ({ sx = {}, children }) => (
  <li
    sx={{
      display: "block",
      ...sx,
    }}
  >
    {children}
  </li>
);

export const ListItemArrow: React.FC<Props> = ({ sx = {}, children }) => (
  <li
    sx={{
      display: "flex",
      alignItems: "flex-start",
      ...sx,
    }}
  >
    <Box
      sx={{
        flex: "0 0 auto",
        width: 28,
        height: 28,
        mr: 2,
        transform: "translateY(-3px)",
        color: "brand.blue",
        svg: {
          width: "100%",
          height: "auto",
        },
      }}
    >
      <ArrowFoward />
    </Box>
    {children}
  </li>
);
