/** @jsx jsx */
import { jsx, Container as BaseContainer, SxProps } from "theme-ui";
import * as React from "react";

type Props = {
  sx?: SxProps;
};

export const Container: React.FC<Props> = ({ sx = {}, ...rest }) => (
  <BaseContainer
    sx={{
      maxWidth: 1240,
      px: 5,
      ...sx,
    }}
    {...rest}
  />
);

export const ContainerSmall: React.FC<Props> = ({ sx = {}, ...rest }) => (
  <BaseContainer
    sx={{
      maxWidth: 1108,
      px: 5,
      ...sx,
    }}
    {...rest}
  />
);
