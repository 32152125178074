import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Heading, Image, AspectRatio, Text } from "theme-ui";
import { Tool } from "@sparkademy/app-common/models/tool";
import { Module, ModuleInfo } from "@sparkademy/app-common/models/module";
import {
  CardClickable,
  SHADOW_SIZE,
} from "@sparkademy/app-common/elements/CardClickable";
import { getMethodKitColorForLevel } from "@sparkademy/app-common/utils/theme";

type Props = {
  tool: Tool;
  module: Module;
  moduleInfo: ModuleInfo;
  isPublic: boolean;
};

export const MethodCardThumb: React.FC<Props> = ({
  tool,
  moduleInfo,
  isPublic,
}) => {
  return (
    <CardClickable
      level={moduleInfo.level}
      completed
      sx={{
        textDecoration: "none",
        borderColor: getMethodKitColorForLevel(moduleInfo.level),
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 2,
        boxShadow: theme => `
          0px 0px 0 0 ${theme.colors.black}
        `,
        width: "100%",
        a: {
          textDecoration: "none",
          color: "text",
          fontSize: 0,
        },
        ":hover": {
          boxShadow: theme => `
            ${SHADOW_SIZE}px ${SHADOW_SIZE}px 0 0 ${theme.colors.black}
          `,
          transform: "translateY(-10px)",
        },
        ":focus-within": {
          boxShadow: theme => `
            ${SHADOW_SIZE}px ${SHADOW_SIZE}px 0 0 ${theme.colors.black}
          `,
          transform: "translateY(-10px)",
        },
        "& a:focus-visible": {
          outline: "none",
        },
        "& a:before": {
          display: "none",
        },
      }}
    >
      <Link id={tool.slug} to={isPublic ? tool.slug : `/method-kit/${tool.slug}`}>
        <Heading
          as="h3"
          sx={{
            fontSize: [0, tool.name.length > 20 ? ".9rem" : 0],
            fontWeight: "bold",
            px: 3,
            py: 2,
            bg: "white",
          }}
        >
          {tool.name}
        </Heading>
        <Image
          src={tool.thumbImage}
          alt={`illustration of ${tool.name}`}
          sx={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Link>
    </CardClickable>
  );
};
