// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

// eslint-disable-next-line
import { CloudflareEmbedPlayer } from "../../components/CloudflareEmbedPlayer";

// m2 assets
import m2FrameworksImage from "../method-kit/m2/01-frameworks.svg";
import m2TwoByTwoImage from "../method-kit/m2/02-two-by-two.svg";
import m2PersonaImage from "../method-kit/m2/03-persona.svg";
import m2PersonaBackImage from "../method-kit/m2/back/03-persona.svg";
import m2CustomerJourneyImage from "../method-kit/m2/04-customer-journey.svg";
import m2CustomerJourneyBackImage from "../method-kit/m2/back/04-customer-journey.svg";
import m2ProblemStatementImage from "../method-kit/m2/05-problem-statement.svg";
import m2ProblemStatementBackImage from "../method-kit/m2/back/05-problem-statement.svg";
import m2IdeationPlanningImage from "../method-kit/m2/06-ideation-planning.svg";
import m2IdeationPlanningBackImage from "../method-kit/m2/back/06-ideation-planning.svg";
import m2IdeationPrinciplesImage from "../method-kit/m2/07-ideation-principles.svg";
import m2IdeationPrinciplesBackImage from "../method-kit/m2/back/07-ideation-principles.svg";
import m2EnergizerPrinciplesImage from "../method-kit/m2/08-energizer-principles.svg";
import m2YesAndImage from "../method-kit/m2/09-yes-and.svg";
import m2YesAndBackImage from "../method-kit/m2/back/09-yes-and.svg";
import m2IAmTreeImage from "../method-kit/m2/11-i-am-a-tree.svg";
import m2IAmTreeBackImage from "../method-kit/m2/back/11-i-am-a-tree.svg";
import m2SilentBrainstormingImage from "../method-kit/m2/12-silent-brainstorming.svg";
import m2HerbstlaubImage from "../method-kit/m2/13-herbstlaub.svg";
import m2ConstraintBrainstormingImage from "../method-kit/m2/14-constraint-brainstorming.svg";
import m2BuildingOnEachOtherImage from "../method-kit/m2/15-building-on-each-other.svg";
import m2ClusteringImage from "../method-kit/m2/16-clustering.svg";
import m2ImpactAnalysisImage from "../method-kit/m2/17-impact-effort-analysis.svg";
import m2ImpactAnalysisBackImage from "../method-kit/m2/back/17-impact-effort-analysis.svg";
import m2DotVotingImage from "../method-kit/m2/18-dot-voting.svg";
import m2IdeaNapkinImage from "../method-kit/m2/19-idea-napkin.svg";
import m2IdeaNapkinBackImage from "../method-kit/m2/back/19-idea-napkin.svg";
import m2WorldCafeImage from "../method-kit/m2/20-world-cafe.svg";
// @ts-ignore
import m2BuildingOnEachOtherTemplate from "../method-kit/m2/building-on-each-other-template.pdf";
// @ts-ignore
import m2CustomerJourneyTemplate from "../method-kit/m2/customer-journey-template.pptx";
// @ts-ignore
import m2IdeaNapkinTemplate from "../method-kit/m2/idea-napkin-template.pptx";
// @ts-ignore
import m2ImpactEffortAnalysisTemplate from "../method-kit/m2/impact-effort-analysis-template.pdf";
// @ts-ignore
import m2PersonaTemplate from "../method-kit/m2/persona-template.pptx";
// @ts-ignore
import m2ProblemStatementTemplate from "../method-kit/m2/problem-statement-template.pdf";

export const tools: Tool[] = [
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Frameworks",
    slug: "m2-frameworks",
    resources: ["whiteboard", "Post-Its", "markers"],
    content: mdx`
Frameworks are visual representations of a system and an excellent tactic to make sense of data. They support you in visualizing your findings and understand key relationships.

Having deduced the learnings from the empathy work, it might feel like you have gathered tons of information but have no way to organize it. By creating frameworks, you can synthesize learnings and bring clarity to what is often highly complex. Depending on your available information, you will have to choose and try different frameworks such as Persona, Customer Journey, Floor Plan, Venn Diagram, Two by Two, and Geographic Map to best visualize your opportunity space.
    `,
    benefits: [
      "Increase a team’s productivity and creativity",
      "Help to categorize and organize ideas",
      "Reveal opportunities",
      "Visualize patterns and correlations",
    ],
    thumbImage: m2FrameworksImage,
    backImage: m2FrameworksImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Two By Two",
    slug: "m2-two-by-two",
    resources: ["whiteboard", "Post-Its", "markers"],
    content: mdx`
A two-by-two is a framework that helps to synthesize the information collected during qualitative research. It is a simple two-by-two diagram divided into four equal quadrants. In the diagram, each axis represents a spectrum of a certain attribute. The two-by-two allows you to group users in sharing the same opinion and behavior to form representative archetypes.

Keep in mind that you might have to try different combinations until you find one that is meaningful and informative. To get started, think about project-related behavior and opinions that might be useful as attributes, but don't limit yourself to your first idea.

Tip: Pay attention to spaces that contain lots of data or are empty. Those extremes are usually very insightful and can hint at potential design opportunities.
    `,
    benefits: [
      "Visualizes and structures existing knowledge",
      "Encourages comprehensiveness",
      "Reveals design opportunities and existing gaps",
    ],
    thumbImage: m2TwoByTwoImage,
    backImage: m2TwoByTwoImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Persona",
    slug: "m2-persona",
    resources: [
      "template or blank paper",
      "markers",
      "mood visuals found online",
      "in magazines or newspapers",
    ],
    content: mdx`
Personas are fictional human-based models or distinct archetypes. They synthesize multiple users into a single valid composite, evidence-based character. The persona represents a type or category of users who share common attributes regarding your challenge area, product, or service use.

Unlike classical market research, Personas don’t divide customers into market segmentation and demographics. Personas are human beings or living organizations with problems, needs, goals, habits, that live and work in specific environments. Your team should review all the findings gathered during research and identify reoccurring behaviors and routines. Common pains and gains are the basis for developing distinctive archetypes that personify your learnings.

Watch this short video to see how a Persona was created for a project on commuting in trains.

<CloudflareEmbedPlayer videoId="15ffcd6d21d29f887b7600cc29ea9949" />

    `,
    benefits: [
      "Broadens mind-sets",
      "Builds empathy",
      "Defines direction",
      "Facilitates storytelling",
    ],
    thumbImage: m2PersonaImage,
    backImage: m2PersonaBackImage,
    template: m2PersonaTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Customer Journey",
    slug: "m2-customer-journey",
    resources: ["whiteboard", "Post-Its", "markers"],
    content: mdx`
Statistically and demographically driven data collection often fails to capture users’ human experiences and feelings. Customer Journeys are engaging and graphical storylines that provide a vivid but structured visualization of your user’s experience. Customer Journeys help you look at the big picture by identifying the main touchpoints of the process, the relevant stakeholders engaged at each one of its steps, and the key interactions between the users and your company in chronological order.

A customer journey consists of:

- Context (Persona, scenario, overarching goals)
- User experience (Touchpoints, actions, goals, behavior, and
  thoughts, emotional experience)
- Defining outputs (emerging opportunities, prioritization of design)

Tip: Base the value of the Customer Journey on both the output and the process of co-creating. Avoid the trap of building an easy, assumption-based journey, but use reliable data instead.
    `,
    benefits: [
      "Visualizes reoccurring behavioral patterns",
      "Supports assessing your product’s or service’s overall performance",
      "Reveals an outside-in perspective on design opportunities",
      "Facilitates storytelling",
    ],
    thumbImage: m2CustomerJourneyImage,
    backImage: m2CustomerJourneyBackImage,
    template: m2CustomerJourneyTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Problem Statement",
    slug: "m2-problem-statement",
    resources: ["whiteboard", "markers"],
    content: mdx`
A Problem Statement re-frames your insight(s) into an actionable point-of-view that guides you during the ideation phase. A good Problem Statement will allow your team to ideate in a focused way and is formatted as a ‘How might we’ question based on your user’s identified needs. Phrasing the Problem Statement as a question helps you generate as many ideas as possible and detect new opportunity spaces.

A Problem Statement consists of three parts:

- The actor or user
- His/ her need
- An insight from your research (e.g., constraint)

The key is to find the right balance between being too broad (not knowing where to start) and too narrow (hindering creativity). Quickly test this on your problem statement: Are you able to come up with five possible and diverse solutions in a few minutes? If yes, you have set the right direction for creating a solution that responds to your users’ problem(s)!

Watch this short video to see how you can develop a Problem Statement that is neither too broad nor too narrow.

<CloudflareEmbedPlayer videoId="acd5bf260e567779ff1e5f6f2882c48f" />
    `,
    benefits: [
      "Focuses on and re-frames the identified problem/ opportunity",
      "Captures the hearts and minds of people you met",
      "Fuels a plan for the ideation phase",
      "Sets the boundaries for the rest of the development process",
    ],
    thumbImage: m2ProblemStatementImage,
    backImage: m2ProblemStatementBackImage,
    template: m2ProblemStatementTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Ideation Planning",
    slug: "m2-ideation-planning",
    resources: ["people", "place", "process"],
    content: mdx`
Setting the stage for divergent thinking is vital to stimulate you, your team, or your participant’s ability to think creatively.

Generally, ideation aims to explore a wide solution space, having both a large number of ideas and diversity among them. This process requires creativity and out-of-the-box thinking. By considering the following three aspects upfront, you can activate this:

**People**:<br /> The participants. Innovation doesn’t happen in isolation; therefore, collaboration is key. Invite people with different skills, expertise, and perspectives to engage, interact, and stimulate multi-disciplinary cooperation.

**Place**:<br /> A room sets behavior. It determines the way we move, behave, and interact. We need a space that fosters collaboration and dialogue, which is designed to encourage movement, agility, and has the opportunity to visualize findings and ideas.

**Process**:<br /> A well-designed approach composed of different methodologies and tools. Apply an agile and customer-focused mindset and a non-linear, step-by-step process like Design Thinking, Agile, or Lean.
    `,
    benefits: [],
    thumbImage: m2IdeationPlanningImage,
    backImage: m2IdeationPlanningBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Ideation Principles",
    slug: "m2-ideation-principles",
    resources: [],
    content: mdx`
**Defer judgment**<br />During ideation, no idea is a bad idea. A non-judgmental environment makes people confident in expressing their opinions. Say ‘yes and’ instead of ‘no but’, there is plenty of time to evaluate and filter ideas later on.

**Go for quantity**<br />It is not the time to evaluate ideas yet. Aim for as many new ideas as possible: a greater number of ideas leads to a bigger chance of producing a radical and impactful solution. Stimulate the ‘yes, and’ attitude, instead of saying ‘no but’.

**Encourage wild ideas**<br />Generate weird, crazy ideas that force you to think about what you really want. Remember: it is easier to dilute wild ideas than to generate entirely new ones. Dare to think differently!

**Build on the ideas of others**<br />When we listen to other people, we lower the risk of getting trapped in our thinking structures and increase the chances of sparking new ideas. Let external expertise and insights inspire your solution. Look for examples and analogies across industries.

**Be visual**<br />Nothing can transform an abstract idea into a concrete solution faster than drawing it. Combining sketches with notes helps you activate the whole brain to synthesize, comprehend, and remember ideas better. Artist or not, it is better than not drawing at all!
    `,
    benefits: [],
    thumbImage: m2IdeationPrinciplesImage,
    backImage: m2IdeationPrinciplesBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Energizer Principles",
    slug: "m2-energizer-principles",
    resources: ["space", "people", "templates and materials (if necessary)"],
    content: mdx`
Energizers, or warm-ups, are activities used in workshops and group situations to help participants enter a particular working mode.

In general, energizers have multiple purposes. For instance, when participants are new to each other, energizers are a great approach to stimulate group-feeling and support radical collaboration. This way, they can get to know and understand one another.

In addition, use energizers after a break or when the attention and motivation are floating away. It is a great way to get the participants back on track, recreate focus, and make them alert and active again.

Finally, energizers are a valuable mechanism to build the right mindset within the different stages of the Design Thinking Process. In particular, when entering an ideation or brainstorm session, the energizers are an excellent means for stirring the divergent, creative, and imaginative mindset.

Some examples of brainstorming energizers are:

- Yes, and...
- Alternate Uses
- 20 circle exercise
- "I am a Tree."
    `,
    benefits: [],
    thumbImage: m2EnergizerPrinciplesImage,
    backImage: m2EnergizerPrinciplesImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Yes And...",
    slug: "m2-yes-and",
    resources: ["no materials needed, just team participation"],
    content: mdx`
The “Yes, and...” warm-up aims at building a positive mindset and iterate on others’ ideas.

To facilitate this warm-up, follow the following steps:

- Have participants form small groups (3-4 people) or pairs and tell them they will plan an activity, for example, a party.
- In the first round, each time someone proposes an idea, someone else should answer with “Yes, BUT...“ (the reason why this is not going to work).
- In the second round, the response should be “Yes, AND..." (add something that builds on the first idea).

Debrief the change of group energy between the first and second round and what kind of ideas came out in the two sessions.
    `,
    benefits: [
      "Creates a positive and open mindset",
      "Building on each other",
      "Stimulates creative thinking",
    ],
    thumbImage: m2YesAndImage,
    backImage: m2YesAndBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "I Am A Tree",
    slug: "m2-i-am-a-tree",
    resources: ["at least 3 people"],
    content: mdx`
The "I am a Tree" energizer is a great warm-up for multiple reasons. Besides physically using the space and building team spirit, this energizer is commonly used to warm up participants imaginative and creative capabilities.

To facilitate this warm-up, follow the following steps:

- Form a circle with all the participants. The activity starts with one person saying "I am a tree" and stepping into the middle of the circle.
- The next person steps inside and makes an association with the tree: "I am a bird" and acts it out at the same time.
- The following person steps into the middle and makes an association with the tree and the bird: "I am a worm".
- Each new entrant starts with "I am..." and every time there are three people in the circle, the next time someone steps in, the person who has been inside the longest steps out again.

Repeat this exercise in random order until everyone has contributed at least 2 - 3 times.
    `,
    benefits: [
      "Builds team spirit",
      "Stimulates the creative mindset",
      "Building on each other",
      "Respond quickly to change",
    ],
    thumbImage: m2IAmTreeImage,
    backImage: m2IAmTreeBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Silent Brainstorming",
    slug: "m2-silent-brainstorming",
    resources: ["Post-Its", "markers"],
    content: mdx`
Silent Brainstorming, also known as "braindump", is a useful technique for generating many different ideas and solutions to a problem without distractions or influences from others.

Within a given time-frame, you explore the opportunity space by silently noting down a variety of ideas on single post-its. It is instrumental at the beginning of every brainstorming session to “dump out” all the obvious ideas that you thought of until now without distraction or influences of others.

Silent Brainstorming is useful when working in groups because it gives each individual the chance to explore their ideas and then share their information equally and democratically within the team.

Important: Keep a time constraint; otherwise you encounter the danger of losing the momentum.
    `,
    benefits: [
      "Prevents the teams from being influenced by a single point of view",
      "Identifies opportunities",
      "Broadens mindset",
      'It generates the "Power of Many"',
    ],
    thumbImage: m2SilentBrainstormingImage,
    backImage: m2SilentBrainstormingImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Herbstlaub",
    slug: "m2-herbstlaub",
    resources: ["Post-Its", "markers", "music (optional)"],
    content: mdx`
The Herbstlaub technique is a great team activity to excite and share new ideas. This ideation technique uses physical movement and rhythm to stimulate a more energetic and fast-paced generation of ideas.

It allows you to actively produce a large quantity and variety of solutions by building on one another’s ideas. During the ideation phase, team members are asked to form a circle and place their problem statement in the middle. Stimulated by upbeat music and while walking around the circle, they quickly write down different ideas that solve their challenge on colorful post-its.

Just like autumn leaves, participants let their post-it ideas fall to the ground. For example, you can do several rounds using different paced music, jump, or even walk backward. The combination of physical and mental movement helps you and your team to overcome a mind block and fosters creativity.
    `,
    benefits: [
      "Encourages flow of ideas",
      "Helps to focus on quantity over quality",
      "Facilitates a collaborative team environment",
      "Inspires ideation",
    ],
    thumbImage: m2HerbstlaubImage,
    backImage: m2HerbstlaubImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Constraint Brainstorming",
    slug: "m2-constraint-brainstorming",
    resources: [
      "whiteboard",
      "Post-Its",
      "markers",
      "brainstorming cards with constraints (optional)",
    ],
    content: mdx`
Constraint Brainstorming, as an ideation method, can be applied in any team setting. Instead of just freely going for every possible idea, this brainstorming method employs specific constraints and different perspectives.

These help you and your team step out of your usual way of thinking and see problems differently. You have the option to choose constraints/ perspectives based on your project.
However, it is always helpful to select those extreme cases that primarily promote crazy and unconventional ideas. For example, take the perspective of a superhero or imagine you have an unlimited budget.

Here are some classic examples:

- “How would superman/ Bill Gates/ .. solve this problem?”
- “What if you used gamification/ AI/ .. to solve this problem?”
- “What if the solution has to be as cheap/ child-friendly/ .. as possible?”
    `,
    benefits: [
      "Stimulates the generation of new ideas",
      "Broadens mindset",
      "Encourages less conventional solutions",
    ],
    thumbImage: m2ConstraintBrainstormingImage,
    backImage: m2ConstraintBrainstormingImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Building On Each Other",
    slug: "m2-building-on-each-other",
    resources: ["template (3 by 6 table)", "markers"],
    content: mdx`
By building on the ideas of your team members, you can leverage your combined knowledge and creativity. You collectively generate new ideas that might not have been sparked in an individual brainstorming. To do so, you can use the so-called 6-3-5 method (6 participants, 3 initial ideas each, 5 iterations), which results in a lot of ideas in a very short time frame.

**Step 1:** Every participant receives a template and comes up with 3 different ideas (alternatively, you can just use post-it’s);

**Step 2:** After 3-5min, everyone hands over the template to the person on the right. Each participant can now develop, complement, or rethink the first ideas by adding new elements or modifying existing ones;

**Step 3:** Repeat until each template is full, and the cycle is complete.

Tip: Do not explain your ideas; misunderstandings can lead to entirely new solutions you would not have thought of otherwise.
    `,
    benefits: [
      "Inspires and creates a lot of ideas quickly",
      "Explores untouched solution spaces",
      "Leverages on collective ideas, brings them to the next level",
    ],
    thumbImage: m2BuildingOnEachOtherImage,
    backImage: m2BuildingOnEachOtherImage,
    template: m2BuildingOnEachOtherTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Clustering",
    slug: "m2-clustering",
    resources: ["template or whiteboard", "markers"],
    content: mdx`
Cluster Analysis or Clustering is the process of grouping observations or ideas of similar kinds into smaller groups within the larger population. By identifying similarities with respect to specific concepts or dimensions, this method helps you to make sense of your data. Organize the available data into meaningful structures and identifies hidden patterns. Try to be visual and organize your ideas into clusters. For each cluster write a good summary note considering the following aspects:

- Use a different color sticky note for your summaries
- Should be interesting and a trade-off between abstraction and precision, but still capture the essence of the learning notes within it
    `,
    benefits: [
      "Facilitates comparison",
      "Helps segmentation of big data sets",
      "Helps to discover new patterns",
      "Reveal trends",
    ],
    thumbImage: m2ClusteringImage,
    backImage: m2ClusteringImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Impact/Effort Analysis",
    slug: "m2-impact-effort-analysis",
    resources: ["template or whiteboard", "markers"],
    content: mdx`
The Impact/Effort Analysis is an efficient and straightforward tool for prioritizing and choosing out of many potential ideas. It is a two-by-two matrix that provides a quick visual guide to help you and your team evaluate several options against the impact gained or generated for the user and the effort and resources required to carry out and implement the idea.

The analysis steps are straightforward: assess each idea and map it onto the quadrant, reflecting its level of impact and effort. For example, solutions falling into the upper- left quadrant are those that yield the highest impact with minimum effort. The top-right quadrant ideas still deliver a high impact; however, they require more resources to be implemented.

Depending on your objectives and the specific setting you are operating in, this tool will help you cluster your ideas and select those aligned with your goal.
    `,
    benefits: [
      "Facilitates comparison of impact and effort of ideas",
      "Helps to analyze big collections of ideas",
      "Helps your team to prioritize",
    ],
    thumbImage: m2ImpactAnalysisImage,
    backImage: m2ImpactAnalysisBackImage,
    template: m2ImpactEffortAnalysisTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Dot-Voting",
    slug: "m2-dot-voting",
    resources: ["colored dots or stickers"],
    content: mdx`
The Design Thinking ideation process often generates many, wide-ranging ideas. To harvest that brainstorm and ensure that ideas do not just sit there on the board, they need to be grouped and filtered.

Dot-Voting is a great group or individual tool to select the most impactful ideas to carry forward by preserving the breadth of solutions and not settling for the safe choice. In short, it is a simple tool to democratically prioritize items or make decisions in a group.

Participants are each given a set number of colored dot stickers, representing different selection criteria. With this in mind you do not vote for your favorite ideas, but for the ones that fulfill the identified criteria. Such as most rational, resonant or radical. The ideas with the most dots are taken forward for the final decision, e.g. in a group discussion. You can, but you don't have to continue with the idea which got the most votes. Remember the "leftover" ideas, as they might be useful inspiration in the prototyping phase or when pivoting.
    `,
    benefits: [
      "Helps to recognize preferences and potentials",
      "Independent voting allows all teammates to have a voice",
      "Encourages to hang onto crazy, disruptive but meaningful ideas",
    ],
    thumbImage: m2DotVotingImage,
    backImage: m2DotVotingImage,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "Idea Napkin",
    slug: "m2-idea-napkin",
    resources: ["paper", "template", "marker or pen"],
    content: mdx`
The Idea Napkin is an inspiring visual thinking tool that provides a simple, consistent format for summarizing and communicating a new concept. The name originates from the notion that a good idea can be expressed simply, for instance, on the back of a napkin. By starting with sketching your idea and naming it, you make the idea more realistic and conceivable. Hereafter, this tool offers you the possibility to think about the most critical aspects of the idea you are trying to develop at a preliminary stage.

These aspects include:

**Value proposition:** what is the value-added of your idea?<br />
**User:** who are you designing for?<br />
**Functionalities:** how does your solution work?<br />
**Open questions:** what are your untested assumptions?<br />

By sketching your idea on paper, the Idea Napkin makes it easier to explain your solution to the audience, collect feedback, and inspire more discussion.
    `,
    benefits: [
      "Helps you balance user and business needs",
      "Structures ideation",
      "Promotes shared understanding",
      "Makes abstract ideas concrete",
    ],
    thumbImage: m2IdeaNapkinImage,
    backImage: m2IdeaNapkinBackImage,
    template: m2IdeaNapkinTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m2",
    name: "World Cafe",
    slug: "m2-world-cafe",
    resources: ["paper table cloths", "markers"],
    content: mdx`
The World Café methodology is a simple, effective, and flexible format to ask for feedback and encourage group dialogue. Small groups of three to six participants sit around a table to discuss and share their ideas and perspective on a topic.

After an agreed-upon amount of time, half of the group rotates clockwise to the next table, where the remaining half introduces the challenge and prior dialogue. With the World Café, you continuously rotate so that each team member has the chance to sit at a different table and share their feedback. World Café can be modified to meet a wide variety of needs, such as testing concepts for feedback.

Tip: Provide paper tablecloths on each table to take notes and make drawings throughout the exercise. Capturing feedback is a crucial part of the innovation process.
    `,
    benefits: [
      "Facilitates an open feedback discussion",
      "Enables the sharing of different viewpoints to understand alternatives and quickly adapt strategies",
      "Links ideas and accesses the collective intelligence in the room",
    ],
    thumbImage: m2WorldCafeImage,
    backImage: m2WorldCafeImage,
  },
];
