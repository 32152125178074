/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";
import * as React from "react";
import { Link, LinkProps, Redirect } from "react-router-dom";
import { useFocusRing } from "@react-aria/focus";

const styles: SxStyleProp = {
  textDecoration: "none",
  border: 0,
  fontFamily: "inherit",
  minHeight: 44,
  py: 3,
  px: 10,
  fontWeight: 500,
  lineHeight: 1,
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: 1,
  borderRadius: "30px",
  bg: "new.primary.purple",
  color: "new.primary.white",
  "@media (hover: hover)": {
    "&:hover": {
      bg: "new.secondary.violet",
    },
  },
  ":active": {
    transform: "translateY(2px)",
    outline: "none",
  },
  ":disabled": {
    pointerEvents: "none",
    cursor: "default",
    bg: "new.secondary.lightGrey",
    color: "new.secondary.grey",
  },
  ":focus:not(.focus-keyboard)": {
    outline: "none",
  },
};

type Props = {};

export const Button = React.forwardRef<
  HTMLButtonElement,
  Props & React.HTMLProps<HTMLButtonElement>
>(({ children, sx = {}, ...rest }, ref) => {
  let { isFocusVisible, focusProps } = useFocusRing();
  return (
    <button
      // @ts-ignore typescript is wrong here
      type="button"
      ref={ref}
      sx={{
        ...styles,
        ...sx,
      }}
      className={isFocusVisible ? "focus-keyboard" : ""}
      {...focusProps}
      {...rest}
    >
      {children}
    </button>
  );
});

export const ButtonLink: React.FC<Props & LinkProps> = ({ children, sx = {}, ...rest }) => {
  let { isFocusVisible, focusProps } = useFocusRing();
  return (
    <Link
      sx={{
        ...styles,
        ...sx,
      }}
      className={isFocusVisible ? "focus-keyboard" : ""}
      {...focusProps}
      {...rest}
    >
      {children}
    </Link>
  );
};

type ProgressProps = { length: number; to: string };

export const ButtonLinkProgress: React.FC<Props & LinkProps & ProgressProps> = ({
  children,
  sx = {},
  length,
  ...rest
}) => {
  const [progress, setProgress] = React.useState<number>();
  const [completed, setCompleted] = React.useState(false);
  const requestRef = React.useRef<number>();
  const startTimeRef = React.useRef<DOMHighResTimeStamp>();

  const animate = (time: DOMHighResTimeStamp) => {
    if (startTimeRef.current === undefined) {
      startTimeRef.current = time;
    }

    const elapsedSec = (time - startTimeRef.current) / 1000;

    if (elapsedSec < length) {
      setProgress(Math.min((elapsedSec / length) * 100, 100));

      // Stop the animation after length seconds
      window.requestAnimationFrame(animate);
    } else {
      setCompleted(true);
    }
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current!);
  }, []); // Make sure the effect runs only once

  if (completed) {
    return <Redirect to={rest.to} />;
  }

  return (
    <ButtonLink
      sx={{
        position: "relative",
        overflow: "hidden",
        ...styles,
        ...sx,
      }}
      {...rest}
    >
      <div
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: `${progress}%`,
          background: "#4B27E0",
          zIndex: 0,
        }}
      ></div>
      {children}
    </ButtonLink>
  );
};

export const ButtonAnchor: React.FC<Props & React.HTMLProps<HTMLAnchorElement>> = ({
  sx = {},
  children,
  ...rest
}) => {
  let { isFocusVisible, focusProps } = useFocusRing();
  return (
    <a
      sx={{
        ...styles,
        ...sx,
      }}
      className={isFocusVisible ? "focus-keyboard" : ""}
      {...focusProps}
      {...rest}
    >
      {children}
    </a>
  );
};
