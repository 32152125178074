/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";

//how to refactor this better?

export const Narrative: React.FC<
  {
    sx?: SxStyleProp;
    olistType?: string;
    markerColor?: string;
  } & React.HTMLProps<HTMLDivElement>
> = ({ sx = {}, olistType = "lower-alpha", markerColor = "auto", ...rest }) => {
  return (
    <Box
      sx={{
        fontSize: 1,
        "h1, h2, h3, h4, h5, h6, p": {
          margin: 0,
        },
        "h1, h2, h3, h4": {
          mt: "80px",
        },
        p: {
          mt: "32px",
          fontSize: 0,
          maxWidth: "65ch",
          lineHeight: 1.4,
        },
        h2: {
          fontSize: 3,
        },
        h3: {
          fontSize: 2,
        },
        h4: {
          fontSize: 1,
        },
        h5: {
          mt: "48px",
          mb: "32px",
          fontSize: 0,
        },
        ul: {
          margin: 0,
          pl: "7px",
          listStylePosition: "outside",
        },
        // specific padding for Safari since custom list item markers are not yet supported 👇
        "@media not all and (min-resolution:.001dpcm)": {
          "@supports (-webkit-appearance:none)": {
            ul: { pl: "18px" },
          },
        },
        "li::marker": {
          color: markerColor,
        },
        "ul li::marker": {
          content: '">"',
          fontWeight: "bold",
        },
        "ul li > p": { display: "inline" },
        "ul li ul": { ml: ["16px", "32px"] },
        ol: {
          margin: 0,
          ml: "32px",
          padding: 0,
          listStyleType: olistType,
        },
        "ol li::marker": {
          fontWeight: "bold",
        },
        li: {
          mt: "32px",
          pl: "16px",
          fontSize: 0,
          lineHeight: 1.2,
          maxWidth: "65ch",
        },
        "li > a": {
          display: "inline",
        },
        a: {
          color: "brand.blue",
          textDecoration: "none",
        },
        "a:hover, a:focus-visible": {
          color: "brand.blueLight",
        },
        "p em": {
          fontStyle: "italic",
        },
        img: {
          maxWidth: "100%",
          mt: 10,
        },
        blockquote: {
          p: 6,
          m: 6,
          bg: "new.secondary.lightGrey",
        },
        video: {
          maxWidth: "100%",
        },
        "audio, video": {
          my: 6,
        },
        hr: {
          my: 14,
        },
        ".visually-hidden": {
          clip: "rect(0 0 0 0)",
          clipPath: "inset(50%)",
          height: "1px",
          overflow: "hidden",
          position: "absolute",
          whiteSpace: "nowrap",
          width: "1px",
        },
        ...sx,
      }}
      {...rest}
    />
  );
};
