import "es7-object-polyfill";
import ReactGA from "react-ga";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import { theme } from "@sparkademy/app-common/materials/theme";
import { ScrollToTop } from "@sparkademy/app-common/utils/scrollToTop";
import { ContentContextProvider } from "@sparkademy/app-common/contexts/content-context";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";

declare global {
  interface Window {
    E2E_TESTING: boolean;
    CURRENT_VERSION: string;
    Intercom: (op: string, attrs?: object) => void | undefined;
  }
}

const searchParams = window.location.search;
window.E2E_TESTING = searchParams.includes("e2eTest");
window.CURRENT_VERSION = "";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://23c66018082148a58793f56ab37f4d38@o380754.ingest.sentry.io/5252559",
    ignoreErrors: [
      "Non-Error exception captured",
      "Non-Error promise rejection captured",
    ],
  });
  if (!window.E2E_TESTING) {
    LogRocket.init("ck1rz5/sparkademy-frontend");
    ReactGA.initialize("UA-159143633-2");
  }
}

const tree = (
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <ContentContextProvider>
          <Route path="/" render={({ location }) => <Route component={App} />} />
        </ContentContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
ReactDOM.render(tree, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
