// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

import innovationMetricsFrontImage from "../method-kit/aif/front/innovation-metrics.svg";
import riskEvaluationFrontImage from "../method-kit/aif/front/risk-evaluation.svg";
import riskIdentificationFrontImage from "../method-kit/aif/front/risk-identification.svg";
import actionPlanFrontImage from "../method-kit/aif/front/action-plan.svg";
import presentANewBusinessIdeaFrontImage from "../method-kit/aif/front/present-a-new-business-idea.svg";
import presentANewProjectIdeaFrontImage from "../method-kit/aif/front/present-a-new-project-idea.svg";
import pitchingFrontImage from "../method-kit/aif/front/pitching.svg";
import storytellingFrontImage from "../method-kit/aif/front/storytelling.svg";
import riskHandlingFrontImage from "../method-kit/aif/front/risk-handling.svg";
import projectScoringFrontImage from "../method-kit/aif/front/project-scoring.svg";
import costEstimationBasicsFrontImage from "../method-kit/aif/front/cost-estimation-basics.svg";

import innovationMetricsBackImage from "../method-kit/aif/back/innovation-metrics.svg";
import riskIdentification1BackImage from "../method-kit/aif/back/risk-identification-1.svg";
import actionPlanBackImage from "../method-kit/aif/back/action-plan.svg";
import presentANewBusinessIdeaBackImage from "../method-kit/aif/back/present-a-new-business-idea.svg";
import presentANewProjectIdeaBackImage from "../method-kit/aif/back/present-a-new-project-idea.svg";
import pitchingBackImage from "../method-kit/aif/back/pitching.svg";
import storytellingBackImage from "../method-kit/aif/back/storytelling.svg";
import riskHandlingBackImage from "../method-kit/aif/back/risk-handling.svg";
import projectScoringBackImage from "../method-kit/aif/back/project-scoring.svg";
import tangibleIntangible from "../method-kit/aif/front/Tangible-Intangible.svg";
import peopleReources from "../method-kit/aif/front/estimating-people-resources.svg";
import actionableMetrics from "../method-kit/aif/front/actionable-metrics.svg";
import costEstimationBasics1BackImage from "../method-kit/aif/back/cost-estimation-basics-1.svg";
// eslint-disable-next-line
import riskEvaluationBackImage from "../method-kit/aif/back/risk-evaluation.svg";
// eslint-disable-next-line
import riskIdentification2BackImage from "../method-kit/aif/back/risk-identification-2.svg";


// @ts-ignore
import innovationMetricsTemplate from "../method-kit/aif/innovation-metrics.pdf";
// @ts-ignore
import riskEvaluationTemplate from "../method-kit/aif/risk-evaluation.pdf";
// @ts-ignore
import projectScoringTemplate from "../method-kit/aif/project-scoring.pdf";
// @ts-ignore
import presentANewProjectIdeaTemplate from "../method-kit/aif/present-a-new-project-idea.pdf";
// @ts-ignore
import pitchingTemplate from "../method-kit/aif/pitching.pdf";
// @ts-ignore
import storytellingTemplate from "../method-kit/aif/storytelling.pdf";
// @ts-ignore
import riskHandlingTemplate from "../method-kit/aif/risk-handling.pdf";
// @ts-ignore
import presentANewBusinessIdeaTemplate from "../method-kit/aif/present-a-new-business-idea.pdf";
// @ts-ignore
import actionPlanTemplate from "../method-kit/aif/action-plan.pdf";
// @ts-ignore
import designActionableMetric from "../method-kit/aif/designing-actionable-metrics.pdf";
// @ts-ignore
import costEstimationTemplate from "../method-kit/aif/cost-estimation-template.xlsx";

export const tools: Tool[] = [
  {
    moduleId: "a_innovationfinance",
    name: "Tangible vs. Intangible Value",
    slug: "aif-tangible-vs-intangible-value",
    resources: ["Pen", "Paper/ Table template", "team"],
    content: mdx`
### Why should you use this method?

-  To understand and communicate the tangible and intangible value that your innovation project can generate



### What is tangible value?

Tangible value is something that can be “touched” such as physical assets or money. Tangible value can be quantified and measured with standard financial measurements.

Tangible value plays an important role in financial accounting of all kinds because of its measurability and direct connection to financial gains and losses. Compared to intangible value, tangible value creation is often easier to predict.

In an innovation project, an example of tangible value could be increased revenue from new customers.



### What is intangible value?

Intangible value is the opposite of tangible value – value that cannot be “touched”. It is the positive outcome of a process that cannot be explicitly measured, although there may be indirect measures of intangible value.

While its difficulty to measure may be discouraging to some, intangible value has become increasingly important in the economy in recent decades. Intangible value may often lead to tangible or specifically monetary value later on. For instance, the intangible value of positive brand perception could lead to the tangible value of increased revenue.

In an innovation project, an example of intangible value could be detailed customer personas that can be used in future projects.



### Example: Tesla’s value creation

#### Tangible Value

* Brick & mortar stores
* Number of Cars
* Revenue from car sales
* Manufacturing facilities


#### Intangible Value

* Battery patents
* Driving data
* Customer loyalty



### Distinguishing Tangible vs. Intangible Value

* Can this value be easily counted?
* Would this value show up on a traditional accounting spreadsheet?
* Is this value directly related to financial gain or a step removed?

    `,
    benefits: [],
    thumbImage: tangibleIntangible,
    backImage: tangibleIntangible,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Project Scoring",
    slug: "aif-project-scoring",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

- To have a convincing argument about your innovation challenge to the management
- To measure and compare the value of projects

### What is Project Scoring?

The project scoring tool is an example of how innovation projects can be captured and measured on both the short-term output, the deliverables, as well as the project’s long-term business value.

This tool can be used to sell the project internally to the management because it captures the two essential elements – strategic value and financial value. Actual numbers or supporting arguments for each of the categories can help during a pitch meeting.

### How can you apply this method?

##### 1. Download the template

##### 2. Fill in the Project deliverables

Fill in the project deliverables with the required time, scope, and cost of the project.

##### 3. Fill in the Strategic values

Fill in the strategic business values for the project like the competitive position, new business opportunities, and image and reputation.

##### 4. Fill in the Financial values

Fill in the financial business values for the project like ROI, benefit/cost ratio and payback period, or any other relevant values.

##### 5. Score each value and deliverable

Provide a score for each of the fields to indicate the importance of the metric.

##### 6. Evaluate the points

Use the metrics to frame the argument to pitch to the management.
    `,
    benefits: [],
    thumbImage: projectScoringFrontImage,
    backImage: projectScoringBackImage,
    template: projectScoringTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Estimating People & Resources",
    slug: "aif-estimating-people-resources",
    resources: ["Pen", "Paper/ Table template", "team"],
    content: mdx`
### Why should you use this method?

* The biggest investment in any project is people.
* Budgets are incomplete without considering people and timeline
* There’s an opportunity cost for involving people in your project



### What should you consider in estimating the cost of human resources?

People are expensive.

Allocating a budget to an innovation project is always a tough task for the management board. They allocate for the project but tend to forget the cost of the team involved. Let us look at these costs and compare.

If a team of 4 people spends 50% of their time for 5 months working on a project, that’s 4 * 5 * 50%. = 10 person months of effort. If those happen to be 4 engineers in Silicon Valley, that might be a typical salary of $25,000 USD per month, so the project is actually costing $250,000 in person costs.


### Have a Deadline

Giving a deadline is as important. If the team is given $100,000 and 4 team members, but no deadline, they could spend that $100,000 over the course of a year. No one is checking on them, so what’s the rush? Let us look at the same situation as above without a deadline.

The team might spend 3 months just interviewing and talking to the customers for free. For initial testing, they might make a landing page which might cost them $3.99 for the domain name and a few hours of labor to figure out how to use a web template (Eg: Wix, WordPress etc.). The actual money required is very small if you don’t outsource everything for the project. Now the main cost is the investment cost of the team that has been working for 12 months instead of 5 months. The cost has ballooned to $625,000 instead of $250,000, which is 2.5 times of the initial in person costs. This can be easily avoided if a deadline is clearly given by the management.


### Make Space for Time

In order to allocate people to an innovation project, it is necessary to ease the team’s workload. If they are 150% booked, then adding an innovation project may not be realistic and ideal. The innovation board may think they are investing 50% of a team of 4, but in reality, they are only investing 5% of everyone’s time. They wanted to invest $350,000 but wound up investing only $101,250.

Here, the management included the team's time in their budget calculations but did not consider whether the team has the time to invest in the innovation project. This is an assumption made by the management.



### How to apply this method

How do you calculate human resources for your project?

#### 1. Start with the core team

List the main team members (including yourself) who will be involved in the entirety of the innovation project. Include the percentage of their time they will commit to the project. Multiply this percentage by their salary to get their total cost.


#### 2. Consider secondary team members

Who will you involve throughout the project to move it forward? This could include subject matter experts who come back during each phase to give feedback. Or this could be a visual designer who will help you create prototypes and presentations. Estimate how many weeks you will involve these people and the hours per week of work. Do research on what their hourly rate is to get a cost estimate.


#### 3. Include the less frequent people or services you will use

These people are likely involved intensely during one phase of the project. For instance, you may involve coders to build a prototype, or you may involve the marketing team to create an online advertising campaign. To estimate their cost, we recommend reaching out to your manager or accounting department.


#### 4. Add the costs together and include them in your budget

Including all the potential people resource costs will give the most accurate estimate for your budget. It will also help you understand if there are ways you can reduce costs by involving fewer people. Is it feasible for you and your core team members to create a marketing campaign yourselves? Or will you need to involve more services because your core team cannot dedicate 100% of their time?kk
    `,
    benefits: [],
    thumbImage: peopleReources,
    backImage: peopleReources,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Cost Estimation Basics",
    slug: "aif-cost-estimation-basics",
    resources: ["Your team", "Data from previous project(s)"],
    content: mdx`
### Why should you use this method?

- Estimate the costs of an innovation project
- To plan the expenditures with little error
- To formulate a rough budget for an innovation project

### What are the different ways to perform a basic cost estimation?

**Analogy method:** Take one single terminated project and compare this single terminated project with yours. You can adapt that old project to yours to make the estimation more precise, to make a one-on-one comparison.

The analogy method is helpful to compare work packages, but less for large projects, as innovation projects usually differ a lot.

**Relation method:** Use a structured comparison with already terminated projects. You look at five, ten, 15, or how many projects you have and compare them. This allows you to look at, for example, how much time you spend on experimentation. The outcome is either an average value or a range of how much time/money was spent on past projects. Repeat the same with other activities you go through in your project. The benefit of this method is that you compare many projects to come to a more accurate estimate.

**Multiplication method:** This method uses its expenditure per unit to develop total expenditures based on old projects. The unit could be anything. For example, if you build a building, it's the cost per square meter. But it could also be something like cost per user interview, cost per prototype, or cost per testing day. So additionally to the time spend on each unit, by comparison with the old project, you determine what costs are connected to it.

**Parametric estimation:** Similar to the multiplication method, but parameters are used instead. The parameters are specific to the project and are used to relate the costs or other characteristics of two or more projects. E.g., it could be the size of the project that delivers the parameter. If the new project is twice the size of that last project (e.g., by being a two-month instead of a one-month project), we must add a factor to the original cost. Be aware that this factor might not be proportional and is developed by comparing many previous projects!

<img src={costEstimationBasics1BackImage} alt="Image" />

### How do you develop a budget estimate for your project?

There are two possible ways for project estimation that work for both classical and agile innovation projects: The bottom-up approach or the top-down approach. Usually, the bottom-up approach creates a much better estimate, which is why we recommend you using it preferably.

Also, consider the development of your estimate over time! The budget you estimate before starting the project will be much less accurate than in the middle of the project. Therefore, you should revise your estimates regularly.

##### 1. Calculate human resource cost

Add up the human resource costs of developer, engineer, product manager, etc. Consider the cost based per hour.

##### 2. Calculate material costs

The material costs include all prototyping costs, software, etc. Consider the cost based per unit.

##### 3. Calculate cost of acquired services

Add the costs for acquired services, such as consultancies, legal fees, etc.

##### 4. Calculate indirect costs

Add the overhead costs to the direct costs mentioned from Steps 1 to 3. These are the costs related to infrastructure, management, office equipment, etc.

##### 5. Profit/ Risk margin

Add a profit margin and a risk margin. Usually, this is around 30 to 50 percent on top of the direct and indirect costs.
    `,
    benefits: [],
    thumbImage: costEstimationBasicsFrontImage,
    backImage: costEstimationBasics1BackImage,
    template: costEstimationTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Designing Actionable Metrics",
    slug: "aif-designing-actionable-metrics",
    resources: ["Pen", "Paper/ Table template", "team"],
    content: mdx`
### Why should you use this method?

* Traditional financial metrics don’t give the full picture of an innovation project
* Finding a balance of different kinds of metrics will help you monitor and evaluate the success of your project
* The most valuable metrics help you make decisions



### Lagging vs. Leading Metrics

A simple way to differentiate leading metrics from lagging metrics is to think of your project as a car. When looking out the windshield, you are looking at what's ahead of you—those are leading metrics.

Conversely, looking out the rearview mirror, back at what has already happened, describes lagging metrics.

Typical financial metrics like ROI or speed to market fall into the category of lagging metrics. Thus, leading metrics may be harder to define, but they can provide invaluable insights into the direction of your innovation project.

<b>Leading metrics:</b> These metrics are indicators that value will be created in the future. They can help foretell success or failure.

<b>Lagging metrics:</b> Traditional metrics that are used to evaluate the success of a project after it has been completed.

Both lagging and leading metrics play essential roles in monitoring and evaluating your innovation project, so it is important to find a balance between both.

In coming up with metrics for your innovation project, focus first on leading metrics, because these are the ones you will monitor on an ongoing basis.

Examples of leading metrics include:
* Experiment pipeline
* Stakeholder conversations
* Interested customer sign-ups

Next, think about the lagging metrics you would like to use to evaluate the success of your project at the end or at predetermined checkpoints.

Examples of lagging metrics include:
* Time to launch
* Revenue
* New Customers acquired


### Avoid Vanity Metrics

A vanity metric might show you some impressive numbers, but they don’t help you to validate or invalidate your hypothesis or they are not telling you how to improve the product or service you designed. Some examples of vanity metrics are:

Number of hits on a webpage.
Number of downloads of an app.

Now imagine you want to use those numbers to decide whether your product is successful or how you can improve it? You don’t know how many different people came to your website. How much time did they spend on it? Where did the hits and downloads come from, which of your internal initiatives is driving them? What did it cost you to generate those hits? You see that such a vanity metric does not help you understand what actions that you took in the past influenced those numbers, and neither gives you a guideline which actions to take next.


### Aim for Actionable Metrics

Aiming for actionable metrics instead means to collect truly useful innovation. Often actionable metrics are formulated through linking them to users or to specific, repeatable tasks or elements of your product. This helps you to use those metrics for decision making and tells you how you might improve those tasks or features.

A/B tests are for example great actionable metrics. You can use them to contrast between different versions of your offering or decide whether a planned change is likely to be more successful than the current product. You can directly link an element of your product to a customer response – might it be higher engagement or higher sales.

Good actionable metrics like this help you to make a decision about future actions and helps you understand which part of your product to improve (instead).


### How to design actionable and leading metrics?

#### 1. Define the goals you would like to achieve

This is the first step you completed in defining the goals of your innovation project. You can also think about setting goals for each phase of your innovation project such as validating a customer segment.


#### 2. Find measures for your goals and desired outcomes

Once you have defined what you want to achieve, brainstorm several observable measures connected to your goals. Focus on what outcomes you would like to see. For example, increased revenue or customer retention.


#### 3. Identify value-creating activities and conditions

Figure out which activities you need to perform or conditions you need to achieve your goals or results. Consider questions such as: What do I need to do to achieve my goals and results? What are the key activities that will drive success? What working conditions need to be in place?


#### 4. Define actionable metrics

This is the step where you will define actionable metrics by identifying how you might measure your value-creators. These are the most actionable if they measure the value-creating activities you defined.
    `,
    benefits: [],
    thumbImage: actionableMetrics,
    backImage: actionableMetrics,
    template: designActionableMetric,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Innovation Metrics",
    slug: "aif-innovation-metrics",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

To be able to measure and compare the success of innovation projects by applying 12 selected internal, external, and people metrics.

### What are Innovation Metrics?

Key performance indicators are targeted towards various stakeholders and are linked to strategic objectives and goals.  They indicate whether or not innovation success is possible in the long term.

- **Key:** A major contributor to success or failure
- **Performance:** Measurable, quantifiable, adjustable, and controllable elements
- **Indicator:** Reasonable representation of present and future performance

Relevant attributes when identifying and selecting the KPIs:

- Predictive
- Measurable
- Actionable
- Relevant
- Automated
- Few in number

Innovation metrics are a set of tools and techniques that fulfill the requirements of KPIs and measure the innovation efforts of a company or organization. Below, we suggest a few values and their measurement.

**External metrics:**

**1. Impact on brand:** acquisition rate of new customer per year

**2. Impact on image:** brand image surveys, customer feedback, or analysts' rankings

**3. Impact on eco-system:** number of external partnerships with universities, suppliers, complementors, and customers.

**Internal metrics:**

**4. Increase of investment opportunities:** Number of new innovation projects management can choose from

**5. Increased value of investment portfolio:** Financial valuation, revenue attributable to innovation

**6. Increased value of capital invested:** Net portfolio valuation over capital invested

**7. Organizational learning curve:** % of high-quality projects compared to previous periods

**8. Non- incremental projects:** Number of disruptive or breakthrough projects and new business model innovations

**9. Non-incremental innovations to market:** Number of non-incremental projects delivered as innovations to the market

**People metrics — impact on culture**

**10.  Speed of innovation:** Time to innovation project completion compared to previous periods

**11.  Involvement:** Number of people participating in innovation projects

**12.   Increase Quality:** Quality of contribution by each employee compared to previous periods

### How can you apply this method?

##### 0. Before you start

Have the table open that is listed as template

##### 1. Set Goals

Invite your team members and set project goals based on the innovation metrics mentioned in the table

##### 2. State current metrics

State the current status of the project based on the innovation metrics mentioned in the table

##### 3. Compare the metrics

Now compare the two numbers and figure out a strategy to reach the desired numbers set by your team
    `,
    benefits: [],
    thumbImage: innovationMetricsFrontImage,
    backImage: innovationMetricsBackImage,
    template: innovationMetricsTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Risk Identification",
    slug: "aif-risk-identification",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

- Identify possible risks of your project by looking at it from different angles
- Understand which types of projects need strong risk management and which ones don't

### What is your type of innovation project?

- **Radical breakthrough innovation types:** no compared product or service at hand or in the market
- **Next generation of your product:** developing a new generation of the same product, e.g., cars, phones, etc.
- **Addition to the family:** changing features to the same product
- **Add ons and enhancements:** adding extra features to existing products

### What type of risks could you have?

- The market risks: Did you pick the right market? Do you know enough about the market? Is the market changing faster than you think? Is the competition already in the market and ramping up?
- Technical risks: Are you using new technology for your innovation project that you have never used in your company so far, or where you as a team don't have enough experience?
- Timing: Is the timing right towards the market and the customer? How much time is your company giving you to work on that specific innovation project?
- Cost: Is the cost of the innovation project worth the effort?
- Price: Is the price of the product competitive in the market?
- Quality: Does the final product reach the desired quality of the product or service?

The following table indicates which types of risks will be likely within your type of innovation project:

<img src={riskIdentification2BackImage} alt="Image" />

### How can you apply this method?

##### 1. Identify the type of innovation project

From the overview above, identify the type of your innovation project.

##### 2. Identify the likelihood of each risk type

Using the table above, identify which risks are likely to be severe for your project.

##### 3. Brainstorm project risks

Go through the different risk types one after another and take a few minutes to note down all risks you can think of. Include everything that comes to your mind; you will eliminate the less relevant ones later during the evaluation process. Spend extra time on the high-risk types, according to the table above.

##### 4. Number the risks

Give different numbers for every risk for easy identification. This will help during the risk evaluation in the next step.

### What’s up next?

[Evaluate your risks](/method-kit/aif-risk-evaluation) and mitigate the relevant ones by either working on the cause or the effect by [risk-handling](/method-kit/aif-risk-handling).
    `,
    benefits: [],
    thumbImage: riskIdentificationFrontImage,
    backImage: riskIdentification1BackImage,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Risk Evaluation",
    slug: "aif-risk-evaluation",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

- Use a risk matrix to judge both the probability and the potential impact of each risk
- Understand which types of projects need strong risk management and which ones don't

### Risk Evaluation

The Risk Matrix is a straightforward tool to evaluate project risks. It is based on a concept by the Zurich Insurance Company, also called the Zurich Hazard Analysis.

<img src={riskEvaluationBackImage} alt="Image" />

### How can you apply this method?

##### 1. Identify the type of innovation project

Use the method card in [risk identification](/method-kit/aif-risk-identification) to identify the type of innovation project

##### 2. Identify the risks

Use the method card in [risk identification](/method-kit/aif-risk-identification) to identify the risks.
List own all the possible risks and give different numbers for every risk for easy identification. Focus on the areas with high risks.

##### 3. Rate every risk

Now rate every possible risk on the matrix such that it becomes a point in the matrix. Note the point as a number to correspond to the risk identified

##### 4. Protection target

Set a protection target- a line going through the matrix (vertical, diagonal, or horizontal) to identify which risks to handle

##### 5. Priority risk handling

Any risk above the protection target line is to be dealt with on a priority basis

##### 6. Secondary risk handling

Any risk below the protection target line can be neglected for the time being

### Example

Imagine you are working at SpaceX and building a rocket that is supposed to land vertically. One of the risks is that the rocket might explode due to the blowback of the landing. Unfortunately, and as you know from the news, this has been happening every time one of those rockets by SpaceX tries to land. So the probability is relatively high, at least at that point of the innovation process. And the impact is catastrophic because the rocket is destroyed. So are you willing to take a risk on the technology side, which is, if it occurs, catastrophic? It seems that at least from statements by Elon Musk that they accepted this risk and set the protection target above. This catastrophic impact is something that they can live with, as it gives valuable information to the company.

### What’s up next?

Mitigate the risks by either working on the cause or the effect by [risk-handling](/method-kit/aif-risk-handling).
    `,
    benefits: [],
    thumbImage: riskEvaluationFrontImage,
    template: riskEvaluationTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Risk Handling",
    slug: "aif-risk-handling",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

- To mitigate the risks by either working on the cause or on the effect.
- Get risks into the accepted area that you have predefined during risk evaluation.

### How to handle risks?

**Elimination:** The avoidance of the risk. Find a way that this risk is not going to happen, for example, by changing the technology, or going to a different market in which you have more experience.

**Reducing the impact:** Can be done in two ways. You can reduce on the cause side and you can reduce on the effect side.

- **Example:** Imagine you have a logistics company and there are open barrels with gasoline being stored in there. Now, next to those barrels someone is smoking, and the person throws the cigaret into that barrel which then explodes.
  - Work on the cause side: Ask the question, why do we have open barrels with gasoline at our premises at all? You could close the barrels. You could work on the trigger, so smoking is not allowed anymore.
  - Work on the event side: If the barrels explode or starts burning, what can you do to reduce the consequences? Have fire extinguishers on site? Construct a separate building where all those barrels are handled?

**Transfer the risk:** Usually done on the effects side by having an insurance for a certain event happening. This way, you are transferring your risk to an insurance company (normally at a cost).

**Remaining risk:** In the risk management process, there is always some risk at the end that you simply might have to live with.

### How can you apply this method?

##### 1. Download the template

##### 2. Identify the risk to handle

State and explain the risk that you are trying to handle (Eg: market, technical, timing, price, cost, price or quality)

##### 3. Avoid the risk

Identify a way to avoid the risk stated in point 1

##### 4. Reduce the risk

Identify a way to reduce the risk stated in point 1

##### 5. Transfer the risk

Identify a way to transfer the risk stated in point 1

##### 6. Remaining risk

State the risk left after going through point 2 to 4

##### 7. Repeat process

Repeat the process from step 1 to 5 for each of the critical risks identified
    `,
    benefits: [],
    thumbImage: riskHandlingFrontImage,
    backImage: riskHandlingBackImage,
    template: riskHandlingTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Storytelling",
    slug: "aif-storytelling",
    resources: ["Printed template or online collaboration tool", "sticky notes", "markers"],
    content: mdx`
### Why should you use this method?

- Convey complex ideas into simple ones
- Sell a project more effectively
- Establish an emotional connection with your audience and let them relate to your message in a more meaningful way

### What is a good story?

A good story should touch your audience, be strong and leading, and be forward-oriented. The elements of a good story are:

- Add a character of the story and their characteristics (Persona)
- Talk about the obstacle or challenge your character is facing.
- Redraw the connection to your audience
- Offer a solution.
- Concentrate on the WHY instead of HOW.

The storyline template gives you a clear structure and helps you to develop a compelling story step by step.

### How can you apply this method?

##### 1. Invite your team

Invite your team members for a short activity to prepare the storyline using the template.

##### 2. Fill out the preparation

State the subject of the project, background information on the audience, and the goal that you want to achieve with this storyline.

##### 3. Set the scene

Here you mention your main character, their characteristics, beliefs, behavior, thoughts, emotions, and goals they are trying to achieve.

##### 4. Encounter the obstacle

Here you introduce the main character's challenge, support it with some proof, and introduce a common ground with the audience.

##### 5. The success

This section states the solution, connects it to the target audience, and shares the solution's value for both your main character and your audience.

##### 6. Note down the conclusion

Summarize your story with an AHA moment, a key sentence for your audience to remember. Then, share your call to action. What do you ultimately want from your listeners?

### What's up next?

Turn your story into a [quick pitch](/method-kit/aif-pitching) or make a [whole presentation](/method-kit/aif-present-new-business-idea) out of it. You can also write an [action plan](/method-kit/aif-action-plan) to put your success story into practice!
    `,
    benefits: [],
    thumbImage: storytellingFrontImage,
    backImage: storytellingBackImage,
    template: storytellingTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Pitching",
    slug: "aif-pitching",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

- Get your listeners attention and then
- Be invited to have a more detailed presentation and conversation

### What is an elevator pitch?

An elevator pitch is a 30s-to-one-minute short presentation of your project, and uses a story to answer the “what, how and why”-formula, it's concise and short. The name comes from the idea of pitching your project to a boss or someone hard to reach during an elevator ride. However, a short and concise pitch can be a great door opener in a lot of situations!

**Tips and Tricks:**

  - Speak slowly (average of 75 words in 30s). This demonstrates confidence and competence
  - Use a template (find above) to formulate your thoughts
  - Be ready with your pitch, you never know when an opportunity presents itself
  - Remove confusing or unfamiliar industry jargon
  - Don’t add fillers and annoy the listener
  - To improve your pitch, you can work on it together with the [storytelling](/method-kit/aif-storytelling) template.

### What’s up next?

If you were successful and are invited to present your idea in a bigger setting, have a look on the method cards of [presenting a project idea](/method-kit/aif-present-new-project-idea) and [presenting a business idea](/method-kit/aif-present-new-business-idea).

Or maybe you want to prepare a pitch about you and your work to introduce yourself in the future?
    `,
    benefits: [],
    thumbImage: pitchingFrontImage,
    backImage: pitchingBackImage,
    template: pitchingTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Present a new project idea",
    slug: "aif-present-new-project-idea",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

- Present your idea effectively to the customer/ client or management

### What is an effective way to present a new project idea?

Although the tips and tricks are similar for any presentation, the structure stated below primarily refers to presenting a new project idea. In this case, the solution you offer is not a ready product or and you don’t have a finished business model. Instead, your solution is a project plan: what will you do within the project, and why is this valuable? What will the outcomes be?

If you are already further into your innovation project and you want to present an actual product, service, or business model, go to the method card [Present a New Business Idea](/method-kit/aif-present-new-business-idea).

**Tips and tricks:**

##### 1. Keep it short – max. 10 slides

**Part 1:** Set the stage and explain why your idea should be considered
  - Why this presentation
  - A summary statement of the idea
  - A Problem, need, or challenge to be solved, validated by some data
  - The solution you offer
  - The value this solution creates for the user and the company

**Part 2:** Showcase why your idea will succeed
  - What makes your solution better than others?
  - Present the team working on the project and why they are the best possible team
  - Key metrics and financial projections.
  - Key unknowns and risks and how will you handle them?
  - Ask/ Call to action towards your audience.

##### 2. Make it personal and as a story. Refer to [storytelling](/method-kit/aif-storytelling) method card.

##### 3. Use authentic images to tell the story and not have more than 20 words per slide

##### 4. Keep more than half the time for feedback and discussions

### How can you apply this method?

##### 1. Invite your team

Invite your team members for a short activity to prepare the presentation. Ideally, start with a storyboard using the template provided and use that as a guideline for the final presentation.

##### 2. Prepare your story

Develop an engaging storyline that touches your audience. If you haven’t developed one yet, refer to the method card “Storytelling”.

##### 3. Part 1

Fill the slides in the template or draw the slides on paper to make an effective storyboard for the presentation. Use words and use placeholders for images. Part 1 sets the tone for the presentation and covers summary, problem statement, solution, and value proposition.

##### 4. Part 2

This part of the presentation needs to cover all the metrics, data, and analysis to convince the client/ management about your project.

##### 5. Feedback and discussion

Set sufficient time to have a discussion and feedback on the presentation and idea. This is where the maximum learning is going to take place.

**Reference:**

- [Action Plan](/method-kit/aif-action-plan) method card
- [Storytelling](/method-kit/aif-storytelling) method card
- [Elevator pitch](/method-kit/aif-pitching) method card
    `,
    benefits: [],
    thumbImage: presentANewProjectIdeaFrontImage,
    backImage: presentANewProjectIdeaBackImage,
    template: presentANewProjectIdeaTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Present a new business idea",
    slug: "aif-present-new-business-idea",
    resources: ["Pen, Paper/ Table template"],
    content: mdx`
### Why should you use this method?

- Present your business idea effectively to the customer/ client or management

### What is an effective way to present a new business idea?

The structure below is a general structure for presenting any (business) idea. If you don’t have a solution yet, but want to pitch a general project idea, have a look at the method card [Present a new project idea](/method-kit/aif-present-new-project-idea).

**Tips and tricks:**

##### 1.   Keep it short – max. 10-12 slides

**Part 1:** Set the stage and explain why your idea should be considered

- A summary statement of the idea
- A Problem, need, or challenge to be solved, validated by some data
- The solution you offer
- The value this solution creates for the user and the company
- Business model and how you intend to make money

**Part 2:** Showcase why your idea will succeed

- What makes your solution better than others?
- Present the team working on the project and why they are the best possible team
- Give a short competitive analysis
- Key metrics and financial projections
- Key unknowns and risks and how will you handle them?
- The current status and the action plan
- Ask/ Call to action towards your audience

##### 2. Make it personal and as a story. Refer to storytelling method card

##### 3. Use authentic images to tell the story and not have more than 20 words per slide

##### 4. Keep more than half the time for feedback and discussions

### How can you apply this method?

##### 1. Invite your team

Invite your team members for a short activity to prepare the presentation. Ideally, start with a storyboard using the template provided and use that as a guideline for the final presentation.

##### 2. Part 1

Fill the slides in the template or draw the slides on paper to make an effective storyboard for the presentation. Use words and use placeholders for images. Part 1, sets the tone for the presentation and covers summary, problem statement, solution, and value proposition.

##### 3. Part 2

This part of the presentation needs to cover all the metrics, data, and analysis to convince the client/ management about your project.

##### 4. Feedback and discussion

Set sufficient time to have a discussion and feedback on the presentation and idea. This is where the maximum learning is going to take place.

**Reference:**

- [Action Plan](/method-kit/aif-action-plan) method card
- [Storytelling](/method-kit/aif-storytelling) method card
- [Elevator Pitch](/method-kit/aif-pitching) method card
    `,
    benefits: [],
    thumbImage: presentANewBusinessIdeaFrontImage,
    backImage: presentANewBusinessIdeaBackImage,
    template: presentANewBusinessIdeaTemplate,
  },
  {
    moduleId: "a_innovationfinance",
    name: "Action Plan",
    slug: "aif-action-plan",
    resources: ["Pen", "Paper/ Table template", "team"],
    content: mdx`
### Why should you use this method?

- Helps with teamwork planning
- Gives a clear direction
- Prepare and track a strategy to achieve own personal goals
- Helps with convincing the management for the project
- Prioritize tasks

### What is an action plan?

An action plan is a checklist for the steps or tasks you need to complete in order to achieve the goals you have set. 

**It covers the four main questions primarily:**

- Who
- Does what
- What resources are necessary
- Until when

Download the template and get started!

### How can you apply this method?

##### 1. Invite your team

Invite your team members for a short activity to prepare the action plan. It is important to involve the whole team in this process.

##### 2. State project goal

For the team's interest, state the project goal for the action plan, where you are, and where you want to be.

##### 3. Define tasks and set milestones

List out the different tasks and milestones for the project. These will be considered as action steps. Organize the tasks based on what has the highest priority and should start the earliest.

##### 4. Define “Who”

For each action plan, list out the team/person responsible for carrying out the task.

##### 5. List resources needed

For each action step, list the necessary resources needed to complete the task and a plan to acquire these resources. (includes the budget)

##### 6. Set deadlines

It is important to set a realistic deadline. The dates should be consulted with the person in charge of the task

##### 7. Monitor, Evaluate, and Update

Think about how to evaluate the task's progress and how to update the action plan continuously. This can be done by defining metrics for evidence that shows progress and improvement.
    `,
    benefits: [],
    thumbImage: actionPlanFrontImage,
    backImage: actionPlanBackImage,
    template: actionPlanTemplate,
  },
];
