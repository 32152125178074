import { Module } from "./module";

export type LevelStatus = "open" | "closed" | "completed" | "failed" | "locked";
type FailureType = "no-program" | "no-certification";

type TrackStatus = "open" | "closed" | "completed";

type TrackInput = {
  slug: string;
  name: string;
  modules: Module[];
  status: TrackStatus;
};

export class Track {
  readonly slug: string;
  readonly name: string;
  readonly modules: Module[];
  readonly status: TrackStatus;

  constructor(input: TrackInput) {
    this.slug = input.slug;
    this.name = input.name;
    this.modules = input.modules;
    this.status = input.status;
  }
}

type LevelInput = {
  id: string;
  modules: Module[];
  activeTrackId?: string;
  tracks?: TrackInput[];
  failureType?: FailureType;
  status?: LevelStatus;
  feedbackUrl?: string;
  project?: ProjectInput;
};

export class LevelInfo {
  readonly id: string;
  readonly name: string;
  readonly description: string;
  readonly commitment: string;

  constructor(id: string, name: string, description: string, commitment: string) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.commitment = commitment;
  }
}

export class Level {
  readonly id: string;
  readonly status?: LevelStatus;
  readonly modules: Module[];
  readonly tracks?: Track[];
  readonly myTrack?: Track;
  readonly feedbackUrl: string;
  readonly isActive: boolean = false;
  readonly isCompleted: boolean = false;
  readonly isFailed: boolean = false;
  readonly failureType: FailureType = "no-program";
  readonly project?: Project;

  constructor(input: LevelInput) {
    this.id = input.id;
    this.modules = input.modules;
    this.feedbackUrl = input.feedbackUrl || "https://www.google.com";
    this.failureType = input.failureType || "no-program";
    this.status = input.status || "open";
    this.tracks = input.tracks ? input.tracks.map(tInput => new Track(tInput)) : [];
    this.myTrack =
      input.tracks && input.tracks.length > 0 && input.activeTrackId
        ? input.tracks.find(t => t.slug === input.activeTrackId)
        : undefined;

    if (input.project) {
      this.project = {
        content: input.project.content,
        duration: input.project.duration,
        status: input.project.status,
        timeCommitment: input.project.time_commitment,
        instructionUrl: input.project.instruction_url,
        moduleId: input.project.module_id,
      };
    }

    this.isActive = this.modules.length === 0 ? false : Boolean(this.modules.find(m => m.isActive));
    this.isCompleted =
      this.modules.length === 0
        ? false
        : Boolean(
            this.modules.filter(m => m.isCompleted).length === this.modules.length &&
              // TODO: only my track is completed
              (this.tracks && this.tracks.length > 0 ? this.tracks[0].status === "completed" : true)
          );
    this.isFailed =
      this.modules.length === 0
        ? false
        : Boolean(this.modules.find(m => m.status === "failed-retries-expired"));
  }
}

export type ProjectInput = {
  content: string;
  duration: string;
  instruction_url: string;
  status: string;
  time_commitment: string;
  module_id: string;
};

export type Project = {
  content: string;
  duration: string;
  instructionUrl: string;
  status: string;
  timeCommitment: string;
  moduleId: string;
};
