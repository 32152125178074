/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { LinkProps } from "react-router-dom";
import { Box } from "theme-ui";
import { ButtonGhostLink, ButtonGhost } from "./ButtonGhost";
import { ReactComponent as KeyboardBackspace } from "@sparkademy/app-common/materials/icons/keyboard_backspace.svg";

type Props = {};

export const ArrowLink: React.FC<Props & LinkProps> = ({ children, sx = {}, ...rest }) => (
  <ButtonGhostLink {...rest} sx={{ px: 0, py: 0, fontSize: 0, minHeight: "initial", ...sx }}>
    <Box
      sx={{
        position: "relative",
        pl: 8,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 24,
          height: 24,
          svg: {
            height: "100%",
            width: "auto",
          },
        }}
      >
        <KeyboardBackspace />
      </Box>
      {children}
    </Box>
  </ButtonGhostLink>
);

export const ArrowLinkButton: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  sx = {},
  ...rest
}) => (
  <ButtonGhost {...rest} sx={{ px: 0, py: 0, fontSize: 0, ...sx }}>
    <Box
      sx={{
        position: "relative",
        pl: 8,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 24,
          height: 24,
          svg: {
            height: "100%",
            width: "auto",
          },
        }}
      >
        <KeyboardBackspace />
      </Box>
      {children}
    </Box>
  </ButtonGhost>
);
