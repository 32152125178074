/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

const styles: SxStyleProp = {
  textDecoration: "none",
  border: 0,
  minHeight: 44,
  py: 3,
  px: 10,
  lineHeight: 1,
  display: "inline-flex",
  fontFamily: "inherit",
  fontWeight: 500,
  alignItems: "center",
  cursor: "pointer",
  fontSize: 0,
  borderRadius: "30px",
  bg: "transparent",
  color: "new.primary.purple",
  "&:hover": {
    color: "new.secondary.violet",
  },
  ":active": {
    transform: "translateY(2px)",
    outline: "none",
  },
  ":disabled": {
    pointerEvents: "none",
    cursor: "default",
    color: "new.secondary.grey",
  },
};

type Props = {
  onDark?: boolean;
};
export const ButtonGhost = React.forwardRef<
  HTMLButtonElement,
  Props & React.HTMLProps<HTMLButtonElement>
>(({ onDark = false, children, sx = {}, ...rest }, ref) => (
  <button
    // @ts-ignore typescript is wrong here
    type="button"
    sx={{
      ...styles,
      ...(onDark ? { color: "new.primary.white" } : {}),
      ...sx,
    }}
    {...rest}
  >
    {children}
  </button>
));

export const ButtonGhostLink: React.FC<Props & LinkProps> = ({
  onDark = false,
  children,
  sx = {},
  ...rest
}) => (
  <Link
    sx={{
      ...styles,
      ...(onDark ? { color: "new.primary.white" } : {}),
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Link>
);

export const ButtonGhostAnchor: React.FC<Props & React.HTMLProps<HTMLAnchorElement>> = ({
  onDark = false,
  children,
  sx = {},
  ...rest
}) => (
  <a
    sx={{
      ...styles,
      ...(onDark ? { color: "new.primary.white" } : {}),
      ...sx,
    }}
    {...rest}
  >
    {children}
  </a>
);
