export const getMethodKitColorForLevel = (levelId: string) => {
  switch (levelId) {
    case "core":
      return "new.lemon";
    case "advanced":
      return "new.secondary.mint";
    default:
      return "primary";
  }
};
