import { Box, Heading, Text, Link } from "theme-ui";

import React from "react";
import jwtDecode from "jwt-decode";
import { Layout } from "../components/Layout";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useHistory, Redirect } from "react-router-dom";
import VisuallyHidden from "@reach/visually-hidden";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationMethodKit } from "@sparkademy/app-common/materials/illustrations/method-kit-signin.svg";
import { ReactComponent as IllustrationEmail2 } from "@sparkademy/app-common/materials/illustrations/email2.svg";
import { Input } from "@sparkademy/app-common/elements/Input";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { validateClientToken, publicMethodKitLogin } from "../services/http-api-service";
import { TrackingService } from "../services/tracking-service";
import isEmail from "@sparkademy/app-common/utils/isEmail";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { PrivacyTermsCheckbox } from "../components/PrivacyTermsCheckbox";
import LogRocket from "logrocket";

enum STATES {
  IDLE = "IDLE",
  MAGIC_LOADING = "MAGIC_LOADING",
  MAGIC_LOADED = "MAGIC_LOADED",
  MAGIC_ERROR = "MAGIC_ERROR",

  FORM_LOADING = "FORM_LOADING",
  FORM_ERROR = "FORM_ERROR",
  FORM_SUCCESS = "FORM_SUCCESS",
}

const MAGIC_LINK_KEY = "token";
export const LOCAL_STORAGE_EMAIL_KEY = "method-kit-email";
export const LOCAL_STORAGE_COMPANY_KEY = "method-kit-company";
export const LOCAL_STORAGE_TOKEN_KEY = "method-kit-token";

export const MethodKitLogin: React.FC = () => {
  const { currentUser } = useSessionContext();

  const currentHistory = useHistory();
  const query = useQuery();
  const [state, setState] = React.useState<STATES>(STATES.IDLE);
  const token = query.get(MAGIC_LINK_KEY);
  const next = query.get("next") !== null ? query.get("next")! : undefined;
  const ref = query.get("ref") || "";
  const companyId = query.get("companyId") || "";

  const [consentGiven, setConsentGiven] = React.useState<boolean>(false);

  // eslint-disable-next-line
  const [error, setError] = React.useState<false | string>(false);

  React.useEffect(() => {
    async function loginAsync() {
      if (currentUser) {
        return;
      }
      if (!token) {
        return;
      }
      if (
        state === STATES.MAGIC_LOADING ||
        state === STATES.MAGIC_LOADED ||
        state === STATES.MAGIC_ERROR
      ) {
        return;
      }

      setState(STATES.MAGIC_LOADING);
      try {
        await validateClientToken(token);
        localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
        localStorage.setItem(LOCAL_STORAGE_COMPANY_KEY, companyId);

        const tokenData = jwtDecode<{ email: string; cohort_id: string }>(token);
        LogRocket.identify(tokenData.email);
        TrackingService.methodKitLoggedIn(tokenData.email);

        setState(STATES.MAGIC_LOADED);
      } catch (ex) {
        console.error(ex);
        TrackingService.methodKitFailedLogIn("NULL", token);
        setState(STATES.MAGIC_ERROR);
      }
    }

    loginAsync();
  }, [currentHistory, currentUser, state, token, companyId]);

  const [email, setEmail] = React.useState("");
  const [isEmailValid, setIsEmailValid] = React.useState(false);

  React.useEffect(() => {
    if (email.trim().length > 0) {
      const isValid = isEmail(email);
      setIsEmailValid(isValid);
    }
  }, [email]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (state === STATES.FORM_LOADING) {
      return;
    }
    setState(STATES.FORM_LOADING);
    try {
      await publicMethodKitLogin(email, ref, companyId, next);
      TrackingService.methodKitRequestToLogIn(email);
      setState(STATES.FORM_SUCCESS);
      localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, email);
    } catch (ex) {
      TrackingService.methodKitFailedLogIn(email);
      setError(ex.message);
      setState(STATES.FORM_ERROR);
    }
  }

  if (currentUser || (token && state === STATES.MAGIC_LOADED)) {
    console.log("First redirect,to: ", next);
    if (next) {
      return <Redirect to={next} />;
    }
    return <Redirect to="/" />;
  }

  if (state === STATES.MAGIC_ERROR) {
    console.log("Second redirect");
    return <Redirect to={"/error"} />;
  }

  if (state === STATES.MAGIC_LOADING || state === STATES.FORM_LOADING) {
    console.log("Third redirect");
    return (
      <Layout sx={{ bg: "new.primary.white" }}>
        <Loader />
      </Layout>
    );
  }

  return (
    <Layout sx={{ bg: "new.primary.white" }}>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 13,
        }}
      >
        {(state === STATES.IDLE || state === STATES.FORM_ERROR) && (
          <Container
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                svg: {
                  width: ["80vw", "100%"],
                  height: "auto",
                },
              }}
            >
              <IllustrationMethodKit />
            </Box>
            <Heading
              as="h1"
              sx={{
                fontSize: 3,
                fontWeight: 700,
                textAlign: "center",
                mt: [30, 45, 45],
                mb: 45,
              }}
            >
              Where teams come to upskill and innovate
            </Heading>

            <Text sx={{ maxWidth: 700, fontSize: 1 }}>
              The Sparkademy Method Kit compiles a collection of the best theoretical frameworks,
              practical tools and hands-on tips. Use Method Kit to support your team through
              relevant project work.
            </Text>

            <Box
              as="form"
              onSubmit={onSubmit}
              sx={{
                width: ["80vw", "100"],
              }}
            >
              <Box
                sx={{
                  mt: 10,
                }}
              >
                <VisuallyHidden>
                  <label htmlFor="email">Email Address</label>
                </VisuallyHidden>
                <Input
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.currentTarget.value)}
                  placeholder="Email Address"
                  sx={{
                    pr: 0,
                    width: ["100%", 500],
                    ":focus": {
                      borderColor:
                        email.trim().length > 0
                          ? isEmailValid
                            ? "new.advancedGreen"
                            : "new.failingRed"
                          : undefined,
                    },
                  }}
                />
              </Box>

              <Box sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
                <PrivacyTermsCheckbox
                  consentGiven={consentGiven}
                  setConsentGiven={setConsentGiven}
                />
              </Box>

              <Button type="submit" disabled={!isEmailValid || !consentGiven} sx={{ mb: 10 }}>
                Continue
              </Button>

              {error && (
                <Text sx={{ color: "new.failingRed", my: 5, maxWidth: 500 }}>
                  An error ocurred. Please try again later.
                </Text>
              )}
            </Box>
          </Container>
        )}

        <Box
          sx={{
            maxWidth: "90ch",
            textAlign: "center",
          }}
        >
          {state === STATES.FORM_SUCCESS ? (
            <Container
              sx={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <IllustrationEmail2 />
              </Box>
              <Heading
                as="h1"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Check your email
              </Heading>
              <Narrative
                sx={{
                  mt: 4,
                }}
              >
                <p>
                  We've sent a confirmation email to you. <br />
                  Click the link, and you’ll be signed in.
                </p>
              </Narrative>
            </Container>
          ) : null}
        </Box>
      </Container>
    </Layout>
  );
};
