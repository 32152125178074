import * as React from "react";
import { Label, Link, Text } from "theme-ui";
import { Checkbox } from "@sparkademy/app-common/elements/Checkbox";

export const PrivacyTermsCheckbox: React.FC<{
  consentGiven: boolean;
  setConsentGiven: (val: boolean) => void;
}> = ({ consentGiven, setConsentGiven }) => {
  const termsLinkStyle = {
    color: "new.primary.purple",
    fontSize: "14px",
  };
  const privacyPolicyLink = (
    <Link
      className="iubenda-nostyle no-brand iubenda-embed"
      href="https://www.iubenda.com/privacy-policy/43449139"
      rel="noopened noreferrer"
      sx={termsLinkStyle}
    >
      privacy policy
    </Link>
  );
  const termsAndConditionsLink = (
    <Link
      className="iubenda-nostyle no-brand iubenda-embed"
      href="https://www.iubenda.com/terms-and-conditions/43449139"
      rel="noopened noreferrer"
      sx={termsLinkStyle}
    >
      terms &amp; conditions
    </Link>
  );

  return (
    <Label
      mb={8}
      sx={{
        fontSize: "14px",
        maxWidth: "65ch",
        lineHeight: "22px",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Checkbox
        id="terms"
        type="checkbox"
        checked={consentGiven}
        onChange={() => setConsentGiven(!consentGiven)}
      />
      <Text sx={{ mt: 0, ml: [6, 4] }}>
        By checking this box, you are agreeing to our {termsAndConditionsLink} and the way we
        collect and use your data as set in our {privacyPolicyLink}.
      </Text>
    </Label>
  );
};
