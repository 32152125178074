type Materials = {
  status: "closed" | "open" | "completed" | "completed-verified";
  start: Date;
  end: Date;
  url?: string;
  deadlineApproaching?: boolean;
};

export type Assessment = {
  status:
    | "upcoming"
    | "closed"
    | "open"
    | "started"
    | "pending-evaluation"
    | "completed"
    | "locked"
    | "overdue";
  start: Date;
  end: Date;
  url: string;
  deadlineApproaching?: boolean;
};

export type CourseEvaluation = {
  status: "open" | "started" | "completed";
  url: string;
};

export type Homework = {
  status:
    | "upcoming"
    | "closed"
    | "open"
    | "started"
    | "pending-evaluation"
    | "completed"
    | "overdue";
  start: Date;
  end: Date;
  url: string;
  deadlineApproaching?: boolean;
};

export type ModuleStatus =
  | "closed"
  | "open"
  | "pending-evaluation"
  | "completed"
  | "failed"
  | "failed-timeout"
  | "failed-retries-expired"
  | "locked";

export class ModuleInfo {
  readonly index: number;
  readonly id: string;
  readonly levelId: string;
  readonly name: string;
  readonly description: string;
  readonly commitment: string;
  readonly level: string;

  constructor(
    index: number,
    id: string,
    levelId: string,
    name: string,
    description: string,
    commitment: string,
    level: string
  ) {
    this.index = index;
    this.id = id;
    this.levelId = levelId;
    this.name = name;
    this.description = description;
    this.commitment = commitment;
    this.level = level;
  }
}

export class Module {
  readonly index: number;
  readonly id: string;
  readonly levelId: string;
  readonly status: ModuleStatus;
  readonly materials: Materials;
  readonly assessment: Assessment;
  readonly homework: Homework;
  readonly feedback: CourseEvaluation;
  readonly workbookUrl: string;
  readonly retry: boolean;

  readonly isActive: boolean = false;
  readonly isCompleted: boolean = false;
  readonly isFailed: boolean = false;
  readonly isViewOnly: boolean = false;
  readonly isProjectWork: boolean = false;
  readonly isStandaloneModule: boolean = false;

  constructor(
    index: number,
    id: string,
    levelId: string,
    workbookUrl: string,
    status: ModuleStatus,
    retry: boolean,
    materials: Materials,
    assessment: Assessment,
    homework: Homework,
    feedback: CourseEvaluation,
    isProjectWork: boolean = false,
    isStandaloneModule: boolean = false
  ) {
    this.index = index;
    this.id = id;
    this.levelId = levelId;
    this.status = status;
    this.materials = materials;
    this.assessment = assessment;
    this.homework = homework;
    this.feedback = feedback;
    this.workbookUrl = workbookUrl;
    this.retry = retry;
    this.isActive =
      this.status === "open" || this.status === "completed" || this.status === "pending-evaluation";
    this.isCompleted = this.status === "completed";
    this.isFailed =
      this.status === "failed" ||
      this.status === "failed-retries-expired" ||
      this.status === "failed-timeout";
    this.isViewOnly = this.assessment.status === "locked";
    this.isProjectWork = isProjectWork;
    this.isStandaloneModule = isStandaloneModule;
  }
}

export function createOpenModule(base: ModuleInfo) {
  return new Module(
    base.index,
    base.id,
    base.levelId,
    "",
    "open",
    false,
    { start: new Date(), status: "open", end: new Date() },
    { start: new Date(), status: "open", end: new Date(), url: "" },
    { start: new Date(), status: "open", end: new Date(), url: "" },
    { url: "", status: "open" }
  );
}

export function createProjectModule(id: string, index: number, open: boolean, levelId: string) {
  // project work is always open
  const aDayInMs = 24 * 60 * 60 * 1000;
  const now = new Date();
  const hwStart = new Date(now.getTime() - aDayInMs);
  const hwEnd = new Date(now.getTime() + aDayInMs);

  return new Module(
    index,
    id,
    levelId,
    "",
    open ? "open" : "closed",
    false,
    { start: new Date(), status: "open", end: new Date() },
    { start: new Date(), status: "open", end: new Date(), url: "" },
    { start: hwStart, status: "open", end: hwEnd, url: "" },
    { url: "", status: "open" },
    true
  );
}

export type ModuleId =
  | "m1"
  | "m2"
  | "m3"
  | "m4"
  | "a_businessmodel"
  | "a_designsprints"
  | "a_innovationfinance"
  | "a_uxdesign";

export const ModuleOrderMap = {
  "m1": 1,
  "m2": 2,
  "m3": 3,
  "m4": 4,
  "a_businessmodel": 5,
  "a_designsprints": 6,
  "a_innovationfinance": 7,
  "a_uxdesign": 8,
};
