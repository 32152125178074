// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

import agendayAndScreenplayFrontImage from "../method-kit/ads/front/agenday-and-screenplay.svg";
import dealingWithDifficultiesFrontImage from "../method-kit/ads/front/dealing-with-difficulties.svg";
import facilitationToolsFrontImage from "../method-kit/ads/front/facilitation-tools.svg";
import inPersonFacilitationFrontImage from "../method-kit/ads/front/in-person-facilitation.svg";
import leadershipInvolvementFrontImage from "../method-kit/ads/front/leadership-involvement.svg";
import moscowMethodFrontImage from "../method-kit/ads/front/moscow-method.svg";
import oneWeekSprintFrontImage from "../method-kit/ads/front/one-week-sprint.svg";
import onlineFacilitationFrontImage from "../method-kit/ads/front/online-facilitation.svg";
import scopingFrontImage from "../method-kit/ads/front/scoping.svg";
import sprintFacilitationFrontImage from "../method-kit/ads/front/sprint-facilitation.svg";
import sprintInvitationFrontImage from "../method-kit/ads/front/sprint-invitation.svg";
import theSprintTeamFrontImage from "../method-kit/ads/front/the-sprint-team.svg";
import twelveWeekSprintFrontImage from "../method-kit/ads/front/twelve-week-sprint.svg";

// eslint-disable-next-line
import agendayAndScreenplayBackImage from "../method-kit/ads/back/agenday-and-screenplay.svg";
import dealingWithDifficultiesBackImage from "../method-kit/ads/back/dealing-with-difficulties.svg";
import facilitationToolsBackImage from "../method-kit/ads/back/facilitation-tools.svg";
import inPersonFacilitationBackImage from "../method-kit/ads/back/in-person-facilitation.svg";
// eslint-disable-next-line
import leadershipInvolvement1BackImage from "../method-kit/ads/back/leadership-involvement-1.svg";
// eslint-disable-next-line
import leadershipInvolvement2BackImage from "../method-kit/ads/back/leadership-involvement-2.svg";
import moscowMethodBackImage from "../method-kit/ads/back/moscow-method.svg";
import oneWeekSprint1BackImage from "../method-kit/ads/back/one-week-sprint-1.svg";
// eslint-disable-next-line
import oneWeekSprint2BackImage from "../method-kit/ads/back/one-week-sprint-2.svg";
import onlineFacilitationBackImage from "../method-kit/ads/back/online-facilitation.svg";
import scopingBackImage from "../method-kit/ads/back/scoping.svg";
import sprintFacilitationBackImage from "../method-kit/ads/back/sprint-facilitation.svg";
import sprintInvitationBackImage from "../method-kit/ads/back/sprint-invitation.svg";
import theSprintTeamBackImage from "../method-kit/ads/back/the-sprint-team.svg";
import twelveWeekSprintBackImage from "../method-kit/ads/back/twelve-week-sprint.svg";

// @ts-ignore
import agendaAndScreenplayTemplate from "../method-kit/ads/agenda-and-screenplay.pdf";
// @ts-ignore
import dealingWithDifficultiesTemplate from "../method-kit/ads/dealing-with-difficulties.pdf";
// @ts-ignore
import inPersonFacilitationTemplate from "../method-kit/ads/in-person-facilitation.pdf";
// @ts-ignore
import leadershipInvolvementTemplate from "../method-kit/ads/leadership-involvement.pdf";
// @ts-ignore
import moscowMethodTemplate from "../method-kit/ads/moscow-method.pptx";
// @ts-ignore
import oneWeekSprintTemplate from "../method-kit/ads/one-week-sprint.pdf";
// @ts-ignore
import onlineFacilitationTemplate from "../method-kit/ads/online-facilitation.pdf";
// @ts-ignore
import sprintInviteFormulationTemplate from "../method-kit/ads/sprint-invite-formulation.pdf";
// @ts-ignore
import sprintScopingTemplate from "../method-kit/ads/sprint-scoping.pdf";
// @ts-ignore
import theSprintTeamTemplate from "../method-kit/ads/the-sprint-team.pdf";
// @ts-ignore
import twelveWeekSprintTemplate from "../method-kit/ads/twelve-week-sprint.pdf";

export const tools: Tool[] = [
  {
    moduleId: "a_designsprints",
    name: "Sprint Scoping",
    slug: "ads-sprint-scoping",
    resources: ["Pen", "Paper/ Table template", "Team"],
    content: mdx`
### Why should you use this method?

Sprint scoping is essential to:

- Align the team to manage expectations upfront
- Understand the challenge at hand
- Have maximum learning from the sprint

### What is scoping?

Scoping is the starting point of a sprint. It is important to understand what’s in scope and what’s out of scope without deciding on a specific solution or problem. The scope should be neither too broad or too narrow. A good scope typicallyhas the customer problem in the center.

### How can you apply this method?

##### 0. Invite your team

Invite your sprint team to do a quick scoping exercise before the start of the sprint.

##### 1. Initial thoughts

Ensure each team member fills in all the boxes of the scoping canvas besides the challenge statement. This is done individually using post-its and should not take more than 15 min.

##### 2. Facilitate a discussion

The team leader should facilitate a discussion of the boxes filled and make necessary changes to the scope required.

##### 3. Define the challenge statement

Individually reflect on the inputs and come up with a challenge statement in the form of a “How might we” statement.

##### 4. Finalize the challenge statement

Discuss the different challenge statements and agree on a final challenge statement for the sprint.

Note: The ideal scenario to do the scoping would be with your team, but you can also prepare the scope of the sprint individually for the sake of the practical assignment.
    `,
    benefits: [],
    thumbImage: scopingFrontImage,
    backImage: scopingBackImage,
    template: sprintScopingTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "MoSCoW Matrix",
    slug: "ads-moscow-matrix",
    resources: ["Pen", "Markers", "Paper/Table", "template/Post-Its", "Team"],
    content: mdx`
### Why should you use this method?

- To be very clear with the core team about the sprint goals and objectives.
- To define the vision of the sprint.
- Prioritize elements in the sprint.
- Align the team and make a decision on the sprint format to be used.
- To ensure the sprint stays on track.

### What is MoSCoW Matrix?

MoSCow Matrix or the Design Criteria Canvas is a great tool to decide on:

- **Must-haves**: what are the non-negotiables, things that are mandatory for the team? Without them, the sprint will be useless.
- **Should-haves**: What is very important, not vital, but adding significant value?
- **Could-haves**: these are the nice-to-haves. It would be great if it can be a part of the process, but if not, it will have a small impact if left out.
- **Won’t**: this is what we don’t want to happen during the project.

This straightforward structure is a crucial starting point of your sprint. Design criteria are not only simply features of your idea but should be more than that. Examples of some design criteria are: your business must contribute to a greener planer; you want your customers to feel delighted; your new business idea needs to generate a certain amount of revenue within three years.

### How can you apply this method?

##### 0. Arrange for a comfortable environment

Invite your sprint team to a positive and comfortable environment and keep all the materials ready.

##### 1. Brainstorming design criteria

Each team member first brainstorms design criteria individually by writing them on post-its.

##### 2. Mapping on the template

Each team member takes turns to stick their design criteria on the template and justfies why it should be in the box they put it in.

##### 3. Organize the criteria

Now, with the team, go over each sticky note and see what the real non-negotiables are, and cluster the design criteria in the boxes.

Note: The ideal scenario to do the MoSCoW matrix would be with your team, but you can also prepare the vision individually for the practical assignment of the module.

### What’s Up Next?

Regularly revisit your design criteria, both as a touchstone for ideas and directions, and to see if they need to be updated based on the insights gathered throughout the sprint.
    `,
    benefits: [],
    thumbImage: moscowMethodFrontImage,
    backImage: moscowMethodBackImage,
    template: moscowMethodTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "One-week Sprint",
    slug: "ads-one-week-sprint",
    resources: ["Pen", "Markers", "Paper", "Template", "Team"],
    content: mdx`
### Why should you run a one-week sprint?

- It is fast and dedicated
- It helps narrow your options further
- There is no distraction
- You will get an immediate idea about customers’ needs

### How can you run a one-week sprint?

Download the template to see an exemplary agenda of a one-week sprint. Each day has a different focus and clear, pre-defined outcomes:

<img src={oneWeekSprint2BackImage} alt="Image" />

Here is how you can get started:

1. Choose a challenge that is neither too broad nor too narrow
2. Recruit a decider and a sprint team
3. Prepare a war room (refer to the war room method card)
4. Get all the stationery supplies and refreshments ready
5. Pick a facilitator
6. Schedule extra extrenal experts and interviewees
7. Block five full days on the calendar
8. Follow the 5-day schedule. Refer to the template

Tips to get the most out of your sprint:

- No distractions like laptops or phones during the sprint.
- All exercises must be timeboxed to create focus and urgency.
- Plan for sufficient breaks and a late lunch.
- The entire process should be documented.
- Put someone in charge of recruiting customers for the test.
- Do all five interviews in one day to see patterns emerging.

### What’s Up Next?

An alternative format that allows for deeper and further validation is a [twelve-week sprint](/method-kit/ads-12-week-sprint).
    `,
    benefits: [],
    thumbImage: oneWeekSprintFrontImage,
    backImage: oneWeekSprint1BackImage,
    template: oneWeekSprintTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "12-week Sprint",
    slug: "ads-12-week-sprint",
    resources: ["Pen", "Markers", "Paper/Table", "template/Team"],
    content: mdx`
### Why should you use this method?

- Customer-centric project development
- It goes deep into the challenge
- It gives more time to interact with customers
- Come up with a proven final solution to a challenge

### How can you run a twelve-week sprint?

Download the weekly overview to plan the twelve-week sprint. Each week has a clear topic and deliverables:

##### Week 1: Understand

The team should align around the sprint’s scope and vision and understand the context and the current situation of the challenge and the customer segments.

##### Weeks 2-4: Problem confirmation

You identify the different personas and their pains, gains, and jobs-to-be-done through 3 conversations with each identified persona per week.

##### Weeks 5-7: Problem-solution fit

You will ideate solutions based on the customer insights and select 2-3 solutions that you will use for testing. Every week, you will create new low-fidelity prototypes, which you test with your potential future customers.

##### Weeks 8-10: Business model fit

Create a minimum of 3 business model options. Every week, you identify your assumptions, create the test plan and a low-fidelity prototype to test that same week with customers.

##### Week 11: Present

Present the findings to leadership and other stakeholders and get them to confirm your conclusions and next steps.

##### Week 12: Documentation

Document the project and you will find a home for the proven business model so the project can go into a pilot phase.

### How can you get started?

1. Choose a challenge that is neither too broad nor too narrow
2. Recruit a decider and a sprint team
3. Prepare a physical or digital war room
4. Get all the stationery and refreshments ready
5. Pick a facilitator
6. Schedule meetings with the leadership and external experts
7. Follow the schedule. Refer to the template.

Tips

- Every week should have a clear deliverable.
- At any stage during this 12-week sprint process, you can kill the project if the project or the solution do not prove themselves.
- All exercises must be timeboxed to create focus and urgency.
- The entire process should be documented.
    `,
    benefits: [],
    thumbImage: twelveWeekSprintFrontImage,
    backImage: twelveWeekSprintBackImage,
    template: twelveWeekSprintTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "Sprint Invitation",
    slug: "ads-sprint-invitation",
    resources: ["Pen", "paper", "template"],
    content: mdx`
### Why should you use this method?

- To create a sense of urgency amongst the team members
- To create excitement within the team
- To make the team members feel important

### What is a sprint invitation?

A sprint invitation is sent to invite your team members to be a part of the sprint journey. The invitation should create excitement and a sense of urgency within the team. Ensure that everyone who gets invited understands why this project is so important. The goal is to make an impact with this innovation.

An invite should include:

- Why this project, this change, why we should do this project at all (why change?)
- Why this project needs to happen now (why now?)
- Why they are the right team to conquer this (why this team?)
- Some inspiration that will get them thinking

### How can you apply this method?

##### 0. Decide on your team

Select members to be part of the sprint team.

##### 1. Formulate the invite

Before sending the invite, formulate the invite answering all the questions regarding the sprint. (Why this project? Why the urgency? Why are they invited to be part of this sprint?)

##### 2. Find an inspiration

Along with the invite, search for something inspiring to send that is related to the project.

##### 3. Send the invite

With the invitation and inspiration ready, send out the invite in a creative way. For example, with some catchy phrases, graphics, etc.
    `,
    benefits: [],
    thumbImage: sprintInvitationFrontImage,
    backImage: sprintInvitationBackImage,
    template: sprintInviteFormulationTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "The Sprint Team",
    slug: "ads-the-sprint-team",
    resources: ["Pen", "paper", "template"],
    content: mdx`
### Why should you use this method?

- A sprint needs different perspectives on the project
- The team is dedicated to the specific innovation sprint and makes the decisions together
- To make the team members feel important

### How do you assemble your sprint team?

##### 0. Decide on your project challenge

Before recruiting the sprint members, decide on the innovation or project challenge.

##### 1. Recruit the Team Sponsor

The first member of the core team. He is in charge of allocating the resources and is the key decision-maker.

##### 2. Recruit the Team Lead

This role could be filled by you or someone else, who will be in charge of the execution of the sprint.

##### 3. Recruit the team members

As part of the core team, recruit 2-3 members who will be part of the sprint execution at all times.

##### 4. List down members apart from the core team for brainstorming sessions

Apart from the core team, identify members from the leadership, external or internal experts, or customers to be part of the different brainstorming sessions in the sprint or support in another way.

### What’s Up Next?

Invite the team for the sprint. Refer to the [sprint invitation](/method-kit/ads-sprint-invitation) method card as a reference.
    `,
    benefits: [],
    thumbImage: theSprintTeamFrontImage,
    backImage: theSprintTeamBackImage,
    template: theSprintTeamTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "Agenda & Screenplay",
    slug: "ads-agenda-and-screenplay",
    resources: ["Screenplay template"],
    content: mdx`
### Why should you use this method?

- Plan a workshop based on the desired outcomes
- Make sure to open, run, and close each activity to guide the participants
- Improve the efficiency of your workshops

### What is an agenda?

An important part of workshop facilitation is expectation management. An agenda is an overview of the high-level blocks of the workshop/day, including the breaks. Each block - also called strategic conversation - is usually 30 minutes to a maximum of 1.5 hours long. It shows the participants what topics and activities to expect without sharing confusing details.

### What is a screenplay?

For the facilitator, however, you put those elements together in a flow called a screenplay. This is a detailed script, only for the facilitator, so you know what you will do and how you will do it. This is much more elaborated than an agenda. A script or screenplay can consist of 5 or 10-minute blocks where you break down all the egenda items into steps you can take as a facilitator to guide the participants through.

As a facilitator, you don’t want to waste your time thinking about when you can go for a break. You want all your attention to go towards the content, not towards the process. You don’t share the detailed outline with the participants, however, as you want to be able to adapt on the go if necessary.

Yes, the facilitation requires a lot of preparation! But trust me, this in-depth preparation will dramatically improve the efficiency of the session as well as the results.

### How can you plan your workshop and create an agenda and screenplay?

Download the template at the top of the page to note down your agenda and screenplay.

##### 1. Start with the end in mind

What should be the result of this session? What outcomes do you want to achieve?

##### 2. Define the strategic conversations

Think of the strategic conversations you need to reach your desired outcome. Plan 30 minutes to maximum 1.5 hours per strategic conversation during your workshop.

##### 3. Define how to open-run-close the strategic conversations

Every strategic conversation needs to have an intro, a part where you discuss and do an exercise, and a conclusion.

##### 4. Add templates

Make sure that for every strategic conversation you have some sort of template. This could be just a blank page with a title on top or a more complex template. This template will help the team to focus on the task at hand.

##### 5. Open and close the whole session

Start the workshop with why everyone is there, the objectives of the meeting, how you will achieve the objectives by explaining the high-level elements of the agenda and aligning the workshop’s expectations. Close at the end of the workshop by summarizing the conclusions and next steps, and ask everyone how they feel.

##### 6. Write the agenda

Summarize the activities in a high-level agenda for the participants.

##### 7. Write the screenplay

Put together a detailed workshop flow for you as a facilitator. Add descriptions to every activity and note down the materials you need to bring.
    `,
    benefits: [],
    thumbImage: agendayAndScreenplayFrontImage,
    backImage: agendayAndScreenplayBackImage,
    template: agendaAndScreenplayTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "Facilitation Tools",
    slug: "ads-facilitation-tools",
    resources: ["Markers", "Post-its", "Templates", "Flipcharts"],
    content: mdx`
### Why should you use this method?

- **Synthesize**: catpture and share outcomes immediately with the group
- **Clear the noise**: this will help you to stop the blah blah during workshops
- **Visual snapshot**: people remember pictures better than written minutes
- **Document**: Easy to share after the session

We know that every human being is very visual. A picture says more than 1,000 words. But how can we be more visual when facilitating sessions?

##### Writing on a flipchart

A good thing about flipchart paper is that you can move it around the room. You can use this technique to guide and facilitate whenever the team has a conversation. Remember, if it is not on a flip, if didn’t happen! Here are some tips on how to make the best use of it:

- Have the right markers. Make sure they are working and are not leaking through the paper and leaving spots on the wall.
- Write capital letters.
- Write big. Make sure the person in the back can still read what you write down.
- Give every flip a title.
- Write using bullet points and alternate colours.

##### Use of templates

The objective of using templates is that they will focus the conversation. So, what are the tips and tricks about using templates?

- Use templates for group conversations. This will make sure the team stays focused on the conversation they need to have.
- Don’t give people too much time to fill out a template. Typically, 20-30 minutes should be enough.
- Make sure they have a large print of the template (A1 size/24x33 inches).
- Make sure people filling the template are using post-its. Never let them write on a template!

##### Post-its

The beauty of post-its is that people can move them freely around or entirely remove them if they change their minds. Post-its also help you to be more specific about what you want to say. It will help to summarie and draw conclusions. And it is easy to cluster and group different thoughts and ideas. Some tips and tricks:

- Write with black marker with a tip of around 1mm. Make sure every team member has one of these markers.
- Write with capital letters only.
- Every item is a new post-it. Never create a list of bullet points on a post-it.
- Use color coding when helpful.
- Encourage the team to draw on the post-its!
- Peel it off side to side to get a flat post-it that is easier to read and sticks better.

##### Drawing

It is about drawing icons. Very simple icons that are understood immediately by everyone. How do you do that?

- Practice. Write down a list of 10-20 words, Google these words, and add the words ‘doodle icon’ in the search bar. Copy or make your own drawing for each.
- Learning to sketch takes time but you will see that it will get easier once you have the first drawing in your repertoire.
- You should draw the word almost as fast as writing the word.
- Add a word to it to make it super clear.
    `,
    benefits: [],
    thumbImage: facilitationToolsFrontImage,
    backImage: facilitationToolsBackImage,
  },
  {
    moduleId: "a_designsprints",
    name: "In-Person Facilitation",
    slug: "ads-in-person-facilitation",
    resources: ["Pen", "paper/ template"],
    content: mdx`
### What is In-Person Facilitation?

When a design sprint is conducted physically, you (or someone) will be in charge of running and executing the design sprint.

### Things to keep in mind while running in-person sessions:

- A diverse group size of 20-24 people in a room is easy to manage.
- A team size of 4 is ideal for executing teamwork. This ensures competition between teams and makes it easy to track progress.
- Split the group into smaller teams in the same room. This saves a lot of time and energy while trying to regroup them.
- Print or draw the templates on A1 size sheets for the teams to work on in directly using sticky notes.
- Have a war room ready to conduct the entire workshop.
- Keep taking pictures and documenting all the work throughout the session.

### Rules

It is important to set the ground rules before starting the session and set behaviour expectations. Some rules for in-person sessions are:

- No use of mobiles or laptops during the session
- No blah blah
- Yellow hats only, no black hats
- Be creative, be a rebel

### Why is it important to have a war room?

- To have an actual space where a group of people can work on the sprint
- A place to co-design and collaborate
- A place to validate assumptions
- You can stand in front of the wall and see what is going on within your sprint
- You can interact immediately with what you see
- You can invite others into your room for feedback and input
- It is easy to document the process. All the information is displayed in one place

### How to set up such a room?

- Find a location that sparks creativity and takes the participants out of their comfort zone
- Claim a room for the duration of the sprint
- Ensure this room has a lot of wall space you can use. You can also add stand-alone display boards to add more wall surfaces
- Table set up for teams. It has to be moveable tables that can be format in such a way that every team has their own table.
- Have sufficient space to move around
- Sufficient daylight coming into the room
- Print the templates large scale, minimum A1 size
- Create different corners of the walls for the various stages of the sprint
- The setup of the room should translate into a story
- The door to the war room should always be open
- The room should always have refreshments, water and candies for external guests who will be providing feedback and input
  `,
    benefits: [],
    thumbImage: inPersonFacilitationFrontImage,
    backImage: inPersonFacilitationBackImage,
    template: inPersonFacilitationTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "Online Facilitation",
    slug: "ads-online-facilitation",
    resources: ["Digital war room", "templates"],
    content: mdx`
### What is Online Facilitation?

When a design sprint needs to be conducted online due to unforseen circumstance or while working with remote team members, you (or someone) needs to be in charge of running and executing the design sprint.

### Rules

It is important to set the ground rules before starting the session and set behaviour expectations. Some rules for online sessions are:

- Video must always be on
- Participants to be on mute
- Raise hands to ask questions
- Use the chatbox to interact with each other

### Things to keep in mind while running online sessions:

- Preferred group size is around 12 people for a very interactive session
- A team size of 4 is ideal for executing teamwork. This ensures competition within the teams and makes it easy to track progress
- Enable interaction at least every 5 minutes. A few examples to boost the interaction:
  - Ask a question and ask people to raise their hands in a digital way
  - Ask quick yes/no questions that people can reply to
  - Ask a question and let everyone reply using the chatbox
  - Actively call out people and ask for their input
  - Create a poll
- Use virtual break-out rooms for teamwork. As a facilitator, visit every break-out room while the teams are working on their task
- Have 1.5 hour sessions spread out over multiple days. Don’t go over 3 hours for an online session
- Use online collaboration tools for templates like Miro or Mural
- Apply visual facilitation by writing on a digital whiteboard
- Don’t forget to share your screen so everyone can see what is being captured
- Use a second screen to see the chatbox and the participants. This will help monitor the energy levels
- Have a war room ready to conduct the entire workshop
- Keep taking pictures and documenting all the work throughout the  session
- Optional: send the participants a physical package in advance of the  meeting

### Why is it important to have a war room?

- To have an actual space where a group of people can work on the sprint
- A place to co-design and collaborate
- A place to validate assumptions
- You can browse through a screen and see what is going on within your sprint
- You can interact immediately with what you see
- You can invite others into your room for feedback and input
- It is easy to document the process. All the information is displayed in one place

### How to set up such a room?

- Use an online collaboration tool to set this up. Things to keep in mind while choosing the tool:
  - Can add your own templates
  - The use of post-its needs to be possible
  - People are able to work in the tool simultaneously and see what the other person is doing
  - Select a tool that does not need to be downloaded nor required to create a personal account
- Create different corners on the walls for the various stages of the sprint
- Create a good flow so it is easier for people to digest what they see
- Ensure you are not replacing a war room with a default folder structure somewhere on a shared drive
  `,
    benefits: [],
    thumbImage: onlineFacilitationFrontImage,
    backImage: onlineFacilitationBackImage,
    template: onlineFacilitationTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "Sprint Facilitation",
    slug: "ads-sprint-facilitation",
    resources: [],
    content: mdx`
### Why should you use this method?

- Be clear abour your role as a facilitator
- Get people into the right mindset

### What is the sprint lead/facilitator?

The sprint concept is very relevant in innovation. It helps to untangle the messiness of uncertainty and gives structure to solving any problem. It will help you focus on the things that really matter. The sprint lead is the individual who is in charge of the sprint on a day-to-day basis. As the sprint lead and facilitator, it is your job to:

- Create the sprint format, plan accordingly and keep the team on track
- Ensure quality of findings, recommendations and pivots
- Liaison with the team sponsor
- Educate, inspire and energize the team

### Energize the team

To get the maximum out of the participants, it is your job to help them get in the right mindset so their contributions will be of real value for you and the sprint.

- Send a motivating invitation that creates a sense of urgency
- Send some upfront inspiration and triggering questions
- Select a suitable workshop location with the right vibe
- Provide refreshments like snacks and water
- Use [energizers](/method-kit/m2-energizer-principles) to raise the energy in the group and make them feel comfortable
- Play music during breaks and creative activities like brainstorming
- Finish the session on a high, ensure the participants feel progress and next steps are identified to get to a resolution soon

### Manipuhelping

In your role as a facilitator, you want to achieve the outcomes that you promised and planned. To get there is the art of “Manipuhelping” - manipulating the participants in order to help them.

- Be very clear on the worskhop objectives and outcomes. Don’t overpromise and underdeliver. People will leave the room frustrated
- Create ownership by allowing the participants to give the answers and own the putcome. Don’t push your ideas onto them. They want to think that they cae up with the ideas themselves
- Ask the right questions. You are in front of the group. You are the one that asks the questions and steers the conversation
- Write down conclusions. You are holding the pen and you decide what you write down on a flipchart and how you write it down
- Prepare the content in-depth. For every exercise, for every strategic conversation, write down the possible solutions you can think of and create a shortlist. This helps to ask guiding questions if the team is missing something

Manipuhelping is an art. You have a sense of direction. However, you want to keep enough flexibility to move in a different direction as well. In the end, that is why you want to get the collective brainpower to think about a solution. If you do it right, it will look like you are just writing down what everyone says. However, you have to peddle hard underneath the surface to make it happen, but you can’t show this hard work to the team. You need to stay very calm and in control.

How do you get a spring workshop started?

Are you planning an [in-person](/method-kit/ads-in-person-facilitation) or an [online facilitation](/method-kit/ads-online-facilitation)? Check out the tips for each on the respective method cards!

Before getting started, consider your [sprint scope](/method-kit/ads-sprint-scoping) and [team](/method-kit/ads-the-sprint-team).

To facilitate a workshop, prepare by writing an [agenda](/method-kit/ads-agenda-and-screenplay) and detailed screenplay!

And if you are getting into action, have a look at the tips and tricks to [involve your leadership](/method-kit/ads-leadership-involvement) and deal with [difficult situations](/method-kit/ads-dealing-with-difficulties).
 `,
    benefits: [],
    thumbImage: sprintFacilitationFrontImage,
    backImage: sprintFacilitationBackImage,
  },
  {
    moduleId: "a_designsprints",
    name: "Dealing with Difficulties",
    slug: "ads-dealing-with-difficulties",
    resources: ["Cheatsheet"],
    content: mdx`
### Why should you use this method?

- Involve everyone in team activities
- Deal with challenging behavior, such as dominant spekers, silent observers or jokers
- Make sure that a workshop has clear outcomes and produces results
- Stay focused on the goals

### Do you have to deal with difficulties during a workshop?

First of all, don’t be afraid of difficult situations. They are part of every innovative process. Innovation teams that only have fun and move forward in a straight line are probably playing it safe and the outcome will not be groundbreaking. In innovation, we want to explore the boundaries and we want to test the waters in risky territories. And that brings feelings of uncertainty, which is often perceived as uncomfortable and might lead to friction in the team. Good! This means that the team is getting somewhere. Don’t run away from friction in an innovation proces. Embrace it.

### Tips and tricks to deal with difficult situations

Difficult situations often occur because of the simple reason that the participants don’t feel heard or understood. Read through the tips below to prepare yourself for any workshop. Additionally, download the descriptions of difficult personas and tips on reacting to them as facilitators.

- **Understand who is in the room.** Go through the participant list: What kind personality do they have? Are they the dominant type, the nay-sayer, the silent person? What are their desires and what are their concerns? Create an agenda that fits the group! It will make sure you know better what to expect and make sure they all feel heard during the session.
- **Get the negativity out as fast as you can.** Make sure there is space  for urgent matters participants feel the need to discuss. The same  goes for the famous elephant in the room. Address it immediately  once you feel there is something people are not saying out loud.
- **Write down on a flip/collaboration tool what the participants are saying.** This will immediately mitigate the risk that people don’t feel  heard. Everyone can see you heard them since you wrote down what they said. Another advantage of writing down what you hear is that this will endless conversations, the blah blah. Stop someone talking and ask: “It seems that you want to bring across an important point, but I struggle to sumarize it. Could you tell me what I should write down on this flip?”
- **Ensure every voice is heard.** If you haven’t heard someone in a while, make sure you directly ask for their opinion occasionally. Another tip is to break the group into smaller teams throughout. Or include quiet, individual brainstorming activities that allow everybody to formulate their opinion and no escaping is possible.
- **Draw conclusions.** Whenever you feel it is discussed enough, take the floor and state your conclusion as simply as you can. Writing it down will help you be short and concise and still give people room to adjust what you say.
- **Create a parking lot.** You don’t want the group to get distracted by relevant items outside the topic you are in right now. Write their comment on a post-it and stick it on the parking lot. Ensure that you address the items on the parking lot at the end of the session and define the next step for these items.
- **Don’t allow phones and laptops on the table.** Make sure participants put away everything that can distract them. In return, make sure the breaks are long enough so they can check their messages and make a quick phone call. When you communicate the breaks up front, they know they can schedule their urgent calls during these breaks to avoid disruption of the session.
- **Ensure people know why they have been invited and why their input is so important to you.** Address this very specifically. It’s already within the invite, but don’t hesitate to repeat it. Explain why you believe their contribution to the workshop is of critical value. Most often, that will make them feel better and they will change their behavior. However, if it is not changing, don’t be afraid to tell them it is okay to leave and do something else. Just make sure you address the issue and take action. You dont want people in the room who take away energy.
- **Ask for written feedback instead of verbal.** This will ensure you get the opinion of everyone, not just the loudest and fastest ones. And when you ask for feedback, make sure you structure it. Using the [6 Thinking Hats](/method-kit/m3-six-thinking-hats), ask them to write at least one post-it per hat per person. Instead, you can also frame the feedback in ‘[I like, I wish, I wonder](/method-kit/m3-i-like-i-wish-what-if)’.
 `,
    benefits: [],
    thumbImage: dealingWithDifficultiesFrontImage,
    backImage: dealingWithDifficultiesBackImage,
    template: dealingWithDifficultiesTemplate,
  },
  {
    moduleId: "a_designsprints",
    name: "Leadership Involvement",
    slug: "ads-leadership-involvement",
    resources: ["Cheatsheet"],
    content: mdx`
### Why should you use this method?

- Involve leadership and satisfy curiosity
- Report to leadership without wasting project time
- Gain decision freedom without involving leadership all the time

### How can you involve leadership in a sprint?

Everyone in the organization is always very curious about what is happening within the innovation teams. I get that! The sprint teams are working on exciting projects. The uncertainty of any innovation project additionally adds to why leadership wants to be updated and involved constantly.

However, this is not very beneficial for you as a sprint lead. Reporting to leadership can be time-consuming. That should not be the case because every hour you did not spend on the customer is lost time. But of course, if you don’t keep leadership up to date, this might harm the project going forward since you will need their buy-in.

##### 1. Fight for freedom of the team

- Ensure the team has the power to make these decisions without involvement from leadership. A great way to do this is to agree on the types of decisions where leadership has to be involved, for example, based on the budget being spent
- Show the leadership team the flow of the sprint process and set up a meeting with them around the more significant milestones
- Actively involve them instead of simply reporting. Tell them how they can help you. Where do you need their input?
- Agree on a short reaction time. Agree with the leadership that they will make themselves available within 48 hours

<img src={leadershipInvolvement1BackImage} alt="Image" />

##### 2. Add value through reporting

Plus, if you present your innovation project the same way as any other project, how will you stand out? How will you show that what you are working on is disruptive? Leadership should get in a different mindset when it comes to innovation. They should not stay in their execution mindset. That won’t help the outcome of your sprint. Below are some ideas on how to achieve that:

- Be creative. How can you use what you already have? Have the meeting in the war room to take them through the critical elements and conclusions within the room. Even when you created a digital war room, this works. Avoid creating PowerPoint decks just to show to leadership
- Avoid vanilla. Dare to have a strong point of view. You want a reaction. You want interaction. Dare to push the boundaries. Dare to make bold statements. At allcause, avoid blended, plain presentations. Shake things up!
- Think about the objective of the meeting. It shouldn’t just be to inform leadership. How will this meeting help you? How can you get the most out of this? What do you need from leadership? Everyone in the meeting has the same goal, and that is a successful outcome of the sprint. So, use this moment and ensure this meeting is adding immediate value to the project

<img src={leadershipInvolvement2BackImage} alt="Image" />
    `,
    benefits: [],
    thumbImage: leadershipInvolvementFrontImage,
    template: leadershipInvolvementTemplate,
  },
];
