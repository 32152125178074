/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";
import * as React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

type Props = {
  sx?: SxStyleProp;
};

export const Layout: React.FC<Props> = ({ children, sx = {} }) => (
  <div
    sx={{
      width: "100vw",
      minHeight: "100vh",
      position: "relative",
      overflowY: "auto",
      overflowX: "hidden",
      display: "flex",
      flexDirection: "column",
      bg: "white",
      ...sx,
      main: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
      },
    }}
  >
    <Header />
    <main>{children}</main>
    <Footer />
  </div>
);
