export class TrackingService {
  static track(event: string, props: object = {}) {
    if (!window.E2E_TESTING) {
      console.info(`Tracking event: ${event}`, props);
      window.analytics && window.analytics.track(event, props);
    }
  }

  static methodKitRequestToLogIn(email: string) {
    this.track("Public_Method_Kit Login_Attempt", { email });
  }

  static methodKitLoggedIn(email: string) {
    this.track("Public_Method_Kit Logged_In", { email });
  }

  static methodKitFailedLogIn(email: string, token: string = "") {
    this.track("Public_Method_Kit Login_Failed", { email, token });
  }
}
