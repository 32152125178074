import * as React from "react";
import { ReactComponent as Icon } from "../materials/icons/loader.svg";
import { Box, SxStyleProp } from "theme-ui";

export const Loader: React.FC<{ bgColor?: string }> = ({ bgColor }) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bg: bgColor || "initial",
    }}
  >
    <Box
      sx={{
        width: 100,
        height: 100,
        color: "brand.green",
        svg: {
          width: "100%",
          height: "auto",
        },
      }}
    >
      <Icon />
    </Box>
  </Box>
);

export const LoaderWithLabel: React.FC<{ label: string }> = ({ label }) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box
      sx={{
        width: 100,
        height: 100,
        color: "brand.green",
        svg: {
          width: "100%",
          height: "auto",
        },
      }}
    >
      <Icon />
    </Box>
    <Box>{label}</Box>
  </Box>
);

export const LoaderInline: React.FC<{ sx?: SxStyleProp }> = ({ sx = {} }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box
      sx={{
        width: 100,
        height: 100,
        color: "brand.green",
        svg: {
          width: "100%",
          height: "auto",
        },
        alignContent: "center",
        ...sx,
      }}
    >
      <Icon />
    </Box>
  </Box>
);
