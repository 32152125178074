import { Theme } from "theme-ui";
export const theme: Theme = {
  useCustomProperties: false,
  fonts: {
    body: 'interstate, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'interstate, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  fontWeights: {
    normal: 400,
    body: 500,
    heading: 500,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    black: "#000",
    white: "#fff",
    grays: {
      medium: "#5B5B5B",
      lighter: "#979797",
      light: "#D1D1D1",
      superLight: "#F6F6F6",
    },
    brand: {
      blue: "#4b27e0",
      blueLight: "#AD9DF0",
      blueDark: "#0F134F", // used in shadows

      green: "#17E5A0",
      greenLight: "#C6EFD3",

      red: "#FC464D",
      redLight: "#FDA79C",

      turquoise: "#56CEFF",
      turquoiseLight: "#C7EEFE",

      yellow: "#FEBF28",
      yellowLight: "#FFE9B6",
    },
    text: "#000",
    background: "#F6F6F6",
    primary: "#4b27e0",

    new: {
      grays: {
        bgDark: "#212d39",
        lockedGrey: "#979797",
        nearlyWhite: "#f6f6f6",
      },
      primary: {
        purple: "#4B27E0",
        green: "#17E5A0",
        white: "#FFFFFF",
        black: "#000000",
        darkGrey: "#3C3C3B",
      },
      secondary: {
        red: "#FF5F5F",
        yellow: "#FFC933",
        grey: "#BFBFBF",
        blue: "#64D7FF",
        mint: "#8BF2CF",
        violet: "#A593EF",
        lightGrey: "#F5F6F8",
      },
      coreYellow: "#ffc933",
      coreYellowLight: "#FFF4D6",
      advancedGreen: "#17e5a9",
      advancedGreenLight: "#E8FCF5",
      masterBlue: "#4b27eb",
      failingRed: "#ff5f5f",
      failingRedLight: "#FFDFDF",
      turquoise: "#64d7ff",
      turquoiseLight: "#E0F7FF",
      lemon: "#FFE499",

      gambling: {
        bg: "#3c3c3c",
        track: "#bfbfbf",
        trackBg: "#6a6a6a",
      },

      bg: "#EDEDF4",
    },
  },
  fontSizes: [
    `${11 / 16}rem`,
    `${15 / 16}rem`,
    `${16 / 16}rem`,
    `${20 / 16}rem`,
    `${23 / 16}rem`,
    `${26 / 16}rem`,
    `${30 / 16}rem`,
    `${35 / 16}rem`,
    `${40 / 16}rem`,
    `${54 / 16}rem`,
    `${94 / 16}rem`,
  ],
  space: [
    `${0 / 16}rem`,
    `${2 / 16}rem`,
    `${4 / 16}rem`,
    `${8 / 16}rem`,
    `${12 / 16}rem`,
    `${16 / 16}rem`,
    `${20 / 16}rem`,
    `${24 / 16}rem`,
    `${32 / 16}rem`,
    `${40 / 16}rem`,
    `${48 / 16}rem`,
    `${64 / 16}rem`,
    `${80 / 16}rem`,
    `${96 / 16}rem`,
    `${128 / 16}rem`,
    `${160 / 16}rem`,
    `${192 / 16}rem`,
    `${224 / 16}rem`,
    `${256 / 16}rem`,
  ],
  styles: {
    root: {
      m: "0px",
      fontFamily: "body",
      //   fontWeight: 'body',
      //   lineHeight: 'body',
    },
  },
};

export const themeNew: Theme = {
  useCustomProperties: false,
  fonts: {
    body: 'interstate, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'interstate, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  lineHeights: {
    body: 1.4,
    heading: 1.125,
  },
  colors: {
    black: "#000",
    white: "#fff",
    grays: {
      medium: "#5B5B5B",
      lighter: "#979797",
      light: "#D1D1D1",
      superLight: "#F6F6F6",
    },
    brand: {
      blue: "#4b27e0",
      blueLight: "#AD9DF0",
      blueDark: "#0F134F", // used in shadows

      green: "#17E5A0",
      greenLight: "#C6EFD3",

      red: "#FC464D",
      redLight: "#FDA79C",

      turquoise: "#56CEFF",
      turquoiseLight: "#C7EEFE",

      yellow: "#FEBF28",
      yellowLight: "#FFE9B6",
    },
    text: "#000",
    background: "#F6F6F6",
    primary: "#4b27e0",

    new: {
      grays: {
        bgDark: "#212d39",
        lockedGrey: "#979797",
        nearlyWhite: "#f6f6f6",
      },

      primary: {
        purple: "#4B27E0",
        green: "#17E5A0",
        white: "#FFFFFF",
        black: "#000000",
        darkGrey: "#3C3C3B",
      },
      secondary: {
        red: "#FF5F5F",
        yellow: "#FFC933",
        grey: "#BFBFBF",
        blue: "#64D7FF",
        mint: "#8BF2CF",
        violet: "#A593EF",
        lightGrey: "#F5F6F8",
      },

      coreYellow: "#ffc933",
      coreYellowLight: "#FFF4D6",
      advancedGreen: "#17e5a9",
      advancedGreenLight: "#E8FCF5",
      masterBlue: "#4b27eb",
      failingRed: "#ff5f5f",
      failingRedLight: "#FFDFDF",
      turquoise: "#64d7ff",
      turquoiseLight: "#E0F7FF",

      gambling: {
        bg: "#3c3c3c",
        track: "#bfbfbf",
        trackBg: "#6a6a6a",
      },

      exceptions: {
        circleIconBg: "#D2C9F7",
        errorCardBg: "#FFE7E7",
      },

      bg: "#EDEDF4",
    },
  },
  fontSizes: [
    `${16 / 16}rem`, // 0
    `${20 / 16}rem`, // 1
    `${24 / 16}rem`, // 2
    `${35 / 16}rem`, // 3
    `${44 / 16}rem`, // 4
  ],
  space: [
    `${0 / 16}rem`,
    `${2 / 16}rem`, //1
    `${4 / 16}rem`, //2
    `${8 / 16}rem`, //3
    `${12 / 16}rem`, //4
    `${16 / 16}rem`, //5
    `${20 / 16}rem`, //6
    `${24 / 16}rem`, //7
    `${32 / 16}rem`, //8
    `${40 / 16}rem`, //9
    `${48 / 16}rem`, //10
    `${60 / 16}rem`, //11
    `${64 / 16}rem`, //12
    `${80 / 16}rem`, //13
    `${80 / 16}rem`, //14
    `${96 / 16}rem`, //15
    `${128 / 16}rem`, //16
    `${160 / 16}rem`,
    `${192 / 16}rem`,
    `${224 / 16}rem`,
    `${256 / 16}rem`,
  ],
  styles: {
    root: {
      m: "0px",
      lineHeight: "body",
    },
  },
};
