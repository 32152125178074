// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

// UX design assets

// Images
import sparkingIdeasFront from "../method-kit/aux/front/sparking-ideas.svg";
import sparkingIdeasBack from "../method-kit/aux/back/sparking-ideas.svg";

import journeyMappingFront from "../method-kit/aux/front/customer-journey-mapping.svg";
import journeyMappingBack from "../method-kit/aux/back/customer-journey-mapping.svg";

import throughUserInsightsFront from "../method-kit/aux/front/through-user-insights.svg";
import throughUserInsightsBack from "../method-kit/aux/back/through-user-insights.svg";

import studyingCompetitiveLandscapeFront from "../method-kit/aux/front/studying-competitive-landscape.svg";
import studyingCompetitiveLandscapeBack from "../method-kit/aux/back/studying-competitive-landscape.svg";

import exploreAnalogousInspirationFront from "../method-kit/aux/front/explore-analogous-inspiration.svg";
import exploreAnalogousInspirationBack from "../method-kit/aux/back/explore-analogous-inspiration.svg";

import futureScenarioPlanningFront from "../method-kit/aux/front/future-scenario-planning.svg";
import futureScenarioPlanningBack from "../method-kit/aux/back/future-scenario-planning.svg";

import ideaMappingFront from "../method-kit/aux/front/idea-mapping.svg";
import ideaMappingBack from "../method-kit/aux/back/idea-mapping.svg";

import behavioralPrinciplesFront from "../method-kit/aux/front/behavioural-principles.svg";
import behavioralPrinciplesBack from "../method-kit/aux/back/behavioural-principles.svg";

import multiSensoryDesignFront from "../method-kit/aux/front/multi-sensory-design.svg";
import multiSensoryDesignBack from "../method-kit/aux/back/multi-sensory-design.svg";

import meFrameworkFront from "../method-kit/aux/front/me-framework.svg";
import meFrameworkBack from "../method-kit/aux/back/me-framework.svg";

import surveyDesignFront from "../method-kit/aux/front/survey-design.svg";
import surveyDesignBack from "../method-kit/aux/back/survey-design.svg";

import storyboardFront from "../method-kit/aux/front/storyboard-thumbnail.svg";
//Templates

//  @ts-ignore
import customerJourneyMappingTemplate from "../method-kit/aux/customer-journey-mapping-template.pptx";
//  @ts-ignore
import competitiveLandscapesTemplate from "../method-kit/aux/comptetitive-landscapes-template.pptx";
//  @ts-ignore
import storyBoardingTemplate from "../method-kit/aux/storyboarding-template.pptx";
//  @ts-ignore
import throughUserInsightsTemplate from "../method-kit/aux/through-user-insights-template.pptx";
//  @ts-ignore
//  @ts-ignore
import exploreAnalogousInspirationTemplate from "../method-kit/aux/explore-analogous-inspiration-template.pptx";
//  @ts-ignore
import futureScenarioPlanningTemplate from "../method-kit/aux/future-scenario-planning-template.pptx";
//  @ts-ignore
import ideaMappingTemplate from "../method-kit/aux/idea-mapping-template.pptx";
//  @ts-ignore
import multiSensoryDesignTemplate from "../method-kit/aux/multi-sensory-design-template.pptx";
//  @ts-ignore
import meFrameworkTemplate from "../method-kit/aux/me-framework-template.pptx";

export const tools: Tool[] = [
  {
    moduleId: "a_uxdesign",
    name: "Customer Journey Mapping",
    slug: "aux-customer-journey-mapping",
    resources: ["Pen", "Paper / Table template", "Team "],
    content: mdx`
### Why should you use this method?

- To maintain a customer-centric mindset
- Identify bottlenecks or silos
- Quickly spot areas of need
- Identify areas of opportunities

### What is customer journey mapping?

Customer journey mapping is mapping out every engagement your customer has with your service, brand, product offering. Businesses often have multiple customer journey maps, reflecting a different area where the customer engages with your business or brand.

Overview of a customer journey map:

- **Identify core user moments:** Identify the core moments of engagement for your user
- **Identify secondary moments:** Think about people or factors that influence the user
- **Order the moments:** Consider the order you think your user would likely experience the core and secondary moments and evolve your original journey map by adding, removing, reordering, revising those&nbsp;moments

Some tips on how to focus the moments

- **Design the opening moment:** The beginning of the experience is the perfect opportunity to set the tone and the rules of engagement
- **Design the closing moment:** The ending moment should offer closure by revisiting a prompt or idea from earlier or generate some warm-fuzzies by sharing a moment of gratitude with your users

### How can you create a customer journey map?

##### 0. Before you start

Define the persona or the user for the customer journey mapping.

##### 1. Define the stages

Cover all the experiences starting from when the customer becomes aware of the product to sustaining their loyalty with you.

##### 2. Determine the steps

Determine all the steps required by the customer in those stages of your journey.

##### 3. Define the touchpoints

Define all the crucial touchpoints that the user will go through in those&nbsp;steps.

##### 4. List the departments

List out all the departments that will have an impact on the user experience in that particular stage of the journey.

##### 5. Map the emotions

Write down the emotional shift that you would like the user to&nbsp;experience.

##### 6. Identify the needs

Finally, based on the emotional shift you want to achieve, identify the user need that will cause that shift.

##### 7. Repeat for every stage

Repeat steps from 2-6 for every stage of the user journey.

##### 8. Focus on the opening moment

Identify the opening moment of the customer journey and state it to have a clear focus.

##### 9. Focus on the closing moment

Identify the closing moment of the customer journey and state it to have a clear focus.


   `,
    benefits: [],
    thumbImage: journeyMappingFront,
    backImage: journeyMappingBack,
    template: customerJourneyMappingTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Through User Insights",
    slug: "aux-through-user-insights",
    resources: ["Pen", "Paper / Table template", "Interviewees"],
    content: mdx`



### Why should you use this method?

-   To maintain a customer-centric mindset

-   Identify bottlenecks or silos

-   Quickly spot areas of need

-   Identify areas of opportunities



### What is customer journey mapping?

Customer journey mapping is mapping out every engagement your customer has with your service, brand, product offering. Businesses often have multiple customer journey maps, reflecting a different area where the customer engages with your business or brand.

Overview of a customer journey map:

-   *Identify core user moments:* Identify the core moments of     engagement for your user

-   *Identify secondary moments:* Think about people or factors that     influence the user

-   *Order the moments:* Consider the order you think your user would     likely experience the core and secondary moments and evolve your     original journey map by adding, removing, reordering, revising      those moments


Some tips on how to focus the moments

-   *Design the opening moment:* The beginning of the experience is the     perfect opportunity to set the tone and the rules of engagement

-   *Design the closing moment:* The ending moment should offer closure     by revisiting a prompt or idea from earlier or generate some     warm-fuzzies by sharing a moment of gratitude with your users



### How can you create a customer journey map?


#### 0.   Before you start

Define the persona or the user for the customer journey mapping.


#### 1.   Define the stages

Cover all the experiences starting from when the customer becomes aware of the product to sustaining their loyalty with you.


#### 2.   Determine the steps

Determine all the steps required by the customer in those stages of your journey.


#### 3.   Identify the needs

Finally, based on the emotional shift you want to achieve, identify the user need that will cause that shift.


#### 4.   Define the touchpoints

Define all the crucial touchpoints that the user will go through in those steps.


#### 5.   Map the emotions

Write down the emotional shift that you would like the user to experience.


#### 6.   Repeat for every stage

Repeat steps from 2-6 for every stage of the user journey.


#### 7.   Focus on the opening moment

Identify the opening moment of the customer journey and state it to have a clear focus.


#### 8.   Focus on the closing moment

Identify the closing moment of the customer journey and state it to have a clear focus.

    `,
    benefits: [],
    thumbImage: throughUserInsightsFront,
    backImage: throughUserInsightsBack,
    template: throughUserInsightsTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Studying Competitive Landscape",
    slug: "aux-studying-competitive-landscape",
    resources: ["Pen", "Paper / Table template"],
    content: mdx`
### Why should you use this method?

- To strategically design a superior product or experience
- It is a critical part of the research process
- Understanding the landscape of solutions is critical to the foundation of the solution you are designing

### What do you mean by studying the competitive landscape?

A competitive landscape analysis provides strategic insights into the features, functions, flows, and feelings evoked by the design solutions of your competitors.

Once the main competitors have been identified, look into the competitor’s end-to-end user experience. But always remember to keep in mind your product’s goals, how you want users to feel about using your product, and why they would prefer using your product over the&nbsp;competitors.

### How can you study your competitive landscape?

##### 0. Before you start

Some questions to consider when you analyze your competitors:

- Who is currently trying to solve this problem?
- How are they trying to solve the problem?
- What is their main differentiator, the unique value added by their business and products?
- Did anyone try to solve it in the past and fail? And why did they fail?

##### 1. Create a list of comparison criteria

Use the template or prepare your list of questions that you are answering about your competitor. You can always add more criteria if it makes sense. This will keep your research guided. And these will be different depending on what your business is.

##### 2. Run a SWOT analysis

Run a fundamental competitor analysis matrix such as SWOT analysis to closely examine the Strengths, Weaknesses, Opportunities, and Threats related to your company.

##### 3. Identify 3-5 competitors

Start with 3-5 competitors and be aware of when to stop.

##### 4. Get inspired but don’t copy

Don’t copy your competitors' designs you find in your research. The competitors may not be using best practices, so instead, be inspired by the solutions found in your research and adopt the solutions to fit your brand, product, and users.
`,
    benefits: [],
    thumbImage: studyingCompetitiveLandscapeFront,
    backImage: studyingCompetitiveLandscapeBack,
    template: competitiveLandscapesTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Explore Analogous Inspiration",
    slug: "aux-explore-analogous-inspiration",
    resources: ["Pen", "Paper / Table template"],
    content: mdx`

### Why should you use this method?

- To break out familiar patterns
- To provoke new areas of thinking
- Spark new insights

### What do you mean by exploring analogous inspiration?

The longer we’ve been working in a particular context or system, the harder it can be to break out of familiar patterns and ways of solving problems. Looking for inspiration far outside our usual field of view can help spark new insights while keeping us grounded in the deeper emotional needs of the people we want to serve. This helps unlock radically fresh perspectives.

### How can you explore analogous inspirations?

##### 1. Choose the moment

Choose a piece of the service, experience, or problem you want to focus&nbsp;on.

##### 2. Identify the emotion

Identify one emotion you want to evoke in your audience of focus.

##### 3. Brainstorm analogous experiences

Brainstorm other services, experiences, or solutions that evoke that emotion. Choose one to move forward with. Then explore how that analogous service, experience, or solution evokes that emotion.

##### 4. Redefine the problem statement

Fill in the statement: How might we make (our service, experience, or problem) more like (analogous service, experience, or solution)?

##### 5. Brainstorm ideas

Use the statement for a second brainstorm to generate new ideas for your context.
  `,
    benefits: [],
    thumbImage: exploreAnalogousInspirationFront,
    backImage: exploreAnalogousInspirationBack,
    template: exploreAnalogousInspirationTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Future Scenario Planning",
    slug: "aux-future-scenario-planning",
    resources: ["Pen", "Paper / Table template"],
    content: mdx`
### Why should you use this method?

- To understand uncertainty and develop a strategy
- To look for design opportunities along the journey map
- Spark new insights

### What is future scenario planning?

Future scenario planning is changing/ designing your business, products, service by unpacking possible future scenarios or&nbsp;hypotheticals.

If we look to the future, it is a way to look for design opportunities along the customer journey map. It is important to expand the imagination to see a broader range of possible futures. Doing this will help take advantage of the unexpected opportunities that will come along.

### How do you plan for future scenarios?

##### 1. Decide the time frame

Decide on a time frame. While you are setting the time frame, you should consider various factors:

- The life cycle of your product
- The political conditions of your country
- Competitors analysis
- Technological advancement

##### 2. Identify trends and driving forces

While identifying the trends and driving forces, focus on:

- Who has an interest in these decisions?
- Who will be affected by them?
- Who could influence them?

##### 3. Define the constraints

Identify two future constraints relevant to your project. This will be the basis for building the scenario and plot.

##### 4. Define their extremes

Think about the extreme cases your constraining variables could take. E.g., when looking at transportation, a constraint could be the use of fossil fuels. The extremes being “Legislation does not change” and “fossil fuels are not allowed anymore.”

##### 5. Develop a scenario

With the extremes of two variables, build one potential future scenario. In the template, this is displayed as one quadrant. While building a scenario, find the strength and weaknesses of the plan and work accordingly. Define touchpoints around which your story will revolve.

##### 6. Brainstorm solutions around that scenario

With your team, brainstorm ideas around each scenario.
`,
    benefits: [],
    thumbImage: futureScenarioPlanningFront,
    backImage: futureScenarioPlanningBack,
    template: futureScenarioPlanningTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Idea Mapping",
    slug: "aux-idea-mapping",
    resources: ["Pen", "Paper / Table template"],
    content: mdx`
### Why should you use this method?

- To review all your opportunities to generate ideas and concepts
- To help narrow down and choose the ideas for prototyping

### How can you apply this method?

##### 1. Map out milestones

Looking at the customer journey map, identify the most important moment or milestones in the user journey.

##### 2. Identify change/evolution

Identify the changes or shifts that you want to see in those moments/ milestones. Consider the insights and inspiration that you collected&nbsp;earlier.

##### 3. Generate concepts or ideas

Brainstorm individually or in a team to come up with ideas that bring about the required change.

##### 4. Question each idea

For each idea generated, use the following questions to help you narrow down which ideas to prototype, take forward:

1. Why do we think this concept will work or improve at that moment?
2. Our biggest question/ unknown about this concept right now?
3. Other things we want to learn about this concept?

### Next Steps

[Prototype](m3-test-principles) the idea selected.
`,
    benefits: [],
    thumbImage: ideaMappingFront,
    backImage: ideaMappingBack,
    template: ideaMappingTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Behavioral Principles",
    slug: "aux-behavioral-principles",
    resources: [],
    content: mdx`
### Why should you use this method?

- To design better human experiences
- To make the interaction with the prototype a memorable experience
- Easier to spot the areas for improvement

### Common behavioral patterns

Understanding human psychology and behavior is critical to design better user experiences. Through observations and qualitative research, such patterns can be identified.

##### 1. Users tend to take the easy route possible.

  - If they know how to interact with other products or services similar in nature, it is hard for them to change their habits
  - Users expect certain functionalities to be located in certain places

##### 2. Elements that are located close to each other are perceived as one group.

##### 3. An element is perceived as a focal point or background is based on contrast, brightness or colors.

  - A well-thought-out color palette can elevate a design from “good” to&nbsp;“great”
  - Colors and the way they are combined have a huge emotional impact on the users
  - There are cultural differences associated with each color based on the intended audience for the product or service

##### 4. Sometimes, users get overwhelmed with options and may not choose anything at all.

  - Less is more

  - But users might get less frustrated if plenty of options are structured

##### 5. Language, voice and tone are integral parts of designing a user experience.

  - It helps brands communicate trust and build lasting relationships in a meaningful context
 `,
    benefits: [],
    thumbImage: behavioralPrinciplesFront,
    backImage: behavioralPrinciplesBack,
    // template: behavioralPrinciplesTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Multi-Sensory Design",
    slug: "aux-multi-sensory-design",
    resources: ["Pen", " Paper/ Table template "],
    content: mdx`
### Why should you use this method?

- To create memorable and positive experiences
- To connect better with the users

### What is Multi-Sensory Design?

Multi-Sensory Design has become much more relevant in this age of digital interactions in terms of user experience. The most memorable and positive experiences we have are always multi-sensorial.

The multi-sensory design acknowledges that people experience and react to space in many ways. Taste, touch, and smell are equally as important as seeing and hearing when it comes to a user experience. Adopting a multi-sensory design approach is a prerequisite to connecting with our customers.

### How can you apply this method?

##### 1. Define the user experience

Explain the desired user experience that you want to create.

##### 2. Identify the changes

Identify the emotional shift/ change that you would like to see with your user experience.

##### 3. Identify samplings

Identify objects/materials that elicit that experience.

##### 4. Analyze the samplings

State the sensory properties of the samples identified in Step 3.

##### 5. Integrate with the UX

Define the sensory experience that you would create with the materials&nbsp;identified.

##### 6. Prototype

Translate the findings into a coherent design and prototype.

##### 7. Grade the prototype

Grade the prototype on the five senses graph based on the sensory experience. Try this for multiple ideas. As shown in the visual, the idea that achieves closest to the straight line is a perfect multi-sensory design experience.

### Next Steps

Test it with the users.


 `,
    benefits: [],
    thumbImage: multiSensoryDesignFront,
    backImage: multiSensoryDesignBack,
    template: multiSensoryDesignTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Monitoring & Evaluating Framework",
    slug: "aux-monitoring-and-evaluation-framework",
    resources: ["Pen", " Paper/ Table template "],
    content: mdx`
### Why should you use this method?

- To measure the effectiveness of the solution
- It helps you define the key indicators to track and gather data

### What is a Monitoring & Evaluating Framework?

A Monitoring & Evaluation Framework is an outline for how you will track the effectiveness of your solution out in the world.

The framework will help you scope out a basic approach, including the key indicators you want to track and the data gathering methods you will use. They may be quantitative and qualitative, depending on what you're trying to track. Some indicators need to be adjusted based on what data you can feasibly collect.

There are two types of indicators, Output Indicators and Outcome Indicators.

##### Output Indicators:

- Output indicators will allow you to measure whether your solution is performing up to expectations
- These indicators, also known as KPIs will be measures of resources, activities and user uptake

##### Outcome Indicators:

- Help to answer the question “is my solution achieving its goals?” or “what does success look like?”
- These indicators will be measures of things that sit at the outcome level, such as shifts in knowledge or behaviors

### How can you create a Monitoring & Evaluating Framework for your project?

##### 1. Define the indicators

Define the output and outcome parameters that are relevant to your experience design.

##### 2. Identify data sources

Identify the different sources to measure the indicators.

##### 3. Figure out the frequency

Figure out how often these indicators need to be measured to provide insights and to prove a project is successful.

##### 4. Set the baseline value

State the current value of the indicator.

##### 5. Set a target

State the desired or the target value of the indicator.
    `,
    benefits: [],
    thumbImage: meFrameworkFront,
    backImage: meFrameworkBack,
    template: meFrameworkTemplate,
  },
  {
    moduleId: "a_uxdesign",
    name: "Survey Design",
    slug: "aux-survey-design",
    resources: [],
    content: mdx`
### Why should you use this method?

- To validate and measure the insights gathered from qualitative research
- To gather quantitative data
- To reach large user groups

### Tips on how to write a survey

- **Closed questions:** The survey should include closed questions to analyze and quantify data. 90% of responses should be covered in multiple-choice options.
- **Keep the language simple:** The language used in a survey should be direct and straightforward. Avoid the use of jargon, advanced concepts, or abbreviation. The questions should ask precisely one thing at a time. Questions should be in a standardized format to avoid misinterpretations.
- **Bias and priming:** The questions should not contain any bias or priming. Questions or concepts introduced earlier in the survey should not influence how people respond to later questions.
- **Length:** The survey should not be longer than 7-8 minutes. Keep the survey as short as possible while still getting the information you&nbsp;want.
- **Structure:** Ask basic, general questions at the beginning, more complex questions in the middle, and then return to general questions at the end. The questions must follow a logical order.
- **Incentives:** Using incentives (such as a gift card, a draw for an iPad, etc.) can increase the response rate.
- **Number of people:** The number of users is divided by your expected response rate and multiple by 100.

### How can you apply this method?

##### 1. Identify the survey method

Use an online tool like Google Form (or any other similar tool) or create paper-based surveys to circulate.

##### 2. Starting page

Have a starting page with some general information about your project, participant consent and other elements like approx. time to complete the survey.

##### 3. Formulate the questions

Using the tips above, formulate close-ended questions and keep the survey short.

##### 4. End page

Include an end page with a thank you and some final information for the&nbsp;participants.

##### 5. Identify the number of users

Calculate the number of users required to fill out the survey to give a good quantitative analysis.

##### 6. Send the survey our with a deadline and an incentive (optional)

Finally, it is time to send the survey, but do not forget to set a deadline. You can include an incentive-based on the urgency and importance of the project.
`,
    benefits: [],
    thumbImage: surveyDesignFront,
    backImage: surveyDesignBack,
  },
  {
    moduleId: "a_uxdesign",
    name: "Storyboarding",
    slug: "aux-storyboarding",
    resources: [],
    content: mdx`
### Why should you use this method?

-  To quickly and clearly communicate how an idea works without
    getting caught up in the details

-  To better understand how to create the desired effect on a user

-  To help you iterate and refine your idea

-  To better collaborate with you team by building a common
    understanding of the idea



### What is a storyboard?

A storyboard is a visual representation of a product or service, showing how it works and how users can interact with it. Storyboards are borrowed from the film industry, where they are used as low-fidelity prototypes for movies to quickly communicate the main moments in a story. It usually looks like a short comic strip where your chosen user persona is the protagonist, and it tells the story of how your solution solves their problem.

This method helps you identify the steps that would not have been obvious at the beginning stage of conceiving the idea. The key idea is to place the user or an object she interacts within the specific context so that you can learn about the many different aspects of an encounter and interaction.


### How do you approach a storyboard?

You do not need to be a great artist to create a great storyboard. It is not about your drawing capabilities but about capturing the situation in which your idea is used in its entirety.

You or your team start by defining the effect you want your solution to have on your user and in what scenario it would be used. From there, you define the main moments in the user scenario to get the user from their starting point to the desired state after interacting with the solution.
The most important part is translating the story into a visual artefact using very basic sketches or photos found online. In this case, simple is better. Ultimately, a storyboard is a visual communication tool that can easily be followed without an explanation.


H### ow can you apply this method?


#### 0.   Gather your data

Using the data you gathered from user research, define your persona and the customer journey.


#### 1.   Define the desired effect

Define the current state of your user and the desired end state of your user after they use your solution.


#### 2.   Outline the user scenario

Define a simple scenario that the user would use your solutions such as “searching for an apartment in a new city”


#### 3.   Plan out the steps

Start by writing the steps the user goes through in simple sentences and connecting them with arrows. Add the emotional state of the user at each step.


#### 4.   Create Visuals

Using a comic-strip-like template with 4-8 panels, make a quick sketch depicting each step you defined. Artistry is not important here. Sketches can be of stick figures with smile or frown to indicate their emotion.


#### 5.   Add captions and annotations

Add a 1-sentence caption to each panel of your storyboard. You can also annotate your drawing with annotations to clarify different objects or people. The goal here is to ensure someone can understand your storyboard without any verbal explanation.


#### 6.   Rework the storyboard based on feedback

Show your storyboard to users or other stakeholders to get feedback on if the problem resonates and if the solution is desirable. Iterate on your storyboard by changing or adding panels, increasing the fidelity, or pivoting to a new scenario entirely.


`,
    benefits: [],
    thumbImage: storyboardFront,
    backImage: storyboardFront,
    template: storyBoardingTemplate,
  },
];
