import React from "react";
import { Box, SxStyleProp } from "theme-ui";

const CheckboxChecked = props => (
  <SVG {...props}>
    <path
      d="M16,4C16,1.792 14.208,0 12,0L4,0C1.792,0 0,1.792 0,4L0,12C0,14.208 1.792,16 4,16L12,16C14.208,16 16,14.208 16,12L16,4Z"
      fill="rgb(23,229,160)"
    />
    <g transform="matrix(1,0,0,1,8.24945,7.10827)">
      <g transform="matrix(1,0,0,1,-6,-4.5)">
        <clipPath id="_clip1">
          <rect x="0" y="0" width="12" height="9" />
        </clipPath>
        <g clipPath="url(#_clip1)">
          <path
            d="M2,4.333L4.186,7.053C4.58,7.543 5.325,7.552 5.73,7.07L10,2"
            fill="none"
            stroke="white"
            strokeWidth="2.5px;"
          />
        </g>
      </g>
    </g>
  </SVG>
);

const CheckboxUnchecked = props => (
  <SVG {...props}>
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" stroke="black" />
  </SVG>
);

const SVG: React.FC<{
  size?: number;
}> = ({ size = 25, ...props }) => (
  <Box
    as="svg"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={size + ""}
    height={size + ""}
    viewBox={`0 0 ${size} ${size}`}
    {...props}
  />
);

const CheckboxIcon = props => (
  <React.Fragment>
    <CheckboxChecked
      {...props}
      __css={{
        display: "none",
        "input:checked ~ &": {
          display: "block",
        },
      }}
    />
    <CheckboxUnchecked
      {...props}
      __css={{
        display: "block",
        "input:checked ~ &": {
          display: "none",
        },
      }}
    />
  </React.Fragment>
);

export const Checkbox: React.FC<
  {
    sx?: SxStyleProp;
    wrapperSx?: SxStyleProp;
    className?: string;
  } & React.ComponentProps<"input">
> = ({ className, sx = {}, wrapperSx = {}, ...props }) => (
  <Box sx={wrapperSx}>
    <Box
      as="input"
      type="checkbox"
      readOnly
      {...props}
      sx={{
        position: "absolute",
        opacity: 0,
        zIndex: -1,
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    />
    <Box
      as={() => <CheckboxIcon {...props} />}
      aria-hidden="true"
      className={className}
      sx={{
        mr: 2,
        flexShrink: 0,
        ...sx,
      }}
    />
  </Box>
);
