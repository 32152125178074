import { Heading, Text, Box, ThemeProvider } from "theme-ui";
import * as React from "react";
import { Layout } from "../components/Layout";
import { Container } from "@sparkademy/app-common/elements/Container";
import { List, ListItem } from "@sparkademy/app-common/elements/List";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ModuleInfo, createOpenModule } from "@sparkademy/app-common/models/module";
import { Tool } from "@sparkademy/app-common/models/tool";
import { tools } from "@sparkademy/app-common/data/tools";
import { MethodCardThumb } from "../components/MethodCardThumb";

// @ts-ignore
import templatesM1 from "@sparkademy/app-common/data/method-kit/SA_Templates_M1.pdf";
// @ts-ignore
import templatesM2 from "@sparkademy/app-common/data/method-kit/SA_Templates_M2.pdf";
// @ts-ignore
import templatesM3 from "@sparkademy/app-common/data/method-kit/SA_Templates_M3.pdf";
// @ts-ignore
import templatesM4 from "@sparkademy/app-common/data/method-kit/SA_Templates_M4.pdf";

import { Loader } from "@sparkademy/app-common/components/Loader";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { copyToClipboard } from "@sparkademy/app-common/utils/clipboard";
import { MessageToast } from "@sparkademy/app-common/components/MessageToast";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { Input } from "@sparkademy/app-common/elements/Input";

function getTemplateForModule(m: ModuleInfo) {
  switch (m.id.toLowerCase()) {
    case "m1":
      return templatesM1;
    case "m2":
      return templatesM2;
    case "m3":
      return templatesM3;
    case "m4":
      return templatesM4;
    default:
      console.warn(`module id ${m.id} does not have a coded template`);
      return "";
  }
}

const toolFilter = (search: string, module_name: string, module_description: string) => (t: Tool) =>
  t.name.toLowerCase().includes(search.toLowerCase()) ||
  module_name.toLowerCase().includes(search.toLowerCase()) ||
  module_description.toLowerCase().includes(search.toLowerCase());

export const MethodKit: React.FC = props => {
  const query = useQuery();
  const sharedByUserId = query.get("ref") || "";
  const companyId = query.get("companyId") || "";
  const [search, setSearch] = React.useState("");

  const { modules } = useContentContext();
  const { currentUser, loaded: userLoaded } = useSessionContext();

  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  function showCopiedUrlMessage() {
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  }

  if (modules.length === 0) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  let allToolsByModule: Array<{
    module: ModuleInfo;
    tools: Tool[];
    templatesUrl: string;
  }> = modules
    .filter(m => !m.id.includes("projectwork"))
    .map(m => ({
      module: m,
      tools: tools.filter(t => t.moduleId.toLowerCase() === m.id.toLowerCase()),
      templatesUrl: getTemplateForModule(m),
    }));

  let toolsByModule: Array<{
    module: ModuleInfo;
    tools: Tool[];
    templatesUrl: string;
  }> = allToolsByModule;

  return (
    <ThemeProvider theme={themeNew}>
      <Layout sx={{ bg: "new.primary.white" }}>
        <Container sx={{ maxWidth: "894px", pb: 14 }}>
          <MessageToast visible={showMessage} message="The link was copied to the clipboard!" />

          <Box sx={{ mt: 14 }} />
          <Heading
            sx={{
              mb: 10,
              fontSize: 4,
              fontWeight: "bold",
            }}
          >
            Method Kit
          </Heading>

          <Text
            as="p"
            sx={{
              fontSize: 1,
              fontWeight: 300,
              lineHeight: 1.4,
              mb: 11,
            }}
          >
            The Sparkademy Method Kit compiles a collection of the best theoretical frameworks,
            practical tools and hands-on tips. These method cards will support your learning journey
            and your team through relevant project work.
          </Text>

          <Text as="p" sx={{ fontSize: 1, mb: 8 }}>
            Copy a shareable link of the Method Kit and share it with your team!
          </Text>
          <Box
            sx={{
              display: ["flex", "flex"],
              flexDirection: ["column", "row"],
              justifyContent: "space-between",
              alignItems: ["start", "center"],
            }}
          >
            <Button
              id="copy-link-btn"
              // sx={{
              //   alignSelf: "center",
              // }}
              onClick={() => {
                const url = "https://method-kit.sparkademy.com";
                copyToClipboard(url);
                showCopiedUrlMessage();
              }}
            >
              Copy Link
            </Button>
            <Box sx={{ display: ["initial", "none"] }} />
            <Input
              value={search}
              onChange={event => setSearch(event.currentTarget.value)}
              placeholder="Search for a tool"
              sx={{ mt: 10, mb: 8, maxWidth: "80vw" }}
            />
          </Box>
          <List
            id="module-list"
            sx={{
              textAlign: "left",
            }}
          >
            {toolsByModule.map(({ module, tools: moduleTools }) => {
              moduleTools = moduleTools.filter(toolFilter(search, module.name, module.description)); //Filter out modules that don't have any tools that match the search
              if (moduleTools.length === 0) {
                return null;
              }
              return (
                <ListItem
                  key={module.id}
                  sx={{
                    mb: 10,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 8,
                    }}
                  >
                    <Heading
                      as="h2"
                      sx={{
                        fontSize: 2,
                        fontWeight: "bold",
                      }}
                    >
                      {`Module ${module.index}`}: {module.name}
                    </Heading>
                  </Box>

                  <List
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      m: -3,
                    }}
                  >
                    {moduleTools.length > 0 &&
                      moduleTools.map(tool => (
                        <ListItem
                          key={tool.slug}
                          sx={{
                            p: 3,
                            width: [`${(1 / 2) * 100}%`, `${(1 / 3) * 100}%`, `${(1 / 4) * 100}%`],
                          }}
                        >
                          <MethodCardThumb
                            isPublic={true}
                            tool={tool}
                            module={createOpenModule(module)}
                            moduleInfo={module}
                          />
                        </ListItem>
                      ))}
                  </List>
                </ListItem>
              );
            })}
          </List>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
