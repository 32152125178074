import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "theme-ui";

import { MethodCard } from "@sparkademy/app-common/components/MethodCard";
import { themeNew } from "@sparkademy/app-common/materials/theme";

import { Layout } from "./components/Layout";
import { MethodKit } from "./pages/MethodKit";
import { MethodKitLogin } from "./pages/MethodKitLogin";
import Cookies from "js-cookie";
import { AUTH_COOKIE } from "@sparkademy/app-common/contexts/session-context";

type AppProps = RouteComponentProps;

export const LOCAL_STORAGE_TOKEN_KEY = "method-kit-token";

export const App: React.FC<AppProps> = props => {
  React.useEffect(() => {
    const e = document.getElementById("root");
    if (e && !navigator.webdriver) {
      e.style.display = "block";
    }
  }, []);

  return (
    <ThemeProvider theme={themeNew}>
      <Switch>
        <Route exact path="/login" component={MethodKitLogin} />
        <Redirect from="/method-kit" to="/" />
        <Redirect from="/method-kit/:cardSlug" to="/:cardSlug" />
        <MethodKitProtectedRoute
          exact
          path="/:cardSlug"
          component={() => (
            <Layout>
              <MethodCard />
            </Layout>
          )}
        />
        <MethodKitProtectedRoute exact path="/" component={MethodKit} />
      </Switch>
    </ThemeProvider>
  );
};

export const MethodKitProtectedRoute: React.FC = props => {
  const currentLocation = useLocation();

  // token from the public method kit login or app-participants login (enablement)
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || Cookies.get(AUTH_COOKIE);

  // skip login page when pre-rendering (puppeteer: navigator.webdriver = true)
  if (skipLoginForAutomation() || token) {
    return (
      <React.Fragment>
        <Route {...props} />
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/login",
        search:
          currentLocation.pathname !== "/"
            ? `?next=${encodeURIComponent(currentLocation.pathname + currentLocation.search)}`
            : "",
      }}
    />
  );
};

function skipLoginForAutomation() {
  // in e2e tests we also have `navigator.webdriver = true`
  if (window.E2E_TESTING) return false;

  // Puppeteer running a pre-render of the method-kit with react-spa-prerender
  if (navigator.webdriver) return true;

  return false;
}
