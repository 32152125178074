import { API_URL } from "@sparkademy/app-common/constants";
import ky, { Options as KyOptions } from "ky";

export class HTTPError extends Error {}

const DEFAULT_OPTIONS: KyOptions = {
  retry: {
    limit: 5,
    methods: ["get", "put", "head", "delete", "options", "trace"],
    statusCodes: [408, 413, 429, 500, 502, 503, 504],
  },
};

function getAnalyticsId(): string | null {
  const analytics_user =
    window.analytics &&
    window.analytics.user &&
    typeof window.analytics.user === "function" &&
    window.analytics.user();
  return (analytics_user && analytics_user.anonymousId()) || null;
}

export async function publicMethodKitLogin(
  email: string,
  ref?: string,
  companyId?: string,
  next?: string
): Promise<void> {
  let url = `${API_URL}/auth/method_kit/login?email=${encodeURIComponent(
    email.toLowerCase()
  )}`;
  if (ref) {
    url = `${url}&ref=${encodeURIComponent(ref)}`;
  }
  if (companyId) {
    url = `${url}&company_id=${encodeURIComponent(companyId)}`;
  }
  if (next) {
    url = `${url}&next=${encodeURIComponent(next)}`;
  }

  const analyticsId = getAnalyticsId();
  if (analyticsId) {
    url = `${url}&analytics_id=${encodeURIComponent(analyticsId)}`;
  }

  await ky.post(url, DEFAULT_OPTIONS);
}
export async function validateClientToken(token: string): Promise<void> {
  await ky.post(
    `${API_URL}/auth/validate_client_token?token=${token}`,
    DEFAULT_OPTIONS
  );
}
