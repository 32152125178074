/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

const styles: SxStyleProp = {
  textDecoration: "none",
  border: 0,
  fontFamily: "inherit",
  minHeight: 44,
  py: 3,
  px: 10,
  fontWeight: 500,
  lineHeight: 1,
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: 1,
  borderRadius: "30px",
  bg: "transparent",
  color: "new.primary.purple",
  boxShadow: `inset 0 0 0 2px currentColor`,
  justifyContent: "center",
  "&:hover": {
    color: "new.secondary.violet",
  },
  ":active": {
    transform: "translateY(2px)",
    outline: "none",
  },
  ":disabled": {
    pointerEvents: "none",
    cursor: "default",
    bg: "new.secondary.lightGrey",
    color: "new.secondary.grey",
  },
};

type Props = {};
export const ButtonOutline: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  sx = {},
  ...rest
}) => (
  <button
    // @ts-ignore typescript is wrong here
    type="button"
    sx={{
      ...styles,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </button>
);

export const ButtonOutlineLink: React.FC<Props & LinkProps> = ({ children, sx = {}, ...rest }) => (
  <Link
    sx={{
      ...styles,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Link>
);

export const ButtonOutlineAnchor: React.FC<Props & React.HTMLProps<HTMLAnchorElement>> = ({
  sx = {},
  children,
  ...rest
}) => (
  <a
    sx={{
      ...styles,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </a>
);
