/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";
import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

const styles: SxStyleProp = {
  border: theme => `2px solid ${theme.colors.new.primary.black}`,
  fontFamily: "inherit",
  minHeight: 44,
  py: 3,
  px: 6,
  fontWeight: 500,
  lineHeight: 1,
  display: "inline-block",
  fontSize: 1,
  borderRadius: "10px",
  bg: "transparent",
  color: "new.primary.purple",
  "&:focus": {
    outline: "none",
    borderColor: "new.primary.purple",
  },
  ":disabled, &.disabled": {
    pointerEvents: "none",
    bg: "new.secondary.lightGrey",
    color: "new.secondary.grey",
  },
  "&::placeholder": {
    color: "new.secondary.violet",
  },
};

type Props = {};
export const Input: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({
  children,
  sx = {},
  ...rest
}) => (
  <input
    sx={{
      ...styles,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </input>
);
