import * as React from "react";
import LogRocket from "logrocket";
import { Session } from "@sparkademy/app-common/models/session";
import { User } from "@sparkademy/app-common/models/user";
import { useBaseApiServiceContext } from "./base-api-service-context";
import Cookies from "js-cookie";

export const AUTH_COOKIE = "auth_token";

export const initialSession: Session = {
  // redirectPathOnAuthentication: localStorage.getItem('onboarding') ? '/' : '/onboarding',
  redirectPathOnAuthentication: "/",
  isAuthenticated: false,
  currentUser: null,
  loaded: false,
};

type SessionContextShape = {
  login: (user: User) => void;
  logout: Function;
} & Session;
export const SessionContext = React.createContext<SessionContextShape>({
  ...initialSession,
  login: () => ({}),
  logout: () => ({}),
});

export const useSessionContext = () => React.useContext(SessionContext);

export const SessionContextProvider: React.FC<{}> = props => {
  const api = useBaseApiServiceContext();

  const locationAfterLogin = "/dashboard";

  const [redirectPathOnAuthentication, setRedirectPathOnAuthentication] = React.useState(
    // localStorage.getItem('onboarding') ? '/' : '/onboarding'
    locationAfterLogin
  );

  const [loaded, setLoaded] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);

  React.useEffect(() => {
    async function checkCurrentUser() {
      let currentUserInitial: User | null = null;
      let isAuthenticatedInitial: boolean = false;
      if (Cookies.get(AUTH_COOKIE)) {
        try {
          const userString = localStorage.getItem("user");
          if (userString) {
            const user = JSON.parse(userString) as User;
            // await api.getStatus(user);
            if (user.exp < new Date().getTime() / 1000) {
              console.error("user token expired, logging out");
              localStorage.removeItem("user");
              isAuthenticatedInitial = false;
            } else {
              currentUserInitial = user;
              isAuthenticatedInitial = true;

              LogRocket.identify(user.data.id!, {
                email: user.data.email,
                cohort: user.data.cohort_id,
              });
            }
          }
        } catch (ex) {
          console.log("ex", ex);
          localStorage.removeItem("user");
          isAuthenticatedInitial = false;
          currentUserInitial = null;
          if (process.env.NODE_ENV === "development") {
            console.log("something is wrong with the local storage data");
            console.error(ex);
          }
        }
      } else {
        console.log("auth_cookie not found");
        localStorage.removeItem("user");
      }
      setCurrentUser(currentUserInitial);
      setIsAuthenticated(isAuthenticatedInitial);
      setLoaded(true);
    }
    checkCurrentUser();
  }, [api]);

  function login(user: User) {
    try {
      localStorage.setItem("user", JSON.stringify(user));
      // setRedirectPathOnAuthentication(localStorage.getItem('onboarding') ? '/' : '/onboarding');
      setRedirectPathOnAuthentication(locationAfterLogin);
      setCurrentUser(user);
      setIsAuthenticated(true);

      LogRocket.identify(user.data.id!, {
        email: user.data.email,
        cohort: user.data.cohort_id,
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  function logout() {
    window.Intercom && window.Intercom("shutdown");

    localStorage.removeItem("user");
    Cookies.remove(AUTH_COOKIE, { path: "/", domain: ".sparkademy.com" });
    setCurrentUser(null);
    setIsAuthenticated(false);
  }

  return (
    <SessionContext.Provider
      value={{
        ...initialSession,
        redirectPathOnAuthentication,
        isAuthenticated,
        currentUser,
        loaded,
        login,
        logout,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
