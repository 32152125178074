/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import * as React from "react";
import { ReactComponent as Logo } from "@sparkademy/app-common/materials/logos/logo-sideways.svg";
import { Link } from "react-router-dom";
import { Container } from "@sparkademy/app-common/elements/Container";

export const Header: React.FC = () => {
  return (
    <header
      sx={{
        bg: "new.primary.white",
        borderBottom: theme => `1px solid ${theme.colors.new.secondary.grey}`,
      }}
    >
      <Container
        sx={{
          minHeight: 96,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          aria-label="homepage"
          sx={{
            display: "block",
            svg: {
              display: "block",
            },
          }}
        >
          <Logo height={21} aria-hidden="true" focusable="false" />
        </Link>
      </Container>
    </header>
  );
};
